import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { postLoginUser } from '../../actions/auth';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import backgroundImage from "../../img/LEVLR-Login-Background.jpg";
import levlr_logo from "../../img/LEVLR Logo_CMYK.png";

const LoginForm = props => {
    const [credentials, setCredentials] = React.useState({});

    const isLoggedIn = useSelector(state => state.isLoggedIn);
    const user = useSelector(state => state.user); // Get the whole user object

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = e => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const loginUser = e => {
        e.preventDefault();
        dispatch(postLoginUser({ credentials }));
    };

    const routeChange = () => { 
        navigate('/register');
    };

    const passwordChange = () => { 
        navigate('/forgot');
    };
    const location = useLocation()
    const lastRoute = localStorage.getItem('lastRoute') || '/accounts';

    useEffect(() => {
       
        if (isLoggedIn) {
            navigate('/accounts');
        }
    }, [isLoggedIn, navigate]);

    const backGroundStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        paddingBottom: '10%',
        paddingTop: '10%',
    };

    const loginPaperStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        width: '70%',
        height: 'auto',
        paddingTop: '4vh',
        paddingBottom: '4vh',
        marginLeft: 'auto',
        marginRight: 'auto',
    };

    const loginTextFieldStyle = {
        width: '65%',
        height: 'auto',
    };

    const loginFormStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: 1,
    };

    const loginButtonStyle = {
        width: '50%',
        color: "white",
        backgroundColor: "#2fb7b7",
        '&:hover': {
            backgroundColor: '#01656a',
        },
        margin: 16,
    };

    const signupButtonStyle = {
        width: '50%',
        color: "white",
        backgroundColor: "#01656a",
        '&:hover': {
            backgroundColor: '#2fb7b7',
        },
        margin: 16,
    };

    const levlrLogoStyle = {
        width: '40%',
        height: 'auto',
    };

    if (!user) {
        return null; // Handle loading or error state
    }

    const userData = user._id;
    const userName = user.name;
    const userEmail = user.email;
    const userActive = user.isActive;

    return (
        <div className="Background-Container" style={backGroundStyle}>
            <Container component="main">
                <CssBaseline />
                <Paper style={loginPaperStyle}>
                    <img src={levlr_logo} style={levlrLogoStyle} alt="Levlr Logo"/>
                    <form style={loginFormStyle} noValidate onSubmit={loginUser}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            id="email"
                            label="email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleChange}
                            style={loginTextFieldStyle}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            id="password"
                            label="Password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            onChange={handleChange}
                            style={loginTextFieldStyle}
                        />
                
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember Me"
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            style={loginButtonStyle}
                        >
                            LOGIN
                        </Button>

                        Don't have an account?
    
                        <Button
                            onClick={routeChange}
                            variant="contained"
                            style={signupButtonStyle}
                        >
                            SIGN UP
                        </Button>

                        Forgot Your Password?
    
                        <Button
                            onClick={passwordChange}
                            variant="contained"
                            style={signupButtonStyle}>
                            Reset Password
                        </Button>
                    </form>
                </Paper>
            </Container>
        </div>
    );
};

export default LoginForm;
