import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../../css/ComparisonResults.css';
import MainWrapper from '../wrappers/mainWrapper';
import Button from '@mui/material/Button';
import Multiselect from 'multiselect-react-dropdown';
import { Switch, FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Modal from 'react-modal';
import axios from 'axios'
import jsPDF from 'jspdf'
import LevlrLogo from '../../img/LEVLR Logo_CMYK.png'
import Select from 'react-select';

const ComparisonResults = props => {

let doc1ZoneState = []
const [newZonesToCompare, setNewZonesToCompare] = useState([])
var stringSimilarity = require("string-similarity");
const [responded, setResponded] = useState(false)
const [getSubzones, setGetSubzones] = useState([])
const [data, dataSet] = useState({})
const [filter, setFilter] = useState("percentage");
const [percentValue, setPercentValue] = useState(0.0);
const [currentSection, setCurrentSection] = useState("results");
const { comparisonID } = useParams();
const [markForExport, setMarkForExport] = useState([])
const [open, setOpen] = useState(false)
const [currentNoteId, setCurrentNoteId] = useState("")
const [loadingStatus, setLoadingStatus] = useState('loading-spinner')
const pdfRef = useRef(null);
const MAX = 85;
const [noteLog, setNoteLog] = useState([])
const [currentNote, setCurrentNote] = useState("")
const [markConfirm, setMarkConfirm] = useState([])
const [markedElements, setMarkedElements] = useState({});
const [newViewArray, setNewViewArray] = useState([])
const [isFocused, setIsFocused] = useState(false);
const [pdfCopyContent, setPdfCopyContent] = useState(false);


const [toggled, setToggled] = useState(true)
const maxLength = 60; // Set the maximum length for visible content

const [selectedZone1, setSelectedZone1] = useState([]);
const [selectedZone2, setSelectedZone2] = useState([]);
const [filterTextZone1, setFilterTextZone1] = useState(''); // State for zone 1 filter text
const [filterTextZone2, setFilterTextZone2] = useState(''); // State for zone 2 filter text
const [filteredItems, setFilteredItems] = useState({ zone1: [], zone2: [] });
const [confirmedForExport, setConfirmedForExport] = useState([]);
console.log("markForExport: " + markForExport)
console.log("confirmedForExport: " + confirmedForExport)
const openPreviewInNewTab = () => {
  const pdfCopy = document.getElementById('pdfCopy');

  if (pdfCopy) {
    // Clone the pdfCopy content to avoid modifying the original
    const clonedContent = pdfCopy.cloneNode(true);

    // Remove all label elements within the cloned content
    const labels = clonedContent.querySelectorAll('label');
    labels.forEach((label) => label.remove());

    // Open a new tab and inject the modified content
    const newTab = window.open('', '_blank');
    newTab.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
        <title>Preview</title>
        <style>
          /* Add any styling you want for the preview here */
          body {
            font-family: Arial, sans-serif;
            padding: 20px;
          }
        </style>
      </head>
      <body>
        ${clonedContent.innerHTML}
      </body>
      </html>
    `);
    newTab.document.close(); // Ensure the document is fully loaded
  } else {
    alert('No content to preview');
  }
};

let sorted = markForExport.sort()

// Generate PDF based on pdfCopy contents
const generatePDF = () => {
  const pdfCopy = document.getElementById('pdfCopy');

  if (pdfCopy && pdfCopy.children.length > 0) {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: 'a1',
      hotfixes: ['px_scaling'],
    });

    const fileName = prompt('Please Enter A File Name.');

    // Adding Claim Credentials at the top
    if (data["claimData"]) {
      const { adjuster, insured, insured_address, claim_number } = data["claimData"];

      doc.setFontSize(12);
      doc.setTextColor('#333333'); // Set text color if desired

      // Adding each credential at a specific y-position for spacing
      doc.text(`Adjuster: ${adjuster}`, 20, 30);
      doc.text(`Insured: ${insured}`, 20, 50);
      doc.text(`Insured Address: ${insured_address}`, 20, 70);
      doc.text(`Claim Number: ${claim_number}`, 20, 90);
    }

    // Render content from pdfCopy
    doc.html(pdfCopy, {
      callback: function (doc) {
        doc.addImage(LevlrLogo, 'PNG', 2000, 20, 203, 70);
        doc.save(`${fileName}.pdf`);
      },
    });
  } else {
    alert('No selections made for export.');
  }
};

//styles
const tableStyle = { 
  position: 'relative',
  height: 'auto',
  width: '100%',
  backgroundColor: '#DDDDDD',
  borderRadius: '4px',
  padding: '12px 8px',
  margin: '12px 12px 24px 12px',
  overflow: 'auto',
  border: '1px dotted black'
}
const filterTableStyle = {
  position: 'relative',
  height: 'auto',
  width: '98%',
  backgroundColor: '#DDDDDD',
  borderRadius: '4px',
  padding: '12px 8px',
  margin: '12px 0px 24px 0px',
  overflow: 'auto',
  border: '1px dotted black'
}
const modalStyle = {
  position: 'absolute',
  display: 'grid',
  overflow: 'hidden',
  backgroundColor: 'white',
  opacity: '95%',
  width: '100%',
  height: '100%',
  borderRadius: '15px',
  padding: '12px 12px',
  boxSizing: 'border-box'
}

const modalRestyle = {
  overlay: {
    width: '40%',
    height: '44%',
    top: '54%',
    left: '1%',
    overflow: 'hidden',
    borderRadius: '15px',
    border: '4px solid black',
    boxSizing: 'border-box',
    padding: '0 0',
  },

  content: {
    overflow: 'hidden',
    background: 'none',
    border: 'none',
    inset: '0px',
    padding: '0 0'
  },
}

const noteStyle = {
  border: '2px solid #526666',
  resize: 'none',
  fontSize: '20px',
  padding: '4px 4px',
  width: '100%',
  height: '100%',
  boxSizing: 'border-box'
}

const notInAnyZone = {
  fontSize: '24px',
  textAlign: 'center',
  padding: '0px 0px',
  margin: '0px 0px'
}

const endOfDocTable = {
  position: 'relative',
  height: 'auto',
  width: '100%',
  backgroundColor: '#e7d4d8',
  borderRadius: '4px',
  margin: '12px 12px 12px 12px',
  padding: '12px 8px',
  overflow: 'auto',
  border: '1px dotted black',
}

const pdfCopy = {
  display: 'flex-box',
  flexDirection: 'column',
  margin: '128px 12px 12px 12px',
  padding: '16px',
  fontSize: '28px',
  width: '100%',
  overflowY: 'auto',
  boxSizing: 'border-box'
}

const theadStyle = {
  fontSize: '20px'
}

const newHeaderStyle = {
  fontSize: '32px',
  paddingBottom: '12px'
}

const borderTop = {
  position: 'relative',
  gridTemplateRows: 'auto auto',
  height: 'auto',
  width: '100%',
  borderRadius: '4px',
  borderTop: '2px solid black',
  marginTop: '36px'
}

const getZonePairingsContainer = {
  display: 'block',
}

const noMatchTableStyle = {
  position: 'relative',
  height: 'auto',
  width: '100%',
  backgroundColor: '#e7d4d8',
  borderRadius: '4px',
  padding: '12px 8px',
  margin: '12px 12px 24px 12px',
  overflow: 'auto',
  border: '1px dotted black'
}

const lastRow = {
  display: 'flex',
  justifyContent: 'space-between',
  gridTemplateColumns: 'auto',
  width: '100%',
  alignItems: 'center',
  padding: '4px 4px'
}

const filterStyle = {
  height: '16px',
  padding: '6px'
}

const centeredHeader = {
  textAlign: 'center'
}

const blockStyle = {
  display: 'block'
}

const mapStyle = {
  position: 'relative',
  height: 'auto',
  display: 'grid',
  gridTemplateRows: 'auto auto',
  marginTop: '6px',
}

const verticalLabel = {
  padding: '16px 4px',
  margin: '4px 4px',
  textAlign: 'center',
  border: 'none',
  fontStyle: 'italic',
  opacity: '50%',
  textDecoration: 'underline'
}

const labelStyle = {
  margin: '12px 0px',
  color: '#00B3B5',
  textAlign: 'left'
}

const doc2ZoneStyle = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  fontWeight: 'bold',
  color: '#00B3B5', 
  textDecoration: 'underline',
  fontSize: '24px'
}

const addNoteButton = {
  backgroundColor: '#00B3B5',
  color: 'white',
  borderRadius: '4px',
  padding: '4px 8px',
  margin: '0px 8px',
  display: 'inline',
  width: '20%'
}

const updateNoteButton = {
  backgroundColor: '#723E49',
  color: 'white',
  borderRadius: '4px',
  padding: '4px 8px',
  margin: '0px 8px',
  display: 'inline',
  width: '20%'
}

const zoneStyle = {
  margin: '12px 0px',
  fontWeight: 'bold',
  fontSize: '1em',
  padding: '2px 12px',
  color: '#526666'
}

const thStyle = {
  fontWeight: 'bold',
  fontStyle: 'italic',
  color: '#526666',
  border: 'none',
  textAlign: 'left',
  alignSelf: 'center'
}

const trStyle = {
  width: '100%',
  padding: '0px 0px',
  display: 'flex',
  justifyContent: 'space-between'
}

const exportButtons = {
  display: 'flex',
  position: 'fixed',
  margin: '0 auto',
  padding: '0 48px',
  boxSizing: 'border-box',
  justifyContent: 'flex-end',
  width: '85%',
  zIndex: '1000',
  top: '10%',
  left: '15%'
}

const trStyleFlexStart = {
  width: '100%',
  padding: '0px 0px',
  display: 'flex',
  justifyContent: 'flex-start'
}

const tbodyStyle = {
  overflow: 'auto',
  display: 'inline'
}

const tbodyNote = {
  overflow: 'auto',
  display: 'inline',
  marginTop: '24px',
  inlineSize: '80%',
  wordBreak: 'break-all',
  fontWeight: 'bold',
  fontSize: '18px',
}

const tdPropStyle = {
  display: 'block',
  margin: '0px 0px',
  color: '#00B3B5',
  textAlign: 'left',
}

const tdValueStyle = {
  color: '#526666',
}

const containsPropVal = {
  display: 'block',
  width: '200px',
  overflow: 'auto',
  padding: '0 4px',
  margin: '12px 0px 0px 0px'
}  

useEffect(() => {
  //console.log("data: " + JSON.stringify(data,null,2))
    getSubzonesData()
    getNotesData()
    getConfirmData()
    if (!responded) {
      getResultsData()
    }
    else if (responded === true && getSubzones.length > 0){
      setNewZonesToCompare(getSubzones[0].stateObject)
      setLoadingStatus('')
    }
    else if (responded === true) {
      setNewZonesToCompare(
        doc1ZoneState.map((val, index) => {
          return({
            Id: index,
            Doc1: val,
            SubZones: []
          })
        }),
      )
      setLoadingStatus('')
    }
    renderZones()
    const handler = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
  
    if(noteLog.length > 0) {
      window.addEventListener('beforeunload', handler);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
}, [newViewArray, responded, loadingStatus])

const toggleResults = () => {
  if(currentSection == "results"){
    setCurrentSection("full_results")
  }else{
    //filtered set of results
    setCurrentSection("results")
  }
}

const getResultsData = async () => {
  const comp = await fetch(`${process.env.REACT_APP_RESULTS_DATA}comp/${comparisonID}`, {timeout: 8000}).then((response) => response.json({}))
  if (comp.hasOwnProperty("full_resultsID") || comp.hasOwnProperty("results_ID")) {
    if(comp["full_resultsID"] === "NaN") {
      const split = await fetch(`${process.env.REACT_APP_SPLIT_RESULTS}/${comp.resultsID}`).then((response) => response.json({}))
      dataSet({
        results: JSON.parse(split.data), 
        full_results: JSON.parse(JSON.stringify(split.data)), 
        claimData: comp.claimData, 
        files: comp.files, 
        headers: comp.headers, 
        resultsID: comp.resultsID, 
        full_resultsID: comp.full_resultsID,
        dateUploaded: comp.dateUploaded,
        statusMSG: comp.statusMSG,
        status: comp.status,
        doc1_name: comp.doc1_name,
        doc2_name: comp.doc2_name,
        headersOne: comp.headersOne,
        headersTwo: comp.headersTwo,
        no_match_a: comp.no_match_a,
        no_match_b: comp.no_match_b
      })
      setResponded(true)
    }
    else if(comp["full_resultsID"] != "NaN") {
      const split = await fetch(`${process.env.REACT_APP_SPLIT_RESULTS}/${comp.resultsID}`).then((response) => response.json({}))
      const fullSplit = await fetch(`${process.env.REACT_APP_SPLIT_FULL_RESULTS}/${comp.full_resultsID}`).then((response) => response.json({}))
      dataSet({
        results: JSON.parse(split.data), 
        full_results: JSON.parse(JSON.stringify(fullSplit.data)), 
        claimData: comp.claimData, 
        files: comp.files, 
        headers: comp.headers, 
        resultsID: comp.resultsID, 
        full_resultsID: comp.full_resultsID,
        dateUploaded: comp.dateUploaded,
        statusMSG: comp.statusMSG,
        status: comp.status,
        doc1_name: comp.doc1_name,
        doc2_name: comp.doc2_name,
        headersOne: comp.headersOne,
        headersTwo: comp.headersTwo,
        no_match_a: comp.no_match_a,
        no_match_b: comp.no_match_b
      })
      setResponded(true)
    }
  }
  else {
    dataSet(comp)
    setResponded(true)
  }
}

//Function to collect subzone record
const getSubzonesData = async () => {
  const response = await fetch(
  `${process.env.REACT_APP_GET_SUBZONES}${comparisonID}`, {timeout: 8000}
  ).then((response) => response.json({}));
  setGetSubzones(response)
};

//Function to collect subzone record
const getNotesData = async () => {
  const response = await fetch(
  `${process.env.REACT_APP_GET_NOTES}${comparisonID}`, {timeout: 8000}
  ).then((response) => response.json({}));
  if(Object.keys(response).length > 0) {
    setNoteLog(response[0]["stateObject"])
  }
};

const getConfirmData = async () => {
  const response = await fetch(
  `${process.env.REACT_APP_GET_CONFIRM}${comparisonID}`, {timeout: 8000}
  ).then((response) => response.json({}));
  if(Object.keys(response).length > 0) {
    setMarkConfirm(response[0]["stateObject"])
  }
};

//Function to put subzones into database
//id param is equal to comparisonID
function handleSaveZones(id) {
  axios.put(`${process.env.REACT_APP_SAVE_ZONES}${id}`, newZonesToCompare)
    .then(response => {   
      alert("Zones Saved To Database!");
      //console.log("Zones Posted.");
    })
    .catch(error => {
      alert("Saving was unsuccessful. Please try again.");
      console.error('There was an error!', error);
    });
}

function handleSaveNotes(id) {
  axios.put(`${process.env.REACT_APP_SAVE_NOTES}${id}`, noteLog)
  .then(response => {   
    alert("Notes Saved To Database!");
    //console.log("Notes Posted.");
  })
  .catch(error => {
    alert("Saving was unsuccessful. Please try again.");
    console.error('There was an error!', error);
  });
}

function handleSaveConfirm(id) {
  axios.put(`${process.env.REACT_APP_SAVE_CONFIRM}${id}`, markConfirm)
  .then(response => {   
    alert("No-Matches Saved To Database!");
    //console.log("No-Matches Posted.");
  })
  .catch(error => {
    alert("Saving was unsuccessful. Please try again.");
    //console.error('There was an error!', error);
  });
}

function saveChanges (id) {
  handleSaveNotes(id)
  handleSaveZones(id)
  handleSaveConfirm(id)
}

const openNote = (e, id) => {
  setOpen(true)
  setCurrentNoteId(id)
}

const updateNote = (e, id) => {
  setOpen(true)
  setCurrentNoteId(id)
}

function findPlaceholderText(id) {
  if(noteLog.find((element) => {
    return (element.id === id);
  })){
    return (
      noteLog.find((element) => {
        return (element.id === id);
      })
    ).note
  }
  else return
  
}

function renderAddOrUpdate(currentNoteId) {
  if(findPlaceholderText(currentNoteId) === undefined) {
      return false
    }
  else {
    if(findPlaceholderText(currentNoteId).length > 0){
      return true
    }
  }
}

function renderNote() {
  return(
    <Modal
      style={modalRestyle}
      isOpen={open}
      onRequestClose={() => handleNoteClose()}
      preventScroll={false}
      ariaHideApp={false}
    >
      <div style={modalStyle}>
        <h3 style={{color: '#526666'}}>{renderAddOrUpdate(currentNoteId)? "Please Update Your Note.":"Please Add A Note."}</h3>
        <textarea
          style={noteStyle}
          id={currentNoteId}
          name={currentNoteId} 
          defaultValue={findPlaceholderText(currentNoteId)}
          rows="8"
          cols="50"
          onBlur={handleNoteChange}
        >
        </textarea>

        <Button
          size="small"
          sx={{
            ':hover': {
                backgroundColor: '#999999'
            },
          height: '50px',
          margin: '16px 0px 0px 0px', 
          fontWeight: 'bold', 
          color: 'white', 
          backgroundColor: '#00B3B5'}}
          onClick={handleNoteSave}
        >
          {renderAddOrUpdate(currentNoteId)? "UPDATE NOTE": "ADD NOTE"}
        </Button>

        <Button
          size="small"
          sx={{
            ':hover': {
                backgroundColor: '#999999'
            },
          margin: '0px 0px 8px 0px', 
          fontWeight: 'bold', 
          height: '50px',
          color: 'white', 
          backgroundColor: '#723E49'}}
          onClick={handleNoteClose}
        >
          Exit
        </Button>
      </div>      
    </Modal>
  )
  
}

function handleNoteClose() {
  setOpen(false)
}

const handleNoteChange = (e) => {
  setCurrentNote(e.target.value)
}

const handleNoteSave = (e) => {  
  let objIndex = noteLog.find(obj => obj.id === currentNoteId) 
  let newNote = {
    id: currentNoteId,
    note: currentNote
  }
  if (objIndex === undefined) {
    setNoteLog([...noteLog, newNote])
    setOpen(false)
  }
  else {
    objIndex.note = currentNote
    setOpen(false)
  }
  
}

function checkDisabledNote (id) {
  if ((noteLog.find( note => note['id'] === id)) === undefined) {
    return false
  }
  else {
    return true
  }
}

function renderClaimCredentials() {
  if(data["claimData"] != null || data["claimData"] != undefined){
    return(
      <div style={newHeaderStyle} id={'newHeader'}>
        <p>Adjuster : {data["claimData"]["adjuster"]}</p>
        <p>Insured : {data["claimData"]["insured"]}</p>
        <p>Insured Address : {data["claimData"]["insured_address"]}</p>
        <p>Claim Number : {data["claimData"]["claim_number"]}</p>
      </div>
    )
  }
  else {
    return
  }
}

function renderAddNote(id) {
  if (!checkDisabledNote(id)) {
    return (
      <Button
        id={id}
        style={addNoteButton}
        onClick={(e) => openNote(e, id)}
      >
        Add Note
      </Button>
  )  
  }
  else if (checkDisabledNote(id)) {
    return (
      <Button
        id={id}
        style={updateNoteButton}
        onClick={(e) => updateNote(e, id)}
      >
        Update Note
      </Button>
  )  
  }
}

function renderProperNote(id) {
  return(
    noteLog.map((key, val) => { 
        if(key.id === id) {
          return(
            <tr style={tbodyNote}>
                Note: {key.note}
            </tr>
          )
        }
      }
    )
  )
}

function noMatchesEndOfResultsA(lineItemZero) {
  if(data["no_match_a"] != null && data["no_match_b"] != null){
    let a = JSON.parse(data["no_match_a"])
    let b = JSON.parse(data["no_match_b"])
    
    if(Object.keys(a).length > 0) {
      return(
        (
          Object.entries(a).map(([keys, vals]) => {
            return(
              <table 
                style={endOfDocTable}
                id={'zzz' + keys}
              >
                <thead style={notInAnyZone}>
                  <tr>
                    <th>NOT FOUND IN ANY ZONE</th>
                  </tr>
                </thead>
              <tbody id={"string"} key={keys} style={tbodyStyle}>
              <tr style={trStyle}>
              {Object.entries(vals).slice(0, 1).map(([key, val]) => (
                <th style={thStyle} key={key}>
                  {key}: {val}</th>
              ))}
              </tr>

              <tr style={trStyle}>
              <th style={verticalLabel}>Doc1</th>

                {Object.entries(vals).slice(1).map(([key, val]) => (
                  <td id={key} key={key} style={containsPropVal}>
                    <td style={tdPropStyle}>
                      {key}
                    </td>
                    <td style={tdValueStyle}>
                      {val}
                    </td>
                  </td>
                ))}
              
              </tr>
            </tbody> 
            <tbody>
              {renderProperNote('zzz' + keys)}
            </tbody>
            <tbody style={tbodyStyle}>
              <tr style={lastRow}>

                {renderAddNote('zzz' + keys)}
                <div>
                <FormControlLabel
                  style={{
                    fontStyle: 'italic',
                    color: '#526666',
                    padding: '2px 8px',
                    display: 'inline',
                    margin: '2px',
                  }}
                  label={handleStayCheckedMatched('zzz' + keys) ? "Confirmed No-Match" : "Potential No-Match"}
                  control={<Checkbox
                    size="small"
                    checked={handleStayCheckedMatched('zzz' + keys)}
                    id={'zzz' + keys}
                    onChange={(e) => handleCheckedMatched(e,'zzz' + keys)}
                  />}
                />
                <FormControlLabel
                  style={{
                    fontStyle: 'italic',
                    color: '#526666',
                    padding: '2px 8px',
                    display: 'inline',
                    margin: '2px',
                  }}
                  label={"Mark For Export"}
                  control={<Checkbox
                    size="small"
                    checked={handleStayChecked('zzz' + keys)}
                    id={'zzz' + keys}
                    onChange={(e) => handleChecked(e, 'zzz' + keys)}
                  />}
                />
                </div>
            
              </tr>
            </tbody>
            </table>
            )
            
          })) 

      )
    }
  }
}

function noMatchesEndOfResultsB(lineItemZero) {
  if(data["no_match_a"] != null && data["no_match_b"] != null){
    let a = JSON.parse(data["no_match_a"])
    let b = JSON.parse(data["no_match_b"])
    
    if(Object.keys(b).length > 0) {
      return(
        (
          Object.entries(b).map(([keys, vals]) => {
            return(
              <table 
                style={endOfDocTable}
                id={'zzz' + keys}
              >
                <thead style={notInAnyZone}>
                  <tr>
                    <th>NOT FOUND IN ANY ZONE</th>
                  </tr>
                </thead>
              <tbody id={"string"} key={keys} style={tbodyStyle}>
              <tr style={trStyle}>
              {Object.entries(vals).slice(0, 1).map(([key, val]) => (
                <th style={thStyle} key={key}>
                  {key}: {val}</th>
              ))}
              </tr>

              <tr style={trStyle}>
              <th style={verticalLabel}>Doc2</th>

                {Object.entries(vals).slice(1).map(([key, val]) => (
                  <td id={key} key={key} style={containsPropVal}>
                    <td style={tdPropStyle}>
                      {key}
                    </td>
                    <td style={tdValueStyle}>
                      {val}
                    </td>
                  </td>
                ))}
              
              </tr>
            </tbody> 
            <tbody>
              {renderProperNote('zzz' + keys)}
            </tbody>
            <tbody style={tbodyStyle}>
              <tr style={lastRow}>

                {renderAddNote('zzz' + keys)}
                <div>
                <FormControlLabel
                  style={{
                    fontStyle: 'italic',
                    color: '#526666',
                    padding: '2px 8px',
                    display: 'inline',
                    margin: '2px',
                  }}
                  label={handleStayCheckedMatched('zzz' + keys) ? "Confirmed No-Match" : "Potential No-Match"}
                  control={<Checkbox
                    size="small"
                    checked={handleStayCheckedMatched('zzz' + keys)}
                    id={'zzz' + keys}
                    onChange={(e) => handleCheckedMatched(e,'zzz' + keys)}
                  />}
                />
                <FormControlLabel
                  style={{
                    fontStyle: 'italic',
                    color: '#526666',
                    padding: '2px 8px',
                    display: 'inline',
                    margin: '2px',
                  }}
                  label={"Mark For Export"}
                  control={<Checkbox
                    size="small"
                    checked={handleStayChecked('zzz' + keys)}
                    id={'zzz' + keys}
                    onChange={(e) => handleChecked(e, 'zzz' + keys)}
                  />}
                />
                </div>
              </tr>
            </tbody>
            </table>
            )
            
          })) 

      )
    }
  }
}
  
const getBackgroundSize = () => {
	return {
		backgroundSize: `${(percentValue * 100) / MAX}% 100%`,
	};
}

function validateRCV (val) {
  if (val === null) {
    return(   
      <td className="differentials grey" key={val}>RCV - Total: <strong>N/A</strong></td>
    )  
  }
  else if (parseInt(val) < 0) {
    return(   
      <td className="differentials grey" key={val}>RCV - Total: <strong className="green">{(val.toFixed(2)) * (-1)}</strong></td>
    )
  }
  else if (parseInt(val) > 0){
    return(   
      <td className="differentials grey" key={val}>RCV - Total: <strong className="red">{val.toFixed(2) * (-1)}</strong></td>
    )
  }
}

function validateACV (val) {
  if (val == null) {
    return(   
      <td className="differentials grey" key={val}>ACV - Total: <strong>N/A</strong></td>
    )
  }
  else if (Math.sign(val) === -1) {
    return(   
      <td className="differentials grey" key={val}>ACV - Total: <strong className="green">{val.toFixed(2) * (-1)}</strong></td>
    )
  }
  else {
    return(   
      <td className="differentials grey" key={val}>ACV - Total: <strong className="red">{(val.toFixed(2))  * (-1)}</strong></td>
    )
  }
}

function validateTotal (key, val) {  
  const parsedVal = parseInt(val)
  if (key != "Description Match Percent"){
    if (val == null) {
      return(   
        <td className="differentials grey" key={val}>Total - Total: <strong>N/A</strong></td>
      )
    }
    else if (Math.sign(val) === -1) {
      return(   
        <td className="differentials grey" key={val}>Total - Total: <strong className="red">{val.toFixed(2)}</strong></td>
      )
    }
    else {
      return(   
        <td className="differentials grey" key={val}>Total - Total: <strong className="green">{val.toFixed(2)}</strong></td>
      )
    }
  }
  else {
    return(   
      <td className="differentials grey" key={parsedVal}>Total - Total: <strong>N/A</strong></td>
    ) 
  }

}

const handleFilterChange = event => {
  setFilter(event.target.value)
}

if(data["claimData"] != null){
  let resultsLayer = data[currentSection]
  let resLayer1 = Object.values(resultsLayer).map((val, i) => Object.values(val).slice(0,1).toString())
  let noZone1Duplicates = [...new Set(resLayer1)]
  let resLayer2 = Object.values(resultsLayer).map((val, i) => (Object.values(val).slice(1, 2)).toString())
  let noZone2Duplicates = [...new Set(resLayer2)]
  doc1ZoneState.push(...noZone1Duplicates)
}

const handleSelect = (zone1Name, e) => {
  setNewZonesToCompare(current => 
    current.map(obj => {
      if (obj.Doc1 === zone1Name) {
        return {...obj, SubZones: [e]};
      }
      return obj;
    })
  )
}

// Check if the container should stay checked
const handleNewStayChecked = (container) => newViewArray.includes(container);

// Handle checkbox marking/unmarking
const handleNewChecked = (container) => {
  setNewViewArray((prevArray) =>
    prevArray.includes(container)
      ? prevArray.filter((html) => html !== container)
      : [...prevArray, container]
  );

  setMarkForExport((prevArray) =>
    prevArray.includes(container)
      ? prevArray.filter((html) => html !== container)
      : [...prevArray, container]
  );
};

const confirmExportSelection = () => {
  console.log("markForExport.length: " + markForExport.length);
  if (markForExport.length > 0) {
    setConfirmedForExport((prevArray) => [...prevArray, ...markForExport]);

    // Add confirmed elements to pdfCopy
    const pdfCopy = document.getElementById('pdfCopy');
    const fragment = document.createDocumentFragment();  // Using DocumentFragment to minimize reflows

    // Create a parent div to wrap all containers
    const containerWrapper = document.createElement('div');
    containerWrapper.style.border = "4px solid #00B3B5";
    containerWrapper.style.boxSizing = "border-box";
    containerWrapper.style.width = "100%";
    containerWrapper.style.padding = "16px"; // Optional padding around the group of containers
    containerWrapper.style.marginBottom = "80px"; // Adjust the margin as needed
    containerWrapper.style.marginTop = "80px"; // Adjust the margin as needed

    markForExport.forEach((key, index) => {
      const containerElement = document.getElementById(key);

      if (containerElement) {
        const clone = containerElement.cloneNode(true);
        clone.style.width = '100%';
        clone.style.boxSizing = "border-box";

        // Sanitize the ID to ensure it's a valid CSS selector
        const sanitizedId = key.replace(/[^a-zA-Z0-9-_]/g, '-');
        clone.id = `${sanitizedId}-pdfCopy`; // Unique ID for cloned element

        containerWrapper.appendChild(clone); // Append each cloned element to the container wrapper


      }
    });

    // Append the entire group of cloned containers inside the border to pdfCopy
    pdfCopy.appendChild(containerWrapper);

    // Clear the temporary selection arrays
    setMarkForExport([]);
    setNewViewArray([]);
  } else {
    alert("No selections made.");
  }
};


const handleChecked = (event, lineItemZero) => {
  // Toggle the presence of `lineItemZero` in the markForExport array
  setMarkForExport((prevArray) =>
    prevArray.includes(lineItemZero)
      ? prevArray.filter((id) => id !== lineItemZero)
      : [...prevArray, lineItemZero]
  );

  // Find the element and clone it if it's being added to markForExport
  const element = document.getElementById(lineItemZero);
  const pdfCopy = document.getElementById('pdfCopy');

  if (element) {
    // Sanitize the ID to make it a valid CSS selector
    const sanitizedId = `${lineItemZero}-pdfCopy`.replace(/[^a-zA-Z0-9-_]/g, '-');

    const elementClone = element.cloneNode(true);
    const cloneId = sanitizedId; // Use sanitized ID for uniqueness

    // Remove all button elements from the clone
    const buttons = elementClone.querySelectorAll('button');
    buttons.forEach((button) => button.remove()); // Remove each button

    // Remove all checkbox input elements from the clone
    const checkboxes = elementClone.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => checkbox.remove()); // Remove each checkbox

    // Remove all label elements from the clone
    const labels = elementClone.querySelectorAll('label');
    labels.forEach((label) => label.remove()); // Remove each label

    if (markForExport.includes(lineItemZero)) {
      // Remove from pdfCopy if the element is unmarked
      const existingClone = pdfCopy.querySelector(`#${cloneId}`);
      if (existingClone) {
        pdfCopy.removeChild(existingClone);
      }
    } else {
      // Add cloned element to pdfCopy if it's not already there
      if (!pdfCopy.querySelector(`#${cloneId}`)) {
        elementClone.id = cloneId; // Set sanitized unique ID for the cloned element
        pdfCopy.appendChild(elementClone); // Append to pdfCopy
      }
    }
  }
};

// Handle checked items for matched export (same logic for removing buttons, checkboxes, and labels)
const handleCheckedMatched = (event, lineItemZero) => {
  // Toggle the presence of `lineItemZero` in the markConfirm array
  setMarkConfirm((prevArray) =>
    prevArray.includes(lineItemZero)
      ? prevArray.filter((id) => id !== lineItemZero)
      : [...prevArray, lineItemZero]
  );

  // Similarly, toggle the presence of `lineItemZero` in the markForExport array
  setMarkForExport((prevArray) =>
    prevArray.includes(lineItemZero)
      ? prevArray.filter((id) => id !== lineItemZero)
      : [...prevArray, lineItemZero]
  );

  // Clone and add to pdfCopy for the matched export
  const element = document.getElementById(lineItemZero);
  const pdfCopy = document.getElementById('pdfCopy');

  if (element) {
    const elementClone = element.cloneNode(true);
    const cloneId = `${lineItemZero}-pdfCopy`; // Make the ID unique

    // Remove all button elements from the clone
    const buttons = elementClone.querySelectorAll('button');
    buttons.forEach((button) => button.remove()); // Remove each button

    // Remove all checkbox input elements from the clone
    const checkboxes = elementClone.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => checkbox.remove()); // Remove each checkbox

    // Remove all label elements from the clone
    const labels = elementClone.querySelectorAll('label');
    labels.forEach((label) => label.remove()); // Remove each label

    if (markForExport.includes(lineItemZero)) {
      // Remove from pdfCopy if the element is unmarked
      const existingClone = pdfCopy.querySelector(`#${cloneId}`);
      if (existingClone) {
        pdfCopy.removeChild(existingClone);
      }
    } else {
      // Add cloned element to pdfCopy if it's not already there
      if (!pdfCopy.querySelector(`#${cloneId}`)) {
        elementClone.id = cloneId; // Set unique ID for the cloned element
        pdfCopy.appendChild(elementClone); // Append to pdfCopy
      }
    }
  }
};



const handleStayChecked = (lineItemZero) => {
  if (markForExport.includes(lineItemZero)) {
    return true
  }
  else {
    return false
  }
}

const handleStayCheckedMatched = (lineItemZero) => {
  if (markConfirm.includes(lineItemZero)) {
    return true
  }
  else {
    return false
  }
}

function returnDoc2Zones(val) {
  return val
}

function returnSubZonesArray(zone1Name, index) {
  if (newZonesToCompare[index]["SubZones"].length === 0) {
    return bestMatch(zone1Name)
  }
  else {
    return (
      newZonesToCompare[index]["SubZones"][0].map((val, i) => {
        return (val.name + "\t")
      })
    )
  }
}

function findDocNumber(key1) {
  if(key1 % 2 === 0) {
    return true
  }
  else {
    return false
  }
}

function renderZones() {

  if(data["claimData"] && data["results"]){ 
    if(((Object.keys(data.full_results)).length === 0) || ((Object.keys(data.results)).length === 0)) {
      return (
        <div>
          <p className={'loading-comparisons-text'}> Insufficient Results to Display... </p>
          <p> Data may not have been parsed properly from one of the two documents. </p>
        </div>
      ) 
    }  

    let resultsLayer = data[currentSection]
    let resLayer1 = Object.values(resultsLayer).map((val, i) => Object.values(val).slice(0,1).toString())
    let noZone1Duplicates = [...new Set(resLayer1)]
    let resLayer2 = Object.values(resultsLayer).map((val, i) => (Object.values(val).slice(1, 2)).toString())
    let noZone2Duplicates = [...new Set(resLayer2)]
    doc1ZoneState.push(...noZone1Duplicates)
    let dropdown = noZone2Duplicates.map((zone2Name, index) =>
      {return({
        name: zone2Name,
        id: index
      })}
      
    )

    if(newZonesToCompare.length > 0) {
      return(
          <div style={getZonePairingsContainer}>
            {noZone1Duplicates.map((zone1Name, index) =>
            <div style={mapStyle} key={index}>

              <div>
                <label style={labelStyle}>Showing Zone From Document 1: </label>
                <label style={zoneStyle}>{zone1Name}</label>
              </div>
              <div>
                <label style={labelStyle}>Showing Zone From Document 2: </label>
                <label style={zoneStyle}>{returnSubZonesArray(zone1Name, index)}</label>
                <Multiselect 
                options={dropdown}
                key={zone1Name} 
                id={zone1Name}  
                className={'dropdown-style'}
                onSelect={(e) => handleSelect(zone1Name, e)} 
                onRemove={(e) => handleSelect(zone1Name, e)}
                hidePlaceholder = {true}
                placeholder = {" Change Zone(s) "}
                isMulti
                displayValue="name"
                />
              </div>   
              <div>
                {Object.entries(resultsLayer).map(([zero, zones]) => {
                      if (
                        (newZonesToCompare[index]["SubZones"].length === 0) &&
                        (Object.values(zones).slice(0,1).includes(zone1Name)) &&
                        (Object.values(zones).slice(1,2).includes(bestMatch(zone1Name).toString()))
                        ) {
                        return(
                          <div>
                            {Object.entries(zones).slice(2,3).map(([lineItems, lineItemsObject]) =>
                            <div ref={pdfRef} key={lineItems} id={lineItems}>
                              <label style={doc2ZoneStyle}>{returnDoc2Zones(zones["Zone 2"])}</label>
                              {Object.entries(lineItemsObject).filter(object => object[1]["Percentage"] >= percentValue).sort(function (a, b)  {
                                  return (b[1]["Percentage"] - a[1]["Percentage"])
                                }).map(([lineItemZero, lineItemZeroObject], index) =>
                                <table style={tableStyle} id={zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero} key={lineItemZero}>
                                  <thead>
                                    
                                    <th style={theadStyle}>
                                     {returnDoc2Zones(zones["Zone 1"])} vs. {returnDoc2Zones(zones["Zone 2"])}
                                      &nbsp; ({index + 1}&nbsp;of&nbsp;{Object.entries(lineItemsObject).length})
                                    </th>
                                    
                                  </thead>
                                  {Object.entries(lineItemZeroObject).slice(0, 1).map(([lineItem1, lineItem1PropsVals]) =>
                                    <tbody style={tbodyStyle} key={lineItem1}>

                                      {Object.entries(lineItem1PropsVals).slice(0, 1).map(([desc, descVal]) =>
                                        <tr style={trStyle} key={desc}>
                                          <th style={thStyle} key={descVal}>{desc}: {descVal.length > maxLength ? `${descVal.slice(0, maxLength)}...` : descVal}</th>
                                        </tr>
                                      )}
                                      <tr key={lineItem1} style={trStyle}>
                                      <th style={verticalLabel}>Doc1</th>
                                      {Object.entries(lineItem1PropsVals).slice(1).map(([quantity, quantityVal]) =>
                                        <td style={containsPropVal}>
                                          <td style={tdPropStyle} key={quantity}>{quantity}</td>  
                                          <td style={tdValueStyle} key={quantityVal}>{quantityVal}</td>
                                        </td>
                                      )}
                                      </tr>
                                    </tbody>
                                  )}

                                  {Object.entries(lineItemZeroObject).slice(1, 2).map(([lineItem2, lineItem2PropsVals]) =>
                                    <tbody style={tbodyStyle} key={lineItem2}>
                                      {Object.entries(lineItem2PropsVals).slice(0, 1).map(([desc, descVal]) =>
                                        <tr style={trStyle} key={desc}>
                                          <th style={thStyle} key={descVal}>{desc}: {descVal.length > maxLength ? `${descVal.slice(0, maxLength)}...` : descVal}</th>
                                        </tr>
                                      )}
                                      <tr style={trStyle}>
                                      <th style={verticalLabel}>Doc2</th>
                                      {Object.entries(lineItem2PropsVals).slice(1).map(([quantity, quantityVal]) =>
                                        <td style={containsPropVal}>
                                          <td style={tdPropStyle} key={quantity}>{quantity}</td>  
                                          <td style={tdValueStyle} key={quantityVal}>{quantityVal}</td>
                                        </td>
                                      )}
                                      </tr> 
                                    </tbody>
                                  )}

                                  <tbody style={tbodyStyle}>
                                    <tr style={trStyleFlexStart}>
                                      {Object.values(lineItemZeroObject).slice(2, 3).map((val, index) =>  
                                        <td key={val}><strong></strong></td>
                                      )}
                                      {Object.values(lineItemZeroObject).slice(3, 4).map((val, index) =>                                       
                                          validateRCV(val)
                                      )}
                                      {Object.values(lineItemZeroObject).slice(4, 5).map((val, index) =>  
                                          validateACV(val)
                                      )}    
                                      {Object.entries(lineItemZeroObject).slice(5, 6).map(([key, val]) =>  
                                          validateTotal(key, val)
                                      )}   
                                    </tr>
                                  </tbody>

                                  <tbody>
                                    {renderProperNote(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " +  lineItemZero)}
                                  </tbody>

                                  <tbody style={tbodyStyle}>
                                    
                                    <tr style={lastRow}>
                                      
                                      {renderAddNote(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                      
                                      <FormControlLabel
                                        style={{
                                          fontStyle: 'italic',
                                          color: '#526666',
                                          padding: '2px 8px',
                                          display: 'inline',
                                          margin: '2px',
                                        }}
                                        label="Mark For Export"
                                        control={<Checkbox
                                          size="small"
                                          checked={handleStayChecked(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " +lineItemZero)}
                                          id={zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero}
                                          onChange={(e) => handleChecked(e, zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                        />}
                                      />
                                    
                                    </tr>
                                  </tbody>
                                  
                                </table>
                              )}
                            </div>
                            )}
                            {Object.entries(zones).slice(3,4).map(([lineItems, lineItemsObject]) =>
                            
                            <div>
                              {Object.entries(lineItemsObject).map(([key1, val1]) => {
                                return(
                                <table key={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1} id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1} style={noMatchTableStyle}>
                                  <thead>
                                    
                                    <th style={theadStyle}>
                                      {handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + ' vs. ' + returnDoc2Zones(zones["Zone 2"]) + " " + key1) ? "Confirmed":"Potential"} No-Match {returnDoc2Zones(zones["Zone 1"])} vs. {returnDoc2Zones(zones["Zone 2"])}
                                    </th>
                                    
                                  </thead>
                                  <tbody style={tbodyStyle} key={key1}>

                                    {Object.entries(val1).slice(0,1).map(([key2, val2]) => {
                                    return(
                                    <tr style={trStyle}>
                                      <th style={thStyle}>{key2}: {val2}</th>
                                    </tr>
                                    )
                                    
                                    })}
                                    <tr key={key1} style={trStyle}>
                                    <th style={verticalLabel}>{findDocNumber(key1) ? "Doc1": "Doc2"}</th>
                                    {Object.entries(val1).slice(1).map(([key2, val2]) => {
                                      return(
                                        <td style={containsPropVal}>
                                          <td style={tdPropStyle} key={key2}>{key2}</td>  
                                          <td style={tdValueStyle} key={val2}>{val2}</td>
                                        </td>
                                      )    
                                    })}
                                      </tr>
                                    </tbody>
                                    <tbody>
                                      {renderProperNote("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + ' vs. ' + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                    </tbody>
                                     <tbody style={tbodyStyle}>
                                      <tr style={lastRow}>
                                      
                                      
                                        {renderAddNote("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                        <div>
                                        
                                        <FormControlLabel
                                          style={{
                                            fontStyle: 'italic',
                                            color: '#526666',
                                            padding: '2px 8px',
                                            display: 'inline',
                                            margin: '2px',
                                            width: '20%'
                                          }}
                                          label={handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1) ? "Confirmed No-Match" : "Potential No-Match"}
                                          control={<Checkbox
                                            size="small"
                                            checked={handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                            id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1}
                                            onChange={(e) => handleCheckedMatched(e,"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                          />}
                                        />
                                        <FormControlLabel
                                          style={{
                                            fontStyle: 'italic',
                                            color: '#526666',
                                            padding: '2px 8px',
                                            display: 'inline',
                                            margin: '2px',
                                            width: '20%'
                                          }}
                                          label={"Mark For Export"}
                                          control={<Checkbox
                                            size="small"
                                            
                                          checked={handleStayChecked("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                          id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1}
                                          onChange={(e) => handleChecked(e, "zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                          />}
                                        />
                                        </div>
                                      
                                      </tr>
                                    </tbody>
                                </table>
                                )
                                
                              })}
                              
                            </div>
                            )}
                          </div>
                          )
                      }
                      else if ((newZonesToCompare[index]["SubZones"].length > 0)) {
                        let subzones = (newZonesToCompare[index]["SubZones"]).map((obj, index) => {   
                          return(
                            obj.map((obj2, index) => {
                              return obj2.name
                            })
                          )
                        })
                        for(let i = 0; i < subzones[0].length; i++) {
                          if(
                            (Object.values(zones).slice(0,1).includes(zone1Name)) &&
                            (Object.values(zones).slice(1,2).includes(subzones[0][i]))
                          ){
                            return (
                              <div>
                                {(
                                Object.entries(zones).slice(2,3).map(([lineItems, lineItemsObject]) =>
                                  <div key={lineItems} id={lineItems}>
                                    <label style={doc2ZoneStyle}>{returnDoc2Zones(zones["Zone 2"])}</label>
                                    {Object.entries(lineItemsObject).filter(object => object[1]["Percentage"] >= percentValue).sort(function (a, b)  {
                                      return(b[1]["Percentage"] - a[1]["Percentage"])            
                                      }).map(([lineItemZero, lineItemZeroObject], index) =>
                                      <table style={tableStyle} id={zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero} key={lineItemZero}>
                                        <thead>
                                          <th style={theadStyle}>
                                          {returnDoc2Zones(zones["Zone 1"])} vs. {returnDoc2Zones(zones["Zone 2"])}
                                          &nbsp; ({index + 1}&nbsp;of&nbsp;{Object.entries(lineItemsObject).length})

                                          </th>
                                        </thead>
                                        {Object.entries(lineItemZeroObject).slice(0, 1).map(([lineItem1, lineItem1PropsVals]) =>
                                          <tbody style={tbodyStyle} key={lineItem1}>
                                            {Object.entries(lineItem1PropsVals).slice(0, 1).map(([desc, descVal]) =>
                                              <tr style={trStyle} key={desc}>
                                                <th style={thStyle} key={descVal}>{desc}: {descVal.length > maxLength ? `${descVal.slice(0, maxLength)}...` : descVal}</th>
                                              </tr>
                                            )}
                                            <tr key={lineItem1} style={trStyle}>
                                            <th style={verticalLabel}>Doc1</th>
                                            {Object.entries(lineItem1PropsVals).slice(1).map(([quantity, quantityVal]) =>
                                              <td style={containsPropVal}>
                                                <td style={tdPropStyle} key={quantity}>{quantity}</td>  
                                                <td style={tdValueStyle} key={quantityVal}>{quantityVal}</td>
                                              </td>
                                            )}
                                            </tr>
                                          </tbody>
                                        )}
                                        {Object.entries(lineItemZeroObject).slice(1, 2).map(([lineItem2, lineItem2PropsVals]) =>
                                          <tbody style={tbodyStyle} key={lineItem2}>
                                            {Object.entries(lineItem2PropsVals).slice(0, 1).map(([desc, descVal]) =>
                                              <tr style={trStyle} key={desc}>
                                                <th style={thStyle} key={descVal}>{desc}: {descVal.length > maxLength ? `${descVal.slice(0, maxLength)}...` : descVal}</th>
                                              </tr>
                                            )}
                                            <tr style={trStyle}>
                                            <th style={verticalLabel}>Doc2</th>

                                            {Object.entries(lineItem2PropsVals).slice(1).map(([quantity, quantityVal]) =>
                                              <td style={containsPropVal}>
                                                <td style={tdPropStyle} key={quantity}>{quantity}</td>  
                                                <td style={tdValueStyle} key={quantityVal}>{quantityVal}</td>
                                              </td>
                                            )}
                                            </tr>
                                          </tbody>
                                        )}
                                        <tbody style={tbodyStyle}>
                                          <tr style={trStyleFlexStart}>
                                            {Object.values(lineItemZeroObject).slice(2, 3).map((val, index) =>  
                                              <td key={val}> <strong></strong></td>
                                            )}
                                            {Object.values(lineItemZeroObject).slice(3, 4).map((val, index) =>                                       
                                                validateRCV(val)
                                            )}
                                            {Object.values(lineItemZeroObject).slice(4, 5).map((val, index) =>  
                                                validateACV(val)
                                            )}
                                            {Object.entries(lineItemZeroObject).slice(5, 6).map(([key, val]) =>  
                                                validateTotal(key, val)
                                            )} 
                                          </tr>
                                        </tbody>
                                        <tbody>
                                          {renderProperNote(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                        </tbody>
                                        <tbody style={tbodyStyle}>
                                          <tr style={lastRow}>
                                            
                                            {renderAddNote(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                            <FormControlLabel
                                              style={{
                                                fontStyle: 'italic',
                                                color: '#526666',
                                                padding: '2px 8px',
                                                margin: '2px',
                                                display: 'inline',
                                              }}
                                              label="Mark For Export"
                                              control={<Checkbox
                                                size="small"
                                                checked={handleStayChecked(zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                                id={zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero}
                                                onChange={(e) => handleChecked(e, zones["Zone 1"] + " vs. " + zones["Zone 2"] + " " + lineItemZero)}
                                              />}
                                            />
                                          </tr>
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                )
                            )}
                                {
                                  (
                                    Object.entries(zones).slice(3,4).map(([lineItems, lineItemsObject]) =>
                                      <div>
                                {Object.entries(lineItemsObject).map(([key1, val1]) => {
                                  return(
                                  <table key={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1} id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1} style={noMatchTableStyle}>
                                    <thead>
                                      
                                      <th style={theadStyle}>
                                        {handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + ' vs. ' + returnDoc2Zones(zones["Zone 2"]) + " " + key1) ? "Confirmed":"Potential"} No-Match {returnDoc2Zones(zones["Zone 1"])} vs. {returnDoc2Zones(zones["Zone 2"])}
                                      </th>
                                      
                                    </thead>
                                    <tbody style={tbodyStyle} key={key1}>

                                      {Object.entries(val1).slice(0,1).map(([key2, val2]) => {
                                      return(
                                      <tr style={trStyle}>
                                        <th style={thStyle}>{key2}: {val2}</th>
                                      </tr>
                                      )
                                      
                                      })}
                                      <tr key={key1} style={trStyle}>
                                      <th style={verticalLabel}>{findDocNumber(key1) ? "Doc1": "Doc2"}</th>
                                      {Object.entries(val1).slice(1).map(([key2, val2]) => {
                                        return(
                                          <td style={containsPropVal}>
                                            <td style={tdPropStyle} key={key2}>{key2}</td>  
                                            <td style={tdValueStyle} key={val2}>{val2}</td>
                                          </td>
                                        )    
                                      })}
                                        </tr>
                                      </tbody>
                                      <tbody>
                                        {renderProperNote("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + ' vs. ' + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                      </tbody>
                                      <tbody style={tbodyStyle}>
                                        <tr style={lastRow}>
                                        
                                        
                                          {renderAddNote("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                          <div>
                                            
                                          <FormControlLabel
                                                style={{
                                                  fontStyle: 'italic',
                                                  color: '#526666',
                                                  padding: '2px 8px',
                                                  display: 'inline',
                                                  margin: '2px',
                                                  width: '20%'
                                                }}
                                                label={handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1) ? "Confirmed No-Match" : "Potential No-Match"}
                                                control={<Checkbox
                                                  size="small"
                                                  checked={handleStayCheckedMatched("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                  id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1}
                                                  onChange={(e) => handleCheckedMatched(e,"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                />}
                                              />
                                              <FormControlLabel
                                                style={{
                                                  fontStyle: 'italic',
                                                  color: '#526666',
                                                  padding: '2px 8px',
                                                  display: 'inline',
                                                  margin: '2px',
                                                  width: '20%'
                                                }}
                                                label={"Mark For Export"}
                                                control={<Checkbox
                                                  size="small"
                                                  
                                                checked={handleStayChecked("zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                id={"zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1}
                                                onChange={(e) => handleChecked(e, "zz PNM " + returnDoc2Zones(zones["Zone 1"]) + " vs. " + returnDoc2Zones(zones["Zone 2"]) + " " + key1)}
                                                />}
                                              />
                                  
                                          </div>
                                        
                                        </tr>
                                      </tbody>
                                  </table>
                                  )
                                  
                                })}
                                
                              </div>
                                      )
                                  )
                                  }
                                </div>
                              )
                            }
                          }
                        }
                    }
                  )} 
              </div> 
              
            </div>
            
            )}

            <div style={borderTop}>
            {noMatchesEndOfResultsA('zzz')}
            {noMatchesEndOfResultsB('zzz')}
            </div>
          </div>
      )
    }
  }
  
}

function bestMatch(zone1Name) {
  if(data["claimData"] != null){
    let resultsLayer = data[currentSection]
    let resLayer1 = Object.values(resultsLayer).map((val, i) => Object.values(val).slice(0,1).toString())
    let noZone1Duplicates = [...new Set(resLayer1)]
    let resLayer2 = Object.values(resultsLayer).map((val, i) => (Object.values(val).slice(1, 2)).toString())
    let noZone2Duplicates = [...new Set(resLayer2)]
      return(
        Object.entries(stringSimilarity.findBestMatch(zone1Name, noZone2Duplicates)).slice(1,2).map(([key, val]) => 
          Object.entries(val).slice(0,1).map(([key2, val2]) => 
            {return(
              val2 
            )}
    )))
  }
}

function toggleView() {
  setToggled(!toggled)
}

  useEffect(() => {
    if (data.results) {
      filterLineItems();
    }
  }, [selectedZone1, selectedZone2, filterTextZone1, filterTextZone2, data]); // Re-run filter on changes
  // Assuming markForExport and confirmedForExport are defined in your component state
  useEffect(() => {
    const pdfCopy = document.getElementById('pdfCopy');
    if (pdfCopy) {
      setPdfCopyContent(pdfCopy.childElementCount > 0);
    }
    setConfirmedForExport(markForExport);
  }, [markForExport]); // Triggers whenever markForExport changes

  // Filter line items based on selected zones and filter text
  const filterLineItems = () => {
    if (!data.results) return;
  
    const filteredZone1Items = [];
    const filteredZone2Items = [];
  
    const zone1Set = new Set();
    const zone2Set = new Set();
  
    Object.entries(data.results).forEach(([key, item]) => {
      const zone1Name = item["Zone 1"];
      const zone2Name = item["Zone 2"];
  
      Object.entries(item["Line Items"]).forEach(([lineItemKey, lineItemValue]) => {
        const line1Key = `${zone1Name}-${lineItemValue["Line Item 1"]?.DESCRIPTION || ""}`;
        const line2Key = `${zone2Name}-${lineItemValue["Line Item 2"]?.DESCRIPTION || ""}`;
  
        // Function to check if filter text exists in line item properties
        const matchesFilterZone1 = (lineItem) => {
          return Object.values(lineItem).some(value =>
            value.toString().toLowerCase().includes(filterTextZone1.toLowerCase())
          );
        };
  
        const matchesFilterZone2 = (lineItem) => {
          return Object.values(lineItem).some(value =>
            value.toString().toLowerCase().includes(filterTextZone2.toLowerCase())
          );
        };
  
        // Add Line Item 1 to Zone 1 items if it matches the filter text for Document 1
        if (
          (selectedZone1.length === 0 || selectedZone1.includes(zone1Name)) &&
          lineItemValue["Line Item 1"] &&
          !zone1Set.has(line1Key) &&
          matchesFilterZone1(lineItemValue["Line Item 1"])
        ) {
          zone1Set.add(line1Key);
          filteredZone1Items.push({ zone: zone1Name, item: lineItemValue["Line Item 1"] });
        }
  
        // Add Line Item 2 to Zone 2 items if it matches the filter text for Document 2
        if (
          (selectedZone2.length === 0 || selectedZone2.includes(zone2Name)) &&
          lineItemValue["Line Item 2"] &&
          !zone2Set.has(line2Key) &&
          matchesFilterZone2(lineItemValue["Line Item 2"])
        ) {
          zone2Set.add(line2Key);
          filteredZone2Items.push({ zone: zone2Name, item: lineItemValue["Line Item 2"] });
        }
      });
    });
  
    setFilteredItems({ zone1: filteredZone1Items, zone2: filteredZone2Items });
  };
  
  const handleZone1Change = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(opt => opt.value) : [];
    setSelectedZone1(selectedValues);
  };

  const handleZone2Change = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(opt => opt.value) : [];
    setSelectedZone2(selectedValues);
  };

  const handleFilterTextZone1Change = (event) => {
    setFilterTextZone1(event.target.value);
  };

  const handleFilterTextZone2Change = (event) => {
    setFilterTextZone2(event.target.value);
  };
  
  // You can then merge this style into the sx prop if you're using it inline with your Button.
  
  const handleFocus = () => {
  
    if(newViewArray.length > 0) {
      if (!window.confirm('Changing the filters will clear your selections. Proceed?')) {
        return;
      }
    }
    
    setNewViewArray([]);
  };

  const renderView = () => {
    const zone1Set = new Set();
    const zone2Set = new Set();
  
    if (data.results) {
      Object.entries(data.results).forEach(([key, item]) => {
        if (item["Zone 1"]) zone1Set.add(item["Zone 1"]);
        if (item["Zone 2"]) zone2Set.add(item["Zone 2"]);
      });
    }
  
    const uniqueZone1s = Array.from(zone1Set).map(zone => ({ value: zone, label: zone }));
    const uniqueZone2s = Array.from(zone2Set).map(zone => ({ value: zone, label: zone }));
    const clearFilterText1 = () => {
      setFilterTextZone1('');
    };
    const clearFilterText2 = () => {
      setFilterTextZone2('');
    };
  
    const truncateValue = (value) => {
      return value.length > 60 ? value.slice(0, 60) + '...' : value;
    };
  
    return (
      <div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <div onClick={handleFocus}>
            <label>Doc 1 Zones: </label>
            <Select
              isMulti
              value={uniqueZone1s.filter(opt => selectedZone1.includes(opt.value))}
              onChange={handleZone1Change}
              options={uniqueZone1s}
              placeholder="Select Zone"
            />
            <br/>
            <label>Filter (Doc 1): </label>
            <input
              style={filterStyle}
              type="text"
              value={filterTextZone1}
              onChange={handleFilterTextZone1Change}
              placeholder="Enter text to filter"
            />
            {filterTextZone1 && (
              <button
                onClick={clearFilterText1}
                style={{
                  transform: 'translateX(-150%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
              >
                ×
              </button>
            )}
          
          </div>

          <div onClick={handleFocus}>
            <label>Doc 2 Zones: </label>
            <Select
              isMulti
              value={uniqueZone2s.filter(opt => selectedZone2.includes(opt.value))}
              onChange={handleZone2Change}
              options={uniqueZone2s}
              placeholder="Select Zone"
            />
            <br/>
            <label>Filter (Doc 2): </label>
            <input
              style={filterStyle}
              type="text"
              value={filterTextZone2}
              onChange={handleFilterTextZone2Change}
              placeholder="Enter text to filter"
            />
            {filterTextZone2 && (
              <button
                onClick={clearFilterText2}
                style={{
                  transform: 'translateX(-150%)',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '16px',
                }}
              >
                ×
              </button>
            )}
          </div>
        </div>
  
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <div style={{ flex: 1, marginRight: '10px', boxSizing: 'border-box' }}>
            {filteredItems.zone1.length > 0 ? (
              filteredItems.zone1.map((entry, index) => {
                // Create a unique ID using the entry's zone and the index
                const uniqueId = `Doc-1-${entry.zone}-${index}`;

                return (
                  <div id={uniqueId} key={uniqueId}>
                    <div style={filterTableStyle}>
                      {/* Display the first entry on top */}
                      {Object.entries(entry.item).slice(0, 1).map(([key, value]) => (
                        <div key={key} style={blockStyle}>
                          <h4 style={centeredHeader}> (Doc 1) Zone: {entry.zone}</h4>
                          <hr />
                          <br />
                          <div style={thStyle}>{key}: {truncateValue(value)}</div>
                          <br />
                        </div>
                      ))}

                      {/* Display the rest of the entries below */}
                      <div style={trStyle}>
                        {Object.entries(entry.item).slice(1).map(([key, value]) => (
                          <div key={key}>
                            <div style={tdPropStyle}>{key}</div>
                            <div style={tdValueStyle}>{value}</div>
                            <br />
                          </div>
                        ))}
                      </div>

                      {/* Checkbox to mark for export */}
                      <FormControlLabel
                        style={{
                          fontStyle: 'italic',
                          color: '#526666',
                          padding: '2px 8px',
                          display: 'inline',
                          margin: '2px',
                        }}
                        label="Mark For Export"
                        control={<Checkbox
                          size="small"
                          checked={handleNewStayChecked(uniqueId)}
                          id={uniqueId}
                          onChange={() => handleNewChecked(uniqueId)}
                        />}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No line items for Doc 1.</p>
            )}
          </div>
  
          <div style={{ flex: 1, marginLeft: '10px', boxSizing: 'border-box' }}>
            {filteredItems.zone2.length > 0 ? (
              filteredItems.zone2.map((entry, index) => {
                // Create a unique ID using the entry's zone and the index
                const uniqueId = `Doc-2-${entry.zone}-${index}`;

                return (
                  <div id={uniqueId} key={uniqueId}>
                    <div style={filterTableStyle}>
                      {/* Display the first entry on top */}
                      {Object.entries(entry.item).slice(0, 1).map(([key, value]) => (
                        <div key={key} style={blockStyle}>
                          <h4 style={centeredHeader}>(Doc 2) Zone: {entry.zone}</h4>
                          <hr />
                          <br />
                          <div style={thStyle}>{key}: {truncateValue(value)}</div>
                          <br />
                        </div>
                      ))}

                      {/* Display the rest of the entries below */}
                      <div style={trStyle}>
                        {Object.entries(entry.item).slice(1).map(([key, value]) => (
                          <div key={key}>
                            <div style={tdPropStyle}>{key}</div>
                            <div style={tdValueStyle}>{value}</div>
                            <br />
                          </div>
                        ))}
                      </div>

                      {/* Checkbox to mark for export */}
                      <FormControlLabel
                        style={{
                          fontStyle: 'italic',
                          color: '#526666',
                          padding: '2px 8px',
                          display: 'inline',
                          margin: '2px',
                        }}
                        label="Mark For Export"
                        control={<Checkbox
                          size="small"
                          checked={handleNewStayChecked(uniqueId)}
                          id={uniqueId}
                          onChange={() => handleNewChecked(uniqueId)}
                        />}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No line items for Doc 2.</p>
            )}
          </div>
        </div>
      </div>
    );
  };
  

  return (
    <div className={'outer-container'}>
      <MainWrapper/>
      <div style={exportButtons}>
            {!toggled ? <Button
            size="small"
            sx={{
              margin: '8px 8px 8px 0px',
              fontWeight: 'bold',
              color: 'white',
              padding: '6px 20px',
              backgroundColor: '#555555',
              ':hover': {
                backgroundColor: '#999999'
              },
              // Conditional animation when markForExport.length > 0
              animation: newViewArray.length > 0 ? 'fluctuateColor 2s infinite alternate' : 'none',
              
              // Add keyframes directly in the sx prop
              '@keyframes fluctuateColor': {
                '0%': {
                  backgroundColor: '#CCCCCC', // Original color
                },
                '50%': {
                  backgroundColor: '#00B3B5', // A color to fluctuate to (black example)
                },
                '100%': {
                  backgroundColor: '#CCCCCC', // Back to original color
                }
              }
            }}
            onClick={confirmExportSelection}
          > 
            Add Selections To PDF
          </Button>
          : <></>}
            <Button
              size="small"
              sx={{
                ':hover': {
                  backgroundColor: '#999999'
                },
                margin: '8px 8px 8px 0px',
                fontWeight: 'bold',
                color: 'white',
                padding: '6px 20px',
                pointerEvents: pdfCopyContent ? 'auto' : 'none',
                backgroundColor: pdfCopyContent ? '#00B3B5' : '#555555'
              }}
              onClick={openPreviewInNewTab}>
              Preview PDF
            </Button>

            <Button
              size="small"
              sx={{
                ':hover': {
                  backgroundColor: '#999999',
                },
                margin: '8px 0px 8px 0px',
                fontWeight: 'bold',
                color: 'white',
                padding: '6px 20px',
                backgroundColor: pdfCopyContent ? '#00B3B5' : '#555555',
              }}
              onClick={() => {
                if (newViewArray.length > 0) {
                  alert("You have selections that are not added to the PDF!");
                } else {
                  generatePDF(); // Call your PDF generation function if the array is empty
                }
              }}
            >
              Export PDF
            </Button>

            </div>
      <div className={'right-container'}>
        <div className={'comparison-results-container'}>
        <h1 className={'h1-comparison-results'}>
          {toggled ? 'Comparison Results - Zone by Zone' : 'Comparison Results - Filter by Descriptions'}
         
        </h1>
          <div className={'comparison-results-actions-div'}>

            

            {/* <Button 
              size="small"
              sx={{

                ':hover': {
                    backgroundColor: '#999999'
                },
              margin: '8px 8px 8px 0px', fontWeight: 'bold', color: 'white', backgroundColor: '#00B3B5'}}
              onClick={toggleResults}
              >
                Toggle Results
            </Button>  */}
            <Button
              size="small"
              sx={{
                ':hover': {
                    backgroundColor: '#999999'
                },
              margin: '8px 8px 8px 0px', fontWeight: 'bold', color: 'white', backgroundColor: '#00B3B5', padding: '4px 20px'}}
              onClick={() => toggleView()}>
                Toggle View
            </Button>
            {toggled ? (
              <Button 
                size="small"
                sx={{
                  ':hover': {
                      backgroundColor: '#999999'
                  },
                margin: '8px 8px 8px 0px', fontWeight: 'bold', color: 'white', backgroundColor: '#00B3B5', padding: '4px 20px'}}
              
                onClick={() => saveChanges(comparisonID)}
              >
                Save Changes
              </Button>
            ) : <></>}
            
            {toggled ? (
              <div>
                <input
                  id="filter-percentage"
                  type="range"
                  min="0"
                  max={MAX}
                  onChange={(e) => setPercentValue(e.target.value)}
                  style={getBackgroundSize()}
                  value={percentValue}
                />
                <label className={'filter-label'} htmlFor="filter-percentage">LEVLR Match Assistance</label>
              </div>
            ) : <></>}
            
          </div>
          

            
          {/*render mockup with zones*/}
          <div id={'pairings'} className={'pairings-container'}>
            <div className={loadingStatus}></div>


            {toggled ? (
                <>
                    {renderZones()}
                    {renderNote()}
                </>
            ) : (
                renderView()
            )}


          </div>
        </div>

      </div>
  

      <div style={pdfCopy} id={'pdfCopy'}>
        {renderClaimCredentials()}

      </div>
    </div>
  )
}

export default ComparisonResults