// SavedPaymentMethods.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SavedPaymentMethods = ({ customerId }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState('');
  
  useEffect(() => {
    console.log("customerID from saved payment: " + customerId)
    const fetchPaymentMethods = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PAYMENT_METHODS}${customerId}`);
        setPaymentMethods(response.data.data);
        
        // Fetch default payment method
        const customer = await axios.get(`${process.env.REACT_APP_CUSTOMER}${customerId}`);
        const defaultMethod = customer.data.defaultPaymentMethod;
        setDefaultPaymentMethod(defaultMethod);

        // Set the default payment method as selected
        setSelectedPaymentMethod(defaultMethod);
      } catch (error) {
        setErrorMessage(error.message);
      }
    };

    if (customerId) {
      fetchPaymentMethods();
    }
  }, [customerId]);

  const handlePrimaryChange = async (event) => {
    const paymentMethodId = event.target.value;
    setSelectedPaymentMethod(paymentMethodId);
    // Set the selected payment method as primary in Stripe
    try {
      console.log("customerId: " + customerId)
      await axios.post(`${process.env.REACT_APP_SET_DEFAULT_PAYMENT_METHOD}${customerId}`, {
        paymentMethodId: paymentMethodId
      });
      setDefaultPaymentMethod(paymentMethodId);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="default-payments">
      <h2>Default Payment Method</h2>
      <select value={selectedPaymentMethod} onChange={handlePrimaryChange}>
        {paymentMethods.map((method) => (
          <option key={method.id} value={method.id}>
            {method.card.brand} ending in {method.card.last4} - Expires {method.card.exp_month}/{method.card.exp_year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SavedPaymentMethods;
