import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import axios from 'axios'
import { postAddClaim } from '../../actions/claims';
import '../../css/ActiveClaims.css';
import '../../css/AdjustmentsActiveClaims.css';
import MainWrapper from '../wrappers/mainWrapper';
import { CSVLink } from 'react-csv';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const ComparisonReports = props => {
    const [users, setUsers] = useState([])
    //console.log("users: " + JSON.stringify(users,null,2))
    const [comparisons, setComparisons] = useState()
    //console.log("comparisons: " + JSON.stringify(comparisons,null,2))
    const [errors, setErrors] = useState()
    //console.log("errors: " + JSON.stringify(errors,null,2))
    const [specificErrors, setSpecificErrors] = useState()
    const [responded, setResponded] = useState(true)
    const [allUsers, setAllUsers] = useState()
    const [error, setError] = useState("No Filter")
    console.log("error: " + error)
    //const [count001a, setCount001a] = useState(0)
    //const [count001b, setCount001b] = useState(0)
    //const [count002a, setCount002a] = useState(0)
    //const [count002b, setCount002b] = useState(0)
    //const [count003a, setCount003a] = useState(0)
    //const [count003b, setCount003b] = useState(0)
    //const [count004a, setCount004a] = useState(0)
    //const [count004b, setCount004b] = useState(0)
    //const [count070a, setCount070a] = useState(0)
    //const [count070b, setCount070b] = useState(0)
    //const [count080, setCount080] = useState(0)
    //const [count081, setCount081] = useState(0)
    //const [count082, setCount082] = useState(0)
    //const [count404, setCount404] = useState(0)

    let count001a = 0;
    let count001b = 0;
    let count002a = 0;
    let count002b = 0;
    let count003a = 0;
    let count003b = 0;
    let count004a = 0;
    let count004b = 0;
    let count070a = 0;
    let count070b = 0;
    let count080 = 0;
    let count081 = 0;
    let count082 = 0;
    let count404 = 0;

    //console.log("count001a: " + count001a)
    //console.log("allUsers: " + JSON.stringify(allUsers,null,2))
    //console.log("errors: " + JSON.stringify(errors,null,2))
    //console.log("specificErrors: " + JSON.stringify(specificErrors,null,2))

      const headers = [
        { label: "DateUploaded", key: "DateUploaded" },
        { label: "UserName", key: "UserName" },
        { label: "Insured", key: "Insured"},
        { label: "Error", key: "Error" },
        { label: "FileName1", key: "FileName1"},
        { label: "FileName2", key: "FileName2"},
        { label: "UserID", key: "UserID" },
        { label: "CompID", key: "CompID" },
        { label: "FileID1", key: "FileID1" },
        { label: "FileID2", key: "FileID2" },
        { label: "Message", key: "Message" },
      ];


      const [data, setData] = useState([])

          
    useEffect(() => {
        getAllUsers()
        renderErrors()
        if(data.length === 0){
            pushData()
        }
    }, [errors])

      //console.log("data: " + data)

    async function getUser(id) {
        //.log("start")
        const response = await fetch(
            `${process.env.REACT_APP_GET_USER_DATA}${id}`
        ).then((response) => response.json({}));
        //console.log("user: " + JSON.stringify(response,null,2))
    }

    async function getAllUsers() {
        const response = await fetch(
            `${process.env.REACT_APP_GET_ALL_USERS}`
        ).then((response) => response.json({}));
        setAllUsers(response)
    }

    const getComparisonData = async (id) => {
        const response = await fetch(
            `${process.env.REACT_APP_RESULTS_DATA}user/${id._id}`
        ).then((response) => response.json({}));
        setComparisons({...comparisons, response})
        
    }

    const renderErrors = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_FAST_API}errored/allFailed`
        ).then((response) => response.json({}));
        setErrors(response)
        
    }

    const renderSpecificErrors = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_FAST_API}errored/getCode/404`
        ).then((response) => response.json({}));
        setSpecificErrors(response)
    }

    const allComparisonsTable = {
        overflow: 'auto',
        position: 'relative',
        margin: '0 auto',
        padding: '0 auto',
        top: '10%',
        width: '95%',
        height: '600px',
        tableLayout: 'auto'
    }

    function renderUsers(id) {
        //console.log("user: " + (allUsers.find(obj => obj._id === id)).name)
        if((allUsers.find(obj => obj._id === id)) != undefined) {
            return(
                (allUsers.find(obj => obj._id === id)).name
            )
        }

        else {
            return(
                "No Name"
            )
        }
    }
    function checkVal(id) {
        if(id === undefined) {
            return ""
        }
        else {
            return id
        }
    }

    function pushData() {
        if(errors) {  
            Object.entries(errors).reverse().map(([key,val],i) => {
                if(val.errorCode) {
                    setData(data => [...data, {
                        DateUploaded: val.dateUploaded, 
                        UserName: renderUsers(val.claimData.user_id), 
                        Insured: val.claimData.insured,
                        Error: val.errorCode,  
                        FileName1: checkVal(val.claimData.file1Name), 
                        FileName2: checkVal(val.claimData.file2Name), 
                        UserID: val.claimData.user_id, 
                        CompID: val._id.ID,
                        FileID1: val.files[0][1], 
                        FileID2: val.files[1][1], 
                        Message: val.errorCodeMessage
                    }])
                }
                //console.log("testing: " + JSON.stringify(getUser(val._id.ID),null,2))
            })
        }
    }

    function countErrorCodes(id) {
        //console.log("id: " + id)
        if(id === "001a") {
            (count001a++)
        }
        if(id === "001b") {
            (count001b++)
        }
        if(id === "002a") {
            (count002a++)
        }
        if(id === "002b") {
            (count002b++)
        }
        if(id === "003a") {
            (count003a++)
        }
        if(id === "003b") {
            (count003b++)
        }
        if(id === "004a") {
            (count004a++)
        }
        if(id === "004b") {
            (count004b++)
        }
        if(id === "070a") {
            (count070a++)
        }
        if(id === "070b") {
            (count070b++)
        }
        if(id === "080") {
            (count080++)
        }
        if(id === "081") {
            (count081++)
        }
        if(id === "082") {
            (count082++)
        }
        if(id === "404") {
            (count404++)
        }
        return id
    }

    const handleChange = (event) => {
        setError(event.target.value );
      };

    function returnErrorTables() {
        if(errors) {
            //console.log("error0: " + JSON.stringify(errors[0],null,2))
            return(
                <table style={{    
                    borderCollapse: 'initial',
                    borderSpacing: '0 4px',
                    padding: '12px',
                    tableLayout: 'fixed',
                    width: 'auto'
                }}
                    id={'CSV-Data'}
                >
                    <thead>
                        <th>#</th>
                        <th>Date Uploaded</th>
                        <th>Username</th>
                        <th>Insured</th>
                        <th>Error</th>
                        <th>File 1 Name</th>
                        <th>File 2 Name</th>
                        <th>User Id</th>
                        <th>Comparison Id</th>
                        <th>File ID 1</th>
                        <th>File ID 2</th>
                        <th>Message</th>
                    </thead>       
                    {Object.entries(errors).reverse().map(([key,val], i) => {
                        if(val.errorCode && error === "No Filter" && val.claimData.user_id != "631a4f0938f1d698d92764f8" && val.claimData.user_id != "6310f478003e1ca38bede627") {
                            return(
                                <tbody>
                                    <tr>
                                        <td>{i}</td>
                                        <td>{val.dateUploaded}</td>
                                        <td>{renderUsers(val.claimData.user_id)}</td>
                                        <td>{val.claimData.insured}</td>
                                        <td>{countErrorCodes(val.errorCode)}</td>
                                        <td>{checkVal(val.claimData.file1Name)}</td>
                                        <td>{checkVal(val.claimData.file2Name)}</td>
                                        <td>{val.claimData.user_id}</td>
                                        <td>{val._id.ID}</td>
                                        <td>{val.files[0][1]}</td>
                                        <td>{val.files[1][1]}</td>
                                        <td>{val.errorCodeMessage}</td>
                                    </tr>
                                </tbody>                                                    
                            )
                        }
                        if(val.errorCode && error != "No Filter" && val.errorCode === error && val.claimData.user_id != "631a4f0938f1d698d92764f8" && val.claimData.user_id != "6310f478003e1ca38bede627") {
                            return(
                                <tbody>
                                    <tr>
                                        <td>{i}</td>
                                        <td>{val.dateUploaded}</td>
                                        <td>{renderUsers(val.claimData.user_id)}</td>
                                        <td>{val.claimData.insured}</td>
                                        <td>{countErrorCodes(val.errorCode)}</td>
                                        <td>{checkVal(val.claimData.file1Name)}</td>
                                        <td>{checkVal(val.claimData.file2Name)}</td>
                                        <td>{val.claimData.user_id}</td>
                                        <td>{val._id.ID}</td>
                                        <td>{val.files[0][1]}</td>
                                        <td>{val.files[1][1]}</td>
                                        <td>{val.errorCodeMessage}</td>
                                    </tr>
                                </tbody>                                                    
                            )
                        }
                })}
                    
                </table>
            )
        }
    }
    return (
        <div className={'outer-container'}>
            <MainWrapper/>
            <div className={'right-container'}>

                <div style={{overflow: 'auto'}} className={'create-new-claim-container'}>

                    <h1 className={'h1-create-new-claim'}>Failed Comparisons Report</h1>
                    <FormControl 
                            style={{
                                display: 'block',
                                float: 'right',
                                margin: '8px'
                            }}
                            sx={{
                                minWidth: '300px'
                            }}
                        >
                            <InputLabel  
                                sx={{
                                    minWidth: '300px'
                                }}
                                id="demo-simple-select-label">Filter By Error Code</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Error"
                                value={error}
                                onChange={handleChange}
                                sx={{
                                    minWidth: '300px'
                                }}
                            >
                                <MenuItem value={"No Filter"}>No Filter</MenuItem>
                                <MenuItem value={"001a"}>001a</MenuItem>
                                <MenuItem value={"001b"}>001b</MenuItem>
                                <MenuItem value={"002a"}>002a</MenuItem>
                                <MenuItem value={"002b"}>002b</MenuItem>
                                <MenuItem value={"003a"}>003a</MenuItem>
                                <MenuItem value={"003b"}>003b</MenuItem>
                                <MenuItem value={"004a"}>004a</MenuItem>
                                <MenuItem value={"004b"}>004b</MenuItem>
                                <MenuItem value={"070a"}>070a</MenuItem>
                                <MenuItem value={"070b"}>070b</MenuItem>
                                <MenuItem value={"080"}>080</MenuItem>
                                <MenuItem value={"081"}>081</MenuItem>
                                <MenuItem value={"082"}>082</MenuItem>
                                <MenuItem value={"404"}>404</MenuItem>
                            </Select>
                        </FormControl>
                        <CSVLink 
                            headers={headers}
                            data={data}
                            target="_blank"
                            filename={`Levlr-Failed-Comparisons-Report-${new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()}.csv`}
                            style={{float: 'right',padding: '8px 8px'}}
                        >
                            Export to CSV
                        </CSVLink>

                        
                    <div style={allComparisonsTable}>
                        {returnErrorTables()}
                        
                    </div>

                    <table style={{width: '80%',position: 'fixed', bottom: '12px'}}>
                            <thead>
                                <th>001a</th>
                                <th>001b</th>
                                <th>002a</th>
                                <th>002b</th>
                                <th>003a</th>
                                <th>003b</th>
                                <th>004a</th>
                                <th>004b</th>
                                <th>070a</th>
                                <th>070b</th>
                                <th>080</th>
                                <th>081</th>
                                <th>082</th>
                                <th>404</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{count001a}</td>
                                    <td>{count001b}</td>
                                    <td>{count002a}</td>
                                    <td>{count002b}</td>
                                    <td>{count003a}</td>
                                    <td>{count003b}</td>
                                    <td>{count004a}</td>
                                    <td>{count004b}</td>
                                    <td>{count070a}</td>
                                    <td>{count070b}</td>
                                    <td>{count080}</td>
                                    <td>{count081}</td>
                                    <td>{count082}</td>
                                    <td>{count404}</td>
                                </tr>
                            </tbody>
                        </table>

                </div>

            </div>
        </div>
    )
}

export default ComparisonReports