import React, {useState, useRef} from 'react';

import '../../css/InsuranceCompanies.css';
import MainWrapper from '../wrappers/mainWrapper';

const InsuranceCompanies = props => {

    {/* This wont work because of static text values in tds. Need Dynamic values. */}
    const [planVal, setPlanVal] = useState(14);
    const [usedVal, setUsedsedVal] = useState(12);
    
    function tdStyle () {
        if ( planVal < usedVal ) {
            return 'styled-data';
        }
        else {
            return '';
        }
       
    }

    return (
        
        <div className={'outer-container'}>
            <MainWrapper/>
            <div className={'right-container'}>
                <div className={'insurance-companies-container'}>

                    <h1 className={'h1-insurance-companies'}>Active Subscriptions</h1>

                    <table> 

                        <thead>

                            <tr>
                                <th style={{width: '40%'}}>Contractor</th>
                                <th style={{width: '20%'}}>Plan</th>
                                <th style={{width: '20%'}}>Used</th>
                                <th style={{width: '20%'}}>Next Billing</th>
                            </tr>

                        </thead>

                        <tbody>

                            <tr>
                                <td>Scranton Restoration</td>
                                <td><strong>{planVal}</strong> Estimates /mo</td>
                                <td className={tdStyle()}><strong>{usedVal}</strong> Active Estimates</td>
                                <td>08/25/22</td>
                            </tr>

                            <tr>
                                <td>Scranton Restoration</td>
                                <td><strong>{planVal}</strong> Estimates /mo</td>
                                <td className={tdStyle()}><strong>{usedVal}</strong> Active Estimates</td>
                                <td>08/25/22</td>
                            </tr>

                            <tr>
                                <td>Scranton Restoration</td>
                                <td><strong>{planVal}</strong> Estimates /mo</td>
                                <td className={tdStyle()}><strong>{usedVal}</strong> Active Estimates</td>
                                <td>08/25/22</td>
                            </tr>

                            <tr>
                                <td>Scranton Restoration</td>
                                <td><strong>{planVal}</strong> Estimates /mo</td>
                                <td className={tdStyle()}><strong>{usedVal}</strong> Active Estimates</td>
                                <td>08/25/22</td>
                            </tr>

                            <tr>
                                <td>Scranton Restoration</td>
                                <td><strong>{planVal}</strong> Estimates /mo</td>
                                <td className={tdStyle()}><strong>{usedVal}</strong> Active Estimates</td>
                                <td>08/25/22</td>
                            </tr>

                            
                            <tr>
                                <td>Scranton Restoration</td>
                                <td><strong>{planVal}</strong> Estimates /mo</td>
                                <td className={tdStyle()}><strong>{usedVal}</strong> Active Estimates</td>
                                <td>08/25/22</td>
                            </tr>     

                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    )

}

export default InsuranceCompanies