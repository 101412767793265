import React, {useState} from 'react';
import LeftNavigationPanel from '../navigation/leftNavigationPanel';
import MainPanel from '../navigation/mainPanel';

const MainWrapper = props => {

    
    

    return (
        
        <div style={{zIndex: '50'}}>
            <LeftNavigationPanel/>
            <MainPanel/>               
        </div>
    )
    
}

export default MainWrapper