import React from 'react';

import '../../css/ArchivedClaims.css';

import MainWrapper from '../wrappers/mainWrapper';


const ArchivedClaims = props => {  

    return (
        
        <div className={'outer-container'}>
            <MainWrapper/>
            <div className={'right-container'}>

                <div className={'archived-claims-container'}>
                    <h1 className={'h1-archived-claims'}>Archived Comparisons</h1>
                </div>

            </div>
        </div>
    )

}

export default ArchivedClaims