import React, {useState} from 'react';
import variant_logo from "../../img/variants-icon.png";
import closed_estimates_logo from "../../img/claims-pending-icon.png";
import active_estimates_logo from "../../img/claims-icon.png";
import weekly_logo from "../../img/weekly-variant-value-icon.png";

import '../../css/Statistics.css';

import MainWrapper from '../wrappers/mainWrapper';


const Statistics = props => {

    const img_resize = {
        height: '100%',
        width: 'auto'
    }

    const [isVariants, setVariants] = React.useState(false);
    const [isActive, setActive] = React.useState(false);
    const [isClosed, setClosed] = React.useState(false);
    const [isWeekly, setWeekly] = React.useState(false);

    function viewAllVariants() {
        setVariants(!isVariants);
    }

    function viewAllActive() {
        setActive(!isActive);
    }

    function viewAllClosed() {
        setClosed(!isClosed);
    }

    function viewAllWeekly() {
        setWeekly(!isWeekly);
    }

    return (

        <div className={'outer-container'}>
            <MainWrapper/>
            <div className={'right-container'}>

            <h1 className={'h1-statistics'}>Weekly Statistics</h1>
                
                <div className={'statistics-container'}>

                    <div className={'variants-container'}>

                        <div className={'variants-container-headers'}>
                            <img src={variant_logo} style={img_resize}></img>
                            <span className={'header-bold'}>VARIANTS </span>
                            <span className={'header-light'}> Found This Week: </span>
                            <strong className={'header-bold'}> 194</strong>
                        </div>

                        <div className={'table-container'}>
                            <table>

                                <thead>
                                    <th>Client</th>
                                    <th>Estimate #</th>
                                    <th>Carrier</th>
                                    <th>Adjuster</th>  
                                    <th>Variants</th>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>  

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                    <tr>
                                        <td>James Halpert</td>   
                                        <td>0004857</td> 
                                        <td>Farmers</td>
                                        <td>Creed Bratton</td>
                                        <td>12</td>
                                    </tr>

                                </tbody>

                            </table>
                            
                        </div>    
                    
                        <button className={'variants-view-all'} onClick={() => {viewAllVariants();}}>View All</button>

                    </div>


                    <div className={'active-estimates-container'}>

                        <div className={'active-estimates-container-headers'}>
                            <img src={active_estimates_logo} style={img_resize}></img>
                            <div className={'inline-or-block'}>
                                <span className={'header-bold'}>ESTIMATES</span>
                        
                                <span className={'header-light'}>Active This Week </span>
                            </div>
                            <strong className={'header-bold-large'}>17</strong>
                        </div>

                        <div className={'table-container'}>

                            <table>

                                <thead>
                                    <th>Estimate #</th>
                                    <th>Client</th>
                                    <th>Total</th>
                                </thead>

                                <tbody>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                    <tr>
                                        <td>0004857</td>
                                        <td>James Halpert</td>
                                        <td>$105,870</td>
                                    </tr>

                                </tbody>
                                
                            </table>

                        </div>

                        <button className={'active-estimates-view-all'} onClick={() => {viewAllActive();}}>View All</button>

                    </div>

                    <div className={'closed-estimates-container'}>

                        <div className={'closed-estimates-container-headers'}>
                            <img src={closed_estimates_logo} style={img_resize}></img>
                            <div className={'inline-or-block'}>
                                <span className={'header-bold'}>ESTIMATES</span>
                      
                                <span className={'header-light'}>Closed This Week</span>
                            </div>
                            <strong className={'header-bold-large'}>4</strong>
                        </div>

                        <div className={'table-container'}>

                            <table>

                                <thead>
                                    <th>Client</th>
                                    <th>Estimate</th>
                                    <th>Paid Out</th>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Prince Paper Comp...</td>
                                        <td>$5,468,750</td>
                                        <td>$5,468,350</td>
                                    </tr>

                                    <tr>
                                        <td>Prince Paper Comp...</td>
                                        <td>$5,468,750</td>
                                        <td>$5,468,350</td>
                                    </tr>

                                    <tr>
                                        <td>Prince Paper Comp...</td>
                                        <td>$5,468,750</td>
                                        <td>$5,468,350</td>
                                    </tr>

                                </tbody>

                            </table>

                        </div>

                        <div className={'table-summary'}>
                                <span>Weekly Pay Out: </span>
                                <strong>16,962,250</strong>
                                
                        </div>

                        <button className={'closed-estimates-view-all'} onClick={() => {viewAllClosed();}}>View All</button>

                    </div>

                    <div className={'weekly-container'}>

                        <div className={'weekly-container-headers'}>
                            <img src={weekly_logo} style={img_resize}></img>
                            <span className={'header-bold'}>WEEKLY</span>
                            <span className={'header-light'}>Variant Value:</span>
                            <strong className={'header-bold'}>$18,489,230</strong>
                        </div>
                        
                        <div className={'table-container'}>

                                <table>

                                    <thead>
                                        <th>Client</th>
                                        <th>Estimate #</th>
                                        <th>Carrier</th>
                                        <th>Estimate</th>
                                        <th>Adjusted</th>
                                        <th>Difference</th>
                                    </thead>
                                    
                                    <tbody>
                                        <tr>
                                            <td>James Halpert</td>
                                            <td>0004857</td>
                                            <td>Farmers</td>
                                            <td>$105,870</td>
                                            <td>$80,450</td>
                                            <td>$16,420</td>
                                        </tr>

                                        <tr>
                                            <td>James Halpert</td>
                                            <td>0004857</td>
                                            <td>Farmers</td>
                                            <td>$105,870</td>
                                            <td>$80,450</td>
                                            <td>$16,420</td>
                                        </tr>

                                        <tr>
                                            <td>James Halpert</td>
                                            <td>0004857</td>
                                            <td>Farmers</td>
                                            <td>$105,870</td>
                                            <td>$80,450</td>
                                            <td>$16,420</td>
                                        </tr>

                                        <tr>
                                            <td>James Halpert</td>
                                            <td>0004857</td>
                                            <td>Farmers</td>
                                            <td>$105,870</td>
                                            <td>$80,450</td>
                                            <td>$16,420</td>
                                        </tr>

                                        <tr>
                                            <td>James Halpert</td>
                                            <td>0004857</td>
                                            <td>Farmers</td>
                                            <td>$105,870</td>
                                            <td>$80,450</td>
                                            <td>$16,420</td>
                                        </tr>

                                        <tr>
                                            <td>James Halpert</td>
                                            <td>0004857</td>
                                            <td>Farmers</td>
                                            <td>$105,870</td>
                                            <td>$80,450</td>
                                            <td>$16,420</td>
                                        </tr>

                                        <tr>
                                            <td>James Halpert</td>
                                            <td>0004857</td>
                                            <td>Farmers</td>
                                            <td>$105,870</td>
                                            <td>$80,450</td>
                                            <td>$16,420</td>
                                        </tr>
                                    </tbody>

                                </table>

                            </div>

                        <button className={'weekly-view-all'} onClick={() => {viewAllWeekly();}}>View All</button>

                    </div>

                </div>

            </div>

        </div>
    )

}

export default Statistics