import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import '../../css/ComparisonResults.css';
import MainWrapper from '../wrappers/mainWrapper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

const FilteredComparisons = () => {
  const [responded, setResponded] = useState(false);
  const [data, setData] = useState({});
  const { comparisonID } = useParams();
  const [selectedZone1, setSelectedZone1] = useState([]);
  const [selectedZone2, setSelectedZone2] = useState([]);
  const [filterTextZone1, setFilterTextZone1] = useState(''); // State for zone 1 filter text
  const [filterTextZone2, setFilterTextZone2] = useState(''); // State for zone 2 filter text
  const [filteredItems, setFilteredItems] = useState({ zone1: [], zone2: [] });
  const navigate = useNavigate();

//styles
const tableStyle = { 
  position: 'relative',
  height: 'auto',
  width: '98%',
  backgroundColor: '#DDDDDD',
  borderRadius: '4px',
  padding: '12px 8px',
  borderSizing: 'border-box',
  margin: '12px 0px 24px 0px',
  overflow: 'auto',
  border: '1px dotted black'
}

const centeredHeader = {
  textAlign: 'center'
}

const thStyle = {
  fontWeight: 'bold',
  fontStyle: 'italic',
  color: '#526666',
  border: 'none',
  textAlign: 'left',
  alignSelf: 'center'
}

const trStyle = {
  width: '100%',
  padding: '0px 0px',
  display: 'flex',
  justifyContent: 'space-between'
}

const blockStyle = {
  display: 'block'
}

const tdPropStyle = {
  display: 'block',
  margin: '0px 0px',
  color: '#00B3B5',
  textAlign: 'left',
}

const tdValueStyle = {
  color: '#526666',
}

const filterStyle = {
  height: '16px',
  padding: '6px'
}

  useEffect(() => {
    if (!responded) {
      fetchResultsData();

    }
  }, [responded]);

  function toggleView() {
    navigate(`/comparisonResults/${comparisonID}`)
  }     


  // Fetch comparison results data
  const fetchResultsData = async () => {
    try {
      const comp = await fetch(`${process.env.REACT_APP_RESULTS_DATA}comp/${comparisonID}`, { timeout: 8000 });
      const compData = await comp.json();

      if (compData.hasOwnProperty("resultsID") || compData.hasOwnProperty("full_resultsID")) {
        await handleResultIDs(compData);
      } else {
        setData(compData);
        setResponded(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Handle fetching based on resultsID and full_resultsID
  const handleResultIDs = async (compData) => {
    const split = await fetch(`${process.env.REACT_APP_SPLIT_RESULTS}/${compData.resultsID}`).then(res => res.json());
    const fullSplit = compData.full_resultsID !== "NaN" 
      ? await fetch(`${process.env.REACT_APP_SPLIT_FULL_RESULTS}/${compData.full_resultsID}`).then(res => res.json()) 
      : null;

    setData({
      results: JSON.parse(split.data),
      full_results: fullSplit ? JSON.parse(fullSplit.data) : null
    });
    setResponded(true);
  };

  useEffect(() => {
    if (data.results) {
      filterLineItems();
    }
  }, [selectedZone1, selectedZone2, filterTextZone1, filterTextZone2, data]); // Re-run filter on changes

  // Filter line items based on selected zones and filter text
  const filterLineItems = () => {
    if (!data.results) return;
  
    const filteredZone1Items = [];
    const filteredZone2Items = [];
  
    const zone1Set = new Set();
    const zone2Set = new Set();
  
    Object.entries(data.results).forEach(([key, item]) => {
      const zone1Name = item["Zone 1"];
      const zone2Name = item["Zone 2"];
  
      Object.entries(item["Line Items"]).forEach(([lineItemKey, lineItemValue]) => {
        const line1Key = `${zone1Name}-${lineItemValue["Line Item 1"]?.DESCRIPTION || ""}`;
        const line2Key = `${zone2Name}-${lineItemValue["Line Item 2"]?.DESCRIPTION || ""}`;
  
        // Function to check if filter text exists in line item properties
        const matchesFilterZone1 = (lineItem) => {
          return Object.values(lineItem).some(value =>
            value.toString().toLowerCase().includes(filterTextZone1.toLowerCase())
          );
        };
  
        const matchesFilterZone2 = (lineItem) => {
          return Object.values(lineItem).some(value =>
            value.toString().toLowerCase().includes(filterTextZone2.toLowerCase())
          );
        };
  
        // Add Line Item 1 to Zone 1 items if it matches the filter text for Document 1
        if (
          (selectedZone1.length === 0 || selectedZone1.includes(zone1Name)) &&
          lineItemValue["Line Item 1"] &&
          !zone1Set.has(line1Key) &&
          matchesFilterZone1(lineItemValue["Line Item 1"])
        ) {
          zone1Set.add(line1Key);
          filteredZone1Items.push({ zone: zone1Name, item: lineItemValue["Line Item 1"] });
        }
  
        // Add Line Item 2 to Zone 2 items if it matches the filter text for Document 2
        if (
          (selectedZone2.length === 0 || selectedZone2.includes(zone2Name)) &&
          lineItemValue["Line Item 2"] &&
          !zone2Set.has(line2Key) &&
          matchesFilterZone2(lineItemValue["Line Item 2"])
        ) {
          zone2Set.add(line2Key);
          filteredZone2Items.push({ zone: zone2Name, item: lineItemValue["Line Item 2"] });
        }
      });
    });
  
    setFilteredItems({ zone1: filteredZone1Items, zone2: filteredZone2Items });
  };
  

  const handleZone1Change = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(opt => opt.value) : [];
    setSelectedZone1(selectedValues);
  };

  const handleZone2Change = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(opt => opt.value) : [];
    setSelectedZone2(selectedValues);
  };

  const handleFilterTextZone1Change = (event) => {
    setFilterTextZone1(event.target.value);
  };

  const handleFilterTextZone2Change = (event) => {
    setFilterTextZone2(event.target.value);
  };

  const renderView = () => {
    const zone1Set = new Set();
    const zone2Set = new Set();
  
    if (data.results) {
      Object.entries(data.results).forEach(([key, item]) => {
        if (item["Zone 1"]) zone1Set.add(item["Zone 1"]);
        if (item["Zone 2"]) zone2Set.add(item["Zone 2"]);
      });
    }
  
    const uniqueZone1s = Array.from(zone1Set).map(zone => ({ value: zone, label: zone }));
    const uniqueZone2s = Array.from(zone2Set).map(zone => ({ value: zone, label: zone }));
  
    const truncateValue = (value) => {
      return value.length > 60 ? value.slice(0, 60) + '...' : value;
    };
  
    return (
      <div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <div>
            <label>Doc 1 Zones: </label>
            <Select
              isMulti
              value={uniqueZone1s.filter(opt => selectedZone1.includes(opt.value))}
              onChange={handleZone1Change}
              options={uniqueZone1s}
              placeholder="Select Zone"
            />
            <br/>
            <label>Filter (Doc 1): </label>
            <input
              style={filterStyle}
              type="text"
              value={filterTextZone1}
              onChange={handleFilterTextZone1Change}
              placeholder="Enter text to filter"
            />
          </div>
          <div>
            <label>Doc 2 Zones: </label>
            <Select
              isMulti
              value={uniqueZone2s.filter(opt => selectedZone2.includes(opt.value))}
              onChange={handleZone2Change}
              options={uniqueZone2s}
              placeholder="Select Zone"
            />
            <br/>
            <label>Filter (Doc 2): </label>
            <input
              style={filterStyle}
              type="text"
              value={filterTextZone2}
              onChange={handleFilterTextZone2Change}
              placeholder="Enter text to filter"
            />
          </div>
        </div>
  
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <div style={{ flex: 1, marginRight: '10px', boxSizing: 'border-box' }}>
            <h2 style={centeredHeader}>Doc 1</h2>
            {filteredItems.zone1.length > 0 ? (
              filteredItems.zone1.map((entry, index) => (
                <div key={index}>
                  <div style={tableStyle}>
                    {/* Display the first entry on top */}
                    {Object.entries(entry.item).slice(0, 1).map(([key, value]) => (
                      <div key={key} style={blockStyle}>
                        <h4 style={centeredHeader}>Zone: {entry.zone}</h4>
                        <hr/>
                        <br/>
                        <div style={thStyle}>{key}: {truncateValue(value)}</div>
                        <br/>
                      </div>
                    ))}
  
                    {/* Display the rest of the entries below */}
                    <div style={trStyle}>
                      {Object.entries(entry.item).slice(1).map(([key, value]) => (
                        <div key={key}>
                          <div style={tdPropStyle}>{key}</div>
                          <div style={tdValueStyle}>{value}</div>
                          <br/>
                        </div>
                      ))}
                    </div>
                    <FormControlLabel
                      style={{
                        fontStyle: 'italic',
                        color: '#526666',
                        padding: '2px 8px',
                        display: 'inline',
                        margin: '2px',
                      }}
                      label="Mark For Export"
                      control={<Checkbox
                        size="small"
                      />}
                    />
                  </div>
                </div>
              ))
            ) : (
              <p>No line items for Doc 1.</p>
            )}
          </div>
  
          <div style={{ flex: 1, marginLeft: '10px', boxSizing: 'border-box' }}>
            <h2 style={centeredHeader}>Doc 2</h2>
            {filteredItems.zone2.length > 0 ? (
              filteredItems.zone2.map((entry, index) => (
                <div key={index}>
                  <div style={tableStyle}>
                    {/* Display the first entry on top */}
                    {Object.entries(entry.item).slice(0, 1).map(([key, value]) => (
                      <div key={key} style={blockStyle}>
                        <h4 style={centeredHeader}>Zone: {entry.zone}</h4>
                        <hr />
                        <br/>
                        <div style={thStyle}>{key}: {truncateValue(value)}</div>
                        <br/>
                      </div>
                    ))}
                    <div style={trStyle}>
                      {/* Display the rest of the entries below */}
                      {Object.entries(entry.item).slice(1).map(([key, value]) => (
                        <div key={key}>
                          <div style={tdPropStyle}>{key}</div>
                          <div style={tdValueStyle}>{value}</div>
                          <br/>

                        </div>
                      ))}
                    </div>
                    <FormControlLabel
                      style={{
                        fontStyle: 'italic',
                        color: '#526666',
                        padding: '2px 8px',
                        display: 'inline',
                        margin: '2px',
                      }}
                      label="Mark For Export"
                      control={<Checkbox
                        size="small"
                      />}
                    />
                  </div>
                </div>
              ))
            ) : (
              <p>No line items for Doc 2.</p>
            )}
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <div className="outer-container">
      <MainWrapper />
      <div className="right-container">
        <div className="comparison-results-container">
          <h1 className="h1-comparison-results">Filter Comparisons</h1>
          <div className="comparison-results-actions-div">
            <Button 
              size="small"
              sx={{
                ':hover': {
                    backgroundColor: '#999999'
                },
              margin: '8px 8px 8px 0px', fontWeight: 'bold', color: 'white', backgroundColor: '#00B3B5'}}
              onClick={() => toggleView()}
            >
              Toggle View
            </Button>
            <Button 
              size="small"
              sx={{
                ':hover': {
                    backgroundColor: '#999999'
                },
              margin: '8px 8px 8px 0px', fontWeight: 'bold', color: 'white', backgroundColor: '#00B3B5'}}
            >
              Export PDF
            </Button>
          </div>
          <div id="pairings" className="pairings-container">
            {renderView()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilteredComparisons;
