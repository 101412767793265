import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import { putUpdateUser } from '../../actions/auth';

import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'

import backgroundImage from "../../img/LEVLR-Login-Background.jpg";
import levlr_logo from "../../img/LEVLR Logo_CMYK.png";

const UpdateForm = props => {

    const [credentials, setCredentials] = useState({})
    // const isLoggedIn = useSelector(state => state.isLoggedIn)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    localStorage.setItem('token', '');

    const handleChange = e => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value})
    }

    const updateUser = e => {
        localStorage.setItem('user', 'set');
        e.preventDefault()
        dispatch(putUpdateUser({ props, credentials }))
    }

    const routeChange = () =>{ 
        let path = `/dashboard`; 
        navigate(path);
      }

    // useEffect(() => {
    //     if (isLoggedIn) {
    //         navigate('/')
    //     }
    // },[isLoggedIn, navigate])


    const backGroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh',
        paddingTop: '20vh',
    }

    const loginPaperStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        width: '45vw',
        minWidth: '600px',
        paddingTop: '4vh',
        marginLeft: 'auto',
        marginRight: 'auto'
    }

    const loginTextFieldStyle = {
        width: '75%'
    }

    const loginFormStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: 1,
    }

    const loginButtonStyle = {
        width: '40%',
        color: "white",
        backgroundColor: "#2fb7b7",
        '&:hover': {
            backgroundColor: '#01656a',
        },
        margin: 16,
    }

    const signupButtonStyle = {
        width: '40%',
        color: "white",
        backgroundColor: "#01656a",
        '&:hover': {
            backgroundColor: '#2fb7b7',
        },
        margin: 16,
    }

    const levlrLogoStyle = {
        width: '40%',
        height: '40%'
    }

    return (
        <div className="Background-Container" style={backGroundStyle}>
            <Container component="main" maxwidth="xs">
                <CssBaseline />
                <Paper style={loginPaperStyle}>
                    <img src={levlr_logo} style={levlrLogoStyle} alt="Levlr Logo"/>
                    <form style={loginFormStyle} noValidate onSubmit={(e) => updateUser(e)}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        id="name"
                        label="Username"
                        name="name"
                        autoComplete="username"
                        autoFocus
                        onChange={handleChange}
                        style={loginTextFieldStyle}
                    />
                       <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        id="email"
                        label="email"
                        name="Current Email"
                        autoComplete={credentials.email}
                        autoFocus
                        onChange={handleChange}
                        style={loginTextFieldStyle}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        autoComplete="password"
                        onChange={handleChange}
                        style={loginTextFieldStyle}
                    />

                
                    <Button
                        type="submit"
                        variant="contained"
                        style={loginButtonStyle}
                    >
                        Sign Up
                    </Button>
                    All Done?
                    <Button
                        type="submit"
                        variant="contained"
                        style={signupButtonStyle}
                        onClick={routeChange}
                    >
                        Return to Dashboard!
                    </Button>
                    </form>

                </Paper>
            </Container>
        </div>
    )
}

export default UpdateForm