import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { useParams } from 'react-router-dom';
import uploadIcon from '../../img/upload-file.png';
import fileIcon from '../../img/file-icon.png';
import helperImg from '../../img/header-helper.svg';
import leftArrow from '../../img/left-arrow.svg';
import rightArrow from '../../img/right-arrow.svg';
import Modal from 'react-modal';
import ModalMUI from '@mui/material/Modal';
import '../../css/ActiveClaims.css';
import '../../css/AdjustmentsActiveClaims.css';
import MainWrapper from '../wrappers/mainWrapper';
const MAX_COUNT = 2;
export const comparisonState = false;
const ContinueComparison = props => {

    //ref to header columns container. used for scroll const below.
    const ref = useRef(null);   
    //use navigate later for redirect to activeClaims upon submitting comparison. 
    const navigate = useNavigate();
    const [responded, setResponded] = useState(false);
    //Set initial states for initial headers and missing value columns. Duplicate init into soft. 
    const [doc1InitHeaders, setDoc1InitHeaders] = useState([]);
    const [doc2InitHeaders, setDoc2InitHeaders] = useState([]);
    const [doc1SoftHeaders, setDoc1SoftHeaders] = useState([]);
    const [doc2SoftHeaders, setDoc2SoftHeaders] = useState([]);
    const [doc1InitMissing, setDoc1InitMissing] = useState([]);
    const [doc2InitMissing, setDoc2InitMissing] = useState([]);
    const [doc1SoftMissing, setDoc1SoftMissing] = useState([]);
    const [doc2SoftMissing, setDoc2SoftMissing] = useState([]);
    const [data, dataSet] = useState({});

    //console.log("data: " + JSON.stringify(data, null, 2));
    //console.log("doc1InitHeaders: " + doc1InitHeaders)
    //console.log("doc2InitHeaders: " + doc2InitHeaders)
    //console.log("doc1SoftHeaders: " + doc1SoftHeaders)
    //console.log("doc2SoftHeaders: " + doc2SoftHeaders)
    //console.log("doc1InitMissing: " + doc1InitMissing)
    //console.log("doc2InitMissing: " + doc2InitMissing)
    //console.log("doc1SoftMissing: " + doc1SoftMissing)
    //console.log("doc2SoftMissing: " + doc2SoftMissing)
    //console.log("doc1SoftHeaders.length: " + doc1SoftHeaders.length)

    //empty state gets populated with comparisonID created upon clicking submit comparison button. 
    const [comparisonID, setComparisonID] = useState("");
    //const continueCompID = "65d525bc3a92d0cf37e973a7"

    //request userID. 
    const userData = useSelector(state => state.user._id)

    // set header1 and header2 to default empty objects so the current component doesn't render "undefined" object.
    const [modalData, setModalData] = useState({
        header1: {
            name:'',
            count:''
        },
        header2: {
            name:'',
            count:''
        }
    })

    const { continueCompID } = useParams()
    let path = '/activeClaims'
    // state for loading spinner
    const [loadingStatus, setLoadingStatus] = useState('')

    //open state for modals. 
    const [modifyHeadersOpen1, setModifyHeadersOpen1] = useState(false)
    const [modifyHeadersOpen2, setModifyHeadersOpen2] = useState(false)
    const [confirmHeadersOpen, setConfirmHeadersOpen] = useState(false)

    //variable that will eventually be sent to the DB and FastAPI. 
    let fullAdjustedHeaders = {
        doc1headers: doc1SoftHeaders,
        doc2headers: doc2SoftHeaders, 
        missing1: doc1SoftMissing,
        missing2: doc2SoftMissing,
        isModified: checkModified(),
        compID: continueCompID
    }
        
    console.log("fullAdjustedHeaders: " + JSON.stringify(fullAdjustedHeaders, null, 2))

    const getResultsData = async () => {
        const comp = await fetch(`${process.env.REACT_APP_RESULTS_DATA}comp/${continueCompID}`, {timeout: 8000}).then((response) => response.json({}))
        if (comp.hasOwnProperty("full_resultsID") || comp.hasOwnProperty("results_ID")) {
          if(comp["full_resultsID"] === "NaN") {
            const split = await fetch(`${process.env.REACT_APP_SPLIT_RESULTS}/${comp.resultsID}`).then((response) => response.json({}))
            dataSet({
              results: JSON.parse(split.data), 
              full_results: JSON.parse(JSON.stringify(split.data)), 
              claimData: comp.claimData, 
              files: comp.files, 
              headers: comp.headers, 
              resultsID: comp.resultsID, 
              full_resultsID: comp.full_resultsID,
              dateUploaded: comp.dateUploaded,
              statusMSG: comp.statusMSG,
              status: comp.status,
              doc1_name: comp.doc1_name,
              doc2_name: comp.doc2_name,
              headersOne: comp.headersOne,
              headersTwo: comp.headersTwo,
              no_match_a: comp.no_match_a,
              no_match_b: comp.no_match_b
            })
            setResponded(true)
          }
          else if(comp["full_resultsID"] != "NaN") {
            const split = await fetch(`${process.env.REACT_APP_SPLIT_RESULTS}/${comp.resultsID}`).then((response) => response.json({}))
            const fullSplit = await fetch(`${process.env.REACT_APP_SPLIT_FULL_RESULTS}/${comp.full_resultsID}`).then((response) => response.json({}))
            dataSet({
              results: JSON.parse(split.data), 
              full_results: JSON.parse(JSON.stringify(fullSplit.data)), 
              claimData: comp.claimData, 
              files: comp.files, 
              headers: comp.headers, 
              resultsID: comp.resultsID, 
              full_resultsID: comp.full_resultsID,
              dateUploaded: comp.dateUploaded,
              statusMSG: comp.statusMSG,
              status: comp.status,
              doc1_name: comp.doc1_name,
              doc2_name: comp.doc2_name,
              headersOne: comp.headersOne,
              headersTwo: comp.headersTwo,
              no_match_a: comp.no_match_a,
              no_match_b: comp.no_match_b
            })
            setResponded(true)
          }
        }
        else {
          dataSet(comp)
          setResponded(true)
        }
      }    

    useEffect(() => {
        
        if (responded === false) {
            getResultsData()
        }
      checkModified()
      renderForm1()
      if(doc1SoftHeaders.length === 0 && responded){
        doc1SoftHeaders.length = parseInt(data.headers[0].count)
        doc1SoftHeaders.fill("Undetected")
        doc1SoftHeaders.fill(data.headers[0].name, 0, 1)
        setDoc1InitMissing(new Array(parseInt(data.headers[0].count)).fill(false))
        setDoc1SoftMissing(new Array(parseInt(data.headers[0].count)).fill(false))
      }
      if(doc2SoftHeaders.length === 0 && responded){
        doc2SoftHeaders.length = parseInt(data.headers[1].count)
        doc2SoftHeaders.fill("Undetected")
        doc2SoftHeaders.fill(data.headers[1].name, 0, 1)
        setDoc2InitMissing(new Array(parseInt(data.headers[1].count)).fill(false))
        setDoc2SoftMissing(new Array(parseInt(data.headers[1].count)).fill(false))
      }
    }, [ responded])

    //Return true if all input values are greater than 0. Still does not validate proper input types.
    function checkHeaderEmptyStrings() {
        if(modalData.header1.name.length > 0 && modalData.header2.name.length > 0 && modalData.header1.count.length > 0 && modalData.header2.count.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    //Return a string or page break for the 3rd Modal in the missing values column.
    function returnMissingString (x) {
        if(x) {
            return "Missing Data"
        }
        else {
            return <br />
        }
    }

    //determine whether or not the headers have been changed in the 1st or 2nd modal. 
    function checkModified() {
        if ((JSON.stringify(doc1InitHeaders) === JSON.stringify(doc1SoftHeaders)) && (JSON.stringify(doc2InitHeaders) === JSON.stringify(doc2SoftHeaders))) {
            return false
        }
        else {
            return true
        }
    }

    //verify that response does not return an undefined array.
    function verifyResponse(x) {
        console.log("x: " + x)
        if (x) {
            return x
        }
        else {
            console.log("else")
            return ""
        }
    }



    //COMPLETE FORM SUBMISSION FROM MODALDATA STATE
    const newComparison = e => {
        e.preventDefault()
        setLoadingStatus('loading-spinner');
        //const fullModalData = modalData
        //let path = `/activeClaims`;
        //navigate(path);
        //console.log("before post")
        //console.log("newComparisonClaimData: " + JSON.stringify(claimData, null,2))
        //console.log("modalData:" + JSON.stringify(modalData,null,2))
        //alert("Your comparison is nearly done. Please continue to verify header information.");
        setLoadingStatus('');
        //add if logic for 001 error. set new modal for manual input. Piggy back off the existing Modal. Set new variables/useStates for this?
        //setComparisonID(verifyResponse(response.data.data[0].comparisonID))
        setDoc1InitHeaders(data.headersOne)
        setDoc2InitHeaders(data.headersTwo)
        setDoc1SoftHeaders(data.headersOne)
        setDoc2SoftHeaders(data.headersTwo)
        setDoc1InitMissing(new Array(((data.headersOne)).length).fill(false))
        setDoc2InitMissing(new Array(((data.headersTwo)).length).fill(false))
        setDoc1SoftMissing(new Array(((data.headersOne)).length).fill(false))
        setDoc2SoftMissing(new Array(((data.headersTwo)).length).fill(false))
        
        setModifyHeadersOpen1(true)
        setResponded(true)


    }

    const continueComparison = e => {


        axios.post(`${process.env.REACT_APP_CONTINUE_COMPARISON}`, fullAdjustedHeaders)
        .then(response => {   
            setConfirmHeadersOpen(false)
            navigate(path);
         })  
         .catch(error => {
            //alert("There was an error submitting your changes. Please Try Again.")
            console.error('There was an error!', error);
            navigate(path);
        }); 
        axios.post(`${process.env.REACT_APP_SAVE_ADJUST_HEADERS}`, fullAdjustedHeaders)
        .then(response => {   
          //alert("Thank you for confirming your headers!");
          navigate(path);
        })
        .catch(error => {
          //alert("Saving was unsuccessful. Please try again.");
          console.error('There was an error!', error);
          navigate(path);
        });
    }

{/* Methods and Functions below pertain to the modals that pop up after header detection. */}
    {/* logic to scroll element overflow in the 1st and 2nd modals. */}
    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    };

    {/* handle input from text fields in Modal1. */}
    const handleHeaderChange1 = (index, e) => {
        const updateVal = doc1SoftHeaders.map((c, i) => {
            if (i === index) {
              // Return the current header
              if(e.target.value === "") {
                return doc1InitHeaders[index]
              }
              else {
                return (e.target.value).trim()
              }
            } else {
              // The rest haven't changed
              return c;
            }
          });
        setDoc1SoftHeaders(updateVal)
    }


    {/* handle input from text fields in Modal2. */}
    const handleHeaderChange2 = (index, e) => {
        const updateVal = doc2SoftHeaders.map((c, i) => {
            if (i === index) {
              // Return the current header
              if(e.target.value === "") {
                return doc2InitHeaders[index]
              }
              else {
                return (e.target.value).trim()
              }
            } else {
              // The rest haven't changed
              return c;
            }
          });
        setDoc2SoftHeaders(updateVal)
    }


    {/* handle yes input field in Modal1. */}
    const handleMissingVal1 = (index, e) => {
        const updateVal = doc1SoftMissing.map((c, i) => {
            if (i === index) {
              // Return the current val
              return e.target.checked;
            } else {
              // The rest haven't changed
              return c;
            }
          })
        setDoc1SoftMissing(updateVal)
    }

    {/* handle yes input field in Modal2. */}
    const handleMissingVal2 = (index, e) => {
        const updateVal = doc2SoftMissing.map((c, i) => {
            if (i === index) {
              // Return the current val
              return e.target.checked;
            } else {
              // The rest haven't changed
              return c;
            }
          });
        setDoc2SoftMissing(updateVal)
    }

    {/* Reset to initial values for document 1. */}
    function handleReset1() {
        setDoc1SoftHeaders(doc1InitHeaders)
        setDoc1SoftMissing(doc1InitMissing)
    }

    {/* Reset to initial values for document 2 */}
    function handleReset2() {
        setDoc2SoftHeaders(doc2InitHeaders)
        setDoc2SoftMissing(doc2InitMissing)
    }

    //UI flow. Open and close appropriate modals
    function handleSubmitDoc1Button() {
        setModifyHeadersOpen1(false)
        setModifyHeadersOpen2(true)
    }

    //UI flow. Open and close appropriate modals
    function handleSubmitDoc2Button() {
 
        setModifyHeadersOpen2(false)
        setConfirmHeadersOpen(true)
    }

    //UI flow. Open and close appropriate modals
    function handleReviewDocsButton () {
        setModifyHeadersOpen1(true)
        setConfirmHeadersOpen(false)
    }

    //Entire view for this component. 
    function renderForm1() {
        return (
            <div className={"create-new-claim-container"}>
                            
                <h1 className={'h1-create-new-claim'}>Continue Comparison</h1>

                <form style={{width: '100%', display: 'flex'}} className={'buttons-container'} onSubmit={(e) => newComparison(e)}>

                    <div style={{display: 'flex', margin: '48px 0px 0px -12px', justifyContent: 'center'}}>
                        <button className="create-new-comparison-button" type="submit">Continue Comparison</button>    
                    </div>

                </form>
                
                {/* User Adjust Headers */}
                <div>
                    <Modal
                    style={modalRestyle}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                    isOpen={modifyHeadersOpen1}
                    onRequestClose={() => setModifyHeadersOpen1(false)}
                    >
                        {/* Container for entire Modal */}
                        <form style={modBackground}>
                            
                            {/* Container for header and close button */}
                            <div style={topDiv}>
                                <div>
                                    <h1 style={checkWork}>CHECK MY WORK</h1>
                                    <h2 style={docNumber}>Document #1</h2>
                                </div>
                                {/*<img src={xButton} style={xButtonResize} alt="x button" onClick={() => (setModifyHeadersOpen1(false) && alert("You will need to restart the comparison if you continue"))}/>*/}
                            </div>

                            <div style={flexContainer}>

                                <div style={modGuts}>
                            
                                    {/* Container for first column (question and Headers label). */}
                                    <div style={leftDiv}>
                                        <br />
                                        <span style={headersLabel}>Detected Headers:</span>
                                        <span style={yourChangeLabel}>Your Change:</span>

                                        <div style={questionGrid}>
                                            <span style={numeric}>1</span>
                                            <span style={modQuestion}>Update columns, if necessary</span>
                                        </div>

                                        <div style={questionGrid}>
                                            <span style={numeric}>2</span>
                                            <span style={modQuestion}>Does column have missing data?</span>
                                        </div>
                                        <br />
                                    </div>
                                </div>

                                <div style={arrowDiv} onClick={() => scroll(-100)}>
                                    <img style={arrowStyle}  src={leftArrow} alt="left arrow" />
                                </div>

                                <div className="columns" style={modGuts} ref={ref}>
                                    
                                    {doc1SoftHeaders.map((header, index) =>
                                        <div className="unique-column" id={index}>

                                            {/* Container for middle columns (yes checkbox, default header found, and update/delete buttons). */}
                                            {/* Loop through state to make multiple columns. */}
                                            <span style={columnNumber}>{index + 1}</span>
                                            <span style={detectedHeaders}>{doc1InitHeaders[index]}</span>
                                            <span style={modifyHeaders}>{header}</span>

                                            <input id={index} onChange={(e) => handleHeaderChange1(index, e)} style={headerFill} type="text"/>
                                            <div style={yesDiv}>
                                                <input key={Math.random()} checked={doc1SoftMissing[index]} id={header} onChange={(e) => handleMissingVal1(index, e)} name="yesBox" style={checkBox} type="checkbox" /> 
                                                <label style={helperLabel} htmlFor="yesBox">Yes</label>  
                                            </div>

                                        </div>
                                    )} 
                                </div>  
                            
                                <div style={arrowDiv} onClick={() => scroll(100)}>
                                    <img style={arrowStyle} src={rightArrow} alt="right arrow" />
                                </div>                              

                                <div style={modGuts}>

                                    {/* Container for last column (example/helper image). */}
                                    <div style={rightDiv}>
                                        <span style={helperLabelHead}>EXAMPLE: </span>
                                        <img style={helperImgResize} src={helperImg} alt="helper img"/>                                
                                        <span style={helperLabelFoot}>Columns <strong style={{color: '#00B3B5'}}>3 & 5 </strong> have missing data</span>
                                    </div>

                                </div>
                            
                            </div>
                            <input type="reset" value="RESET DOCUMENT #1" onClick={() => handleReset1()} style={resetButton}/>

                            <button type="button" onClick={() => handleSubmitDoc1Button()} style={modSubmitButton}>CONFIRM DOCUMENT #1</button>

                        </form>
                    </Modal>
                </div>

                <div>
                    <Modal                            
                    style={modalRestyle}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                    isOpen={modifyHeadersOpen2}
                    onRequestClose={() => setModifyHeadersOpen2(false)}
                    >
                        {/* Container for entire Modal */}
                        <form style={modBackground}>
                            
                            {/* Container for header and close button */}
                            <div style={topDiv}>
                                <div>
                                    <h1 style={checkWork}>CHECK MY WORK</h1>
                                    <h2 style={docNumber}>Document #2</h2>
                                </div>
                                {/*<img src={xButton} style={xButtonResize} alt="x button" onClick={() => (setModifyHeadersOpen2(false) && alert("You will need to restart the comparison if you continue"))}/>*/}
                            </div>

                            <div style={flexContainer}>

                                <div style={modGuts}>
                            
                                    {/* Container for first column (question and Headers label). */}
                                    <div style={leftDiv}>
                                        <br />
                                        <span style={headersLabel}>Detected Headers:</span>
                                        <span style={yourChangeLabel}>Your Change:</span>

                                        <div style={questionGrid}>
                                            <span style={numeric}>1</span>
                                            <span style={modQuestion}>Update columns, if necessary</span>
                                        </div>

                                        <div style={questionGrid}>
                                            <span style={numeric}>2</span>
                                            <span style={modQuestion}>Does column have missing data?</span>
                                        </div>

                                        <br />
                                    </div>
                                </div>

                                <div style={arrowDiv} onClick={() => scroll(-100)}>
                                    <img style={arrowStyle}  src={leftArrow} alt="left arrow" />
                                </div>

                                <div className="columns" style={modGuts} ref={ref}>

                                    {doc2SoftHeaders.map((header, index) =>

                                    <div className="unique-column" id={index}>
                                        {/* Container for middle columns (yes checkbox, default header found, and update/delete buttons). */}
                                        {/* Loop through state to make multiple columns. */}
                                        
                                            <span style={columnNumber}>{index + 1}</span>
                                            <span style={detectedHeaders}>{doc2InitHeaders[index]}</span>
                                            <span style={modifyHeaders}>{header}</span>

                                            <input id={index} onChange={(e) => handleHeaderChange2(index, e)} style={headerFill} type="text"/>
                                            <div style={yesDiv}>
                                                <input key={Math.random()} checked={doc2SoftMissing[index]} id={header} onChange={(e) => handleMissingVal2(index, e)} name="yesBox" style={checkBox} type="checkbox" /> 
                                                <label style={helperLabel} htmlFor="yesBox">Yes</label>  
                                            </div>

                                        </div>
                                
                                    )} 
                                </div>  

                                <div style={arrowDiv} onClick={() => scroll(100)}>
                                    <img style={arrowStyle} src={rightArrow} alt="right arrow" />
                                </div>                              

                                <div style={modGuts}>

                                    {/* Container for last column (example/helper image). */}
                                    <div style={rightDiv}>
                                        <span style={helperLabelHead}>EXAMPLE: </span>
                                        <img style={helperImgResize} src={helperImg} alt="helper img"/>                                
                                        <span style={helperLabelFoot}>Columns <strong style={{color: '#00B3B5'}}>3 & 5 </strong> have missing data</span>
                                    </div>

                                </div>
                            
                            </div>
                            <input type="reset" value="RESET DOCUMENT #2" onClick={() => handleReset2()} style={resetButton}/>

                            <button type="button" onClick={() => handleSubmitDoc2Button()} style={modSubmitButton}>CONFIRM DOCUMENT #2</button>

                        </form>
                    </Modal>
                </div>

                <div>
                    <Modal
                    style={modalRestyle}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                    isOpen={confirmHeadersOpen}
                    onRequestClose={() => setConfirmHeadersOpen(false)}
                    >
                        <div style={modBackground}>
                            
                            {/* Container for header and close button */}
                            <div style={topDiv}>
                                <div>
                                    <h1 style={checkWork}>CONFIRM MY WORK</h1>
                                    <i><h2 style={docNumber}>Check and ensure that all column names are spelled according to the document</h2></i>
                                </div> 
                            </div>

                            <div style={flexConfirm}>
                                <div style={stackDocs}>

                                <h2 style={confirmDocNumber}>Document #1</h2>

                                    <div className="columns-div-confirm" style={modGuts}>

                                        {/* Loop through headers array. */}
                                        
                                        {doc1SoftHeaders.map((header, index) =>

                                        <div className="confirm-column" id={index}>
                                            <span style={columnNumber}>{index + 1}</span>
                                            <br />
                                            <span style={modifyHeaders}>{header}</span>
                                            <span style={missingData}>{returnMissingString(doc1SoftMissing[index])}</span>
                                        </div>
                                        )} 
                                        
                                    </div>      

                                <h2 style={confirmDocNumber}>Document #2</h2> 
                                    
                                    <div className="columns-div-confirm" style={modGuts}>

                                        {/* Loop through headers array. */}
                                        {doc2SoftHeaders.map((header, index) =>

                                        <div className="confirm-column" id={index}>

                                            <span style={columnNumber}>{index + 1}</span>
                                            <br />
                                            <span style={modifyHeaders}>{header}</span>
                                            <span style={missingData}>{returnMissingString(doc2SoftMissing[index])}</span>

                                        </div>
                                        )} 

                                    </div>                       

                                </div>
                            </div>

                            <button onClick={continueComparison} style={modSubmitButton}>CONFIRM DOCUMENTS</button>
                            <button onClick={() => handleReviewDocsButton()} style={resetButton}>BACK TO REVIEW</button>           
                        </div>
                        
                    </Modal>
                </div>
            </div>
        )
    }

    //STYLES
    const modalRestyle = {
        content: {
            background: 'none',
            border: 'none',
            inset: '0px',
            backgroundColor: 'rgb(25, 25, 25, 0.5)',
            padding: '0px',
          },
    }
    const modBackground = {
        backgroundColor: '#DDDEDD',
        position: 'absolute',
        padding: '24px',
        top: '10%',
        left: '10%',
        height: 'auto',
        width: '80%',
        borderRadius: '18px',
        border: '1px solid black'
    }
    const checkWork = {
        display: 'inline',
        color: '#555F55',
        fontWeigth: '900',
        fontSize: '24px',
        margin: '0px 16px 10px 16px'
    }
    const docNumber = {
        display: 'inline',
        color: '#00B3B5',
        fontSize: '18px',      
    }
    const missingData = {
        color: '#00B3B5',
        fontStyle: 'italic'
    }
    const confirmDocNumber = {
        margin: '24px 0px',
        display: 'block',
        textAlign: 'left',
        color: '#00B3B5',
        fontSize: '18px',
    }

    const questionGrid = {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '20% 80%',
        padding: '0px 4px 0px 4px',
        justifySelf: 'center',
        alignItems: 'center'
    }
    const numeric = {
        fontSize: '48px',
        fontWeight: 'bold',
        color: '#999999'
    }
    const arrowStyle = {
        height: '50%',
        width: '100%',
    }
    const arrowDiv = {
        padding: '0px 0px 0px 8px',
        display: 'flex',
        width: '7%',
        height: 'auto',
        alignItems: 'center',
        margin: '0px 0px',   
    }
    const topDiv = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'middle',
        marginBottom: '24px'
    }
    const flexContainer = {
        position: 'relative',
        top: '8%',
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '12px 0',
        marginBottom: '60px',
        borderBottom: '1px solid #00B3B5'
    }
    const flexConfirm = {
        position: 'relative',
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between'
    }
    const stackDocs = {
        position: 'relative',
        top: '8%',
        width: '100%',
        height: 'auto',
        display: 'block'
    }
    const leftDiv = {
        display: 'grid',
        gridTemplateRows: '10% 10% 10% 20% 20%',
        fontSize: '16px',
        height: '90%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: '12px',
        margin: '0px 0px 4px 12px'
    }
    const modGuts = {
        display: 'flex'
    }
    const rightDiv = {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        margin: '0px 0px 0px 0px',
        textAlign: 'center'
    }
    const columnNumber = {
        color: '#00B3B5',
        justifySelf: 'center'
    }
    const modifyHeaders = {
        padding: '4px 4px',
        color: '#555F55',
        fontWeight: '500',
        justifySelf: 'center',
        alignSelf: 'center',
        whiteSpace: 'nowrap'
    }
    const detectedHeaders = {
        padding: '4px 4px',
        color: '#00B3B5',
        fontWeight: '500',
        justifySelf: 'center',
        textAlign: 'right',
        alignSelf: 'center'
    }
    const modQuestion = {
        padding: '0px 0px 0px 12px',
        color: '#444444',
        fontSize: '12px',
        textAlign: 'left'
    }
    const helperLabel = {
        display: 'block',
        color: '#444444',
        fontSize: '18px',
        padding: '0px 24px 0px 24px',
        textAlign: 'center',
    }
    const helperLabelHead = {
        display: 'block',
        color: '#00B3B5',
        fontWeight: 'bold',
        marginBottom: '4px',
        fontSize: '18px',
        padding: '0px 24px 0px 24px',
        textAlign: 'center',
        width: '100%'
    }
    const helperLabelFoot = {
        color: '#555555',
        display: 'block',
        margin: '12px auto',
        fontSize: '18px',
        padding: '0px 24px 0px 24px',
        textAlign: 'left',
        width: '50%'
    }
    const yesDiv = {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignSelf: 'center'
    }
    const helperImgResize = {
        display: 'block',
        padding: '0px 24px 0px 24px',
        margin: '0px 24px 0px 24px',
        width: '100%',
        height: 'auto'
    }
    const checkBox = {
        border: '1px solid #00B3B5 !important',
        borderRadius: '8px',
        height: '22px',
        width: '22px',

    }
    const headersLabel = {
        color: '#00B3B5',
        padding: '4px 4px',
        fontWeight: '500',
        fontSize: '16px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
    }
    const yourChangeLabel = {
        color: '#555F55',
        padding: '4px 4px',
        fontWeight: '600',
        fontSize: '16px',
        textAlign: 'right',
        whiteSpace: 'nowrap'
    }
    const headerFill = {
        border: '1px solid #00B3B5',
        borderRadius: '4px',
        maxWidth: '150px',
        height: '30px',
        fontSize: '1.0vw',
        textAlign: 'center',
        justifySelf: 'center',
        margin: '18px 0px' 
    }
    const modSubmitButton = {
        position: 'absolute',
        bottom: '4%',
        right: '24px',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        backgroundColor: '#00B3B5',
        color: 'white',
        fontSize: '16px',
        border: 'none',
        width: 'auto',
        height: '40px',
        padding: '4px 24px',
        margin: '4px 0px 4px 0px'
    }
    const resetButton = {
        position: 'absolute',
        bottom: '4%',
        left: '24px',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        backgroundColor: '#555555',
        color: 'white',
        fontSize: '16px',
        border: 'none',
        width: 'auto',
        height: '40px',
        padding: '4px 24px',
        margin: '4px 0px 4px 0px',
        ':hover': {
            opacity: '50%'
        },
    }
    return (
        <div className={'outer-container'}>
            <MainWrapper/>
            <div className={loadingStatus}></div>

            <div className={'right-container'}>
                {renderForm1()}        
            </div>                
        </div>
    )
}
export default ContinueComparison