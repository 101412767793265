import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../css/Accounts.css';

import MainWrapper from '../wrappers/mainWrapper';
import userPic from '../../img/file-icon.png';
import AdminDashboard from './adminDashboard';

const AdminPanel = props => {
    const userData = useSelector(state => state.user)
    function subscriptionStyle () {

        if (subscriptionVal < subscriptionLimit) {
            return 'green-large';
        }

        else {
            return 'black-large';
        }

    }

    const [subscriptionVal, setSubscriptionVal] = useState(9);
    const [subscriptionLimit, setSubscriptionLimit] = useState(10);

    return (

        <div className={'outer-container'}>
            <MainWrapper/>

            <div className={'right-container'}>
                <AdminDashboard/>
            </div>
            
                    
        </div>
    )
}
export default AdminPanel;