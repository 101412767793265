import { findByLabelText } from '@testing-library/react';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import levlrLogo from "../../img/LEVLR Logo_CMYK.png";
import termsOfUse from "../../img/TermsOfUse.pdf"
import privacyPolicy from "../../img/PrivacyPolicy.pdf"

import LogoutButton from '../logout/logout.jsx';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import Mailgun from 'mailgun.js';
import FormData from 'form-data';


const MainPanel = props => {
    const userData = useSelector(state => state.user)
    const userName = useSelector(state => state.user.name)
    const userEmail = useSelector(state => state.user.email)
    const userActive = useSelector(state => state.user.isActive)

    const imgResize = {
        width: '70%',
        height: 'auto'
    }

    const formStyle = {
        position: 'absolute',
        display: 'grid',
        top: '50%',
        left: '50%',
        backgroundColor: 'white',
        opacity: '95%',
        width: '60%',
        height: '50%',
        padding: '2% 2%',
        borderRadius: '15px',
        transform: 'translate(-50%, -50%)'
    }

    const h1Style = {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#555555',
        marginBottom: '8px'
    }

    const questionaireStyle = {
        display: 'grid',
        gridTemplateRows: '10% 10% 10% 10% 30% auto',
        height: 'auto'
    }

    const individualStyle = {
        padding: '12px 0px 12px 0px',
        alignItems: 'middle'
    }

    const blockStyle = {
        display: 'block',
        padding: '12px 0px 12px 0px',
        height: 'auto'
    }

    const spanStyle = {
        color: '#555555',
        fontSize: '1.0em'
    }

    const inputStyle = {
        float: 'right',
        margin: '4px 0px 0px 12px',
        border: '2px solid #00B3B5',
        height: 'auto',
        width: 'auto',
        resize: 'none',
        borderRadius: '4px'
    }

    const submitStyle = {
        backgroundColor: '#00B3B5',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        padding: '12px 14px',
        margin: '4% 0',
        float: 'right',
        fontWeight: 'bold',
        fontSize: '16px'
    }

    const flexBox = {
        display: 'flex',
        justifyContent: 'center',

        overflow: 'scroll'
    }

    const buttonContainer = {
        width: '100%',
        heith: 'auto'
    }

    const feedbackButton = {
        width: '120px',
        marginLeft: '24px',
        marginRight: '24px',
        backgroundColor: '#00B3B5',
        borderRadius: '0px',
        height: '36px',
        padding: '4px 24px'
    }

    const termsButton = {
        display: 'flex',
        width: 'auto',
        background: 'none',
        borderRadius: '0px',
        height: 'auto',
        padding: '4px 24px',
        alignItems: 'center',
        color: '#555555',
        textDecoration: 'none'
    }
    
    const xButton = {
        position: 'absolute',
        right: '3%',
        top: '3%',
        background: 'none',
        textAlign: 'right',
        width: 'fit-content',
        float: 'right',
        justifyContent: 'flex-end',
        alignSelf: 'end',
        fontSize: '24px',
        border: 'none'

    }
    const mailgun = new Mailgun(FormData);
    const mg = mailgun.client({username: 'Jeffrey Diem', key: process.env.MAILGUN_API_KEY || '57d0666e33636e4c3b02205878d3dc2b-1b3a03f6-45256524'});

    //open/close state
    const [open, setOpen] = useState(false);

    //object declaration and states
    const [question1, setQuestion1] = useState(
        0
    );

    const [question2, setQuestion2] = useState(
        ''
    );

    const [question3, setQuestion3] = useState(
        ''
    );

    const [question4, setQuestion4] = useState(
        ''
    );

    const [feedbackData, setFeedbackData] = useState({
        date_created: '',
        rating: 0, 
        helpful: '', 
        recommend: '', 
        comments: '', 
        user_name: '',
        user_id: ''
    });

    //Handlers
    const handlequestion1 = e => {
        setQuestion1(
            e.target.value
        )
    }

    const handlequestion2 = e => {
        setQuestion2(
            e.target.value
        )
    }

    const handlequestion3 = e => {
        setQuestion3(
            e.target.value
        )
    }

    const handlequestion4 = e => {
        setQuestion4(
            e.target.value
        )
    }

    //use selector



    function renderFeedbackButton() {
        if (userActive === false) {
            return (
                <div></div>
            )
        }
        if (userActive === true) {
            return (
                <Button 
                style={feedbackButton}
                variant="contained"
                onClick={() => setOpen(true)}
                >
                    Feedback
                </Button> 
            ) 
        }
    }    
    
    //axios post request for new feedback object.
    const newFeedback = e => {
        e.preventDefault();
        console.log("Step 0");
        setFeedbackData({
            date_created: Date().toLocaleString(),
            rating: question1, 
            helpful: question2, 
            recommend: question3, 
            comments: question4, 
            user_name: userName,
            user_id: userData._id
        });

        console.log("Step 1");
        console.log("Step 2");
      
//Excluding this axios post because an Internal Server error is received. 
//Most likely a typo I am missing in the object's property. 
{/*
        axios.post(`${process.env.REACT_APP_FEEDBACK}`, feedbackData)
            .then(response => {   
                console.log("Step 3")
                console.log("feedback status: " + response.status)
                if (response.status === 201) {
                    console.log("Step 4")
                    alert("Thank you for submitting your feedback!");
                    console.log("Feedback accepted.");
                }
            })
            .catch(error => {
                console.log('There was an error!', error)
                alert("There was an error processing your feedback. Please try again.")
                console.log("Step 5")
            });
*/}
            mg.messages.create('mg.mylevlr.com', {
                //to: ["jeffdiemlevlr@gmail.com"],
                to: ["jdiem@getlevlr.com", "dwk2007@gmail.com"],
                bcc: ["webmaster@pdgcreative.com", "andrew@pdgcreative.com"],
                from: "feedback@mg.mylevlr.com",
                subject: "Feedback",
                text: "Testing Mailgun",
                html: "<h1>Feedback Details</h1>" +
                        "<p>" + "date: " + Date().toLocaleString() + "</p>" + 
                        "<p>" + "rating: " + question1 + "</p>" +
                        "<p>" + "helpful: " + question2 + "</p>" +
                        "<p>" + "recommend: " + question3 + "</p>" + 
                        "<p>" + "comments: " + question4 + "</p>" + 
                        "<p>" + "user id: " + userData._id + "</p>" + 
                        "<p>" + "user name: " + userName + "</p>" +
                        "<p>" + "user email: " + userEmail + "</p>" 

            })
            .then(msg => alert("Thank you for submitting your feedback!"))
            .catch(err => alert("There was an error submitting your feedback." + err))    
            setOpen(false);
    }

    //console.log testing
        //console.log("question1: " + question1);
        //console.log("question2: " + question2);
        //console.log("question3: " + question3);
        //console.log("question4: " + question4);
        //console.log("user_id: " + userData);
        //console.log("userName: " + userName);
        //console.log("feedbackData1: " + feedbackData.rating);
        //console.log("feedbackData2: " + feedbackData.helpful);
        //console.log("feedbackData3: " + feedbackData.recommend);
        //console.log("feedbackData4: " + feedbackData.comments);
        //console.log("feedbackID: " + feedbackData.user_id);
        //console.log("feedbackName: " + feedbackData.user_name);
        //console.log("feedbackDate: " + feedbackData.date_created);

    //Styles
    

    return (

        <div className={'header-container'}>

            <div className={'header-start'}>

                <div className={'empty-div'}>
                </div>

                <NavLink to="/accounts" className={'user-container'}>
                    
                    <div className={'profile-info-container'}>
                        <strong className={'strong-style'}>{userData.name}</strong>
                        <p className={'p-style'}>{userData.email}</p>
                    </div>
                </NavLink>
                {/* User Details */}

            </div>

            <div className={'header-middle'}>
                <img src={levlrLogo} className={'levlr-logo-resize'}></img>
            </div>

            <div className={'header-end'}>
                <a 
                    style={termsButton}
                    href={termsOfUse}
                    target="_blank"
                >
                        Terms of Use 
                </a> 
                <a 
                    style={termsButton}
                    href={privacyPolicy}
                    target="_blank"                
                >
                        Privacy Policy
                </a> 
            

            {renderFeedbackButton()}
            <LogoutButton />

            <Modal 
            open={open}
            >
                <form onSubmit = {(e) => newFeedback(e)} style={formStyle}>
                    <button style={xButton} onClick={() => setOpen(false)}>X</button>

                    <div style = {flexBox}>

                        <div>
                            <h1 style={h1Style}>Please complete the following questionaire.</h1>

                            <div style = {questionaireStyle}>
                                
                                <br />
                            
                                <div style = {individualStyle}> 
                                    <span style = {spanStyle}>How would you rate the overall ease of use of Levlr (1-5 where 5 is best)?</span>
                                    <input style={inputStyle} type="number" id="rating" name="rating" min="1" max="5" onChange={handlequestion1}/>
                                </div>

                                <div style = {individualStyle}> 
                                    <span style = {spanStyle}>Did Levlr provide actionable data that you found helpful?</span> 
                                    <select style={inputStyle} id="helpful" name="helpful" onChange={handlequestion2}>
                                        <option value=""></option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>             
                                </div>

                                <div style = {individualStyle}> 
                                    <span style = {spanStyle}>Would you recommend Levlr to a colleague?</span>
                                    <select style={inputStyle} id="recommend" name="recommend" onChange={handlequestion3}>
                                        <option placeholder={question3} value=""></option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>

                                <div style = {blockStyle}> 
                                    <span style = {spanStyle}>Please let us know any additional feedback.</span>
                                    <textarea style={inputStyle} rows="5" cols="50" id="comments" name="comments" onChange={handlequestion4}/>                         
                                </div>

                                <div style={buttonContainer}>
                                    <button style={submitStyle} type="submit">Submit Feedback</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </Modal>
            </div>

        </div>       
    )

}

export default MainPanel