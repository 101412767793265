// StripeContext.js
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_live_51LPAanFS589M22btAFq4jbiwAAcCCZG9objVz9jHPbpCfHLHrccjqBADaH8z6z4aLBAE9l5sXoCJacKP7TwSJadc00GBaAkIyz');

const StripeContext = ({ children }) => {
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export default StripeContext;
