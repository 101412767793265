import React from "react";
import { useState, useEffect } from "react";
import { postAddHeader } from "../../actions/claims";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import exampleDocument from '../../img/exampleEstimate.svg';
import { display, flexbox } from "@mui/system";


const ComparisonHeader = ({setFormState, id, actions, setModalData, modalData, setOpen}) => {
    
    
    const [header1, setHeader1] = useState({
        name:'',
        count: ''
    })

    const [header2, setHeader2] = useState({
        name:'',
        count: ''
    })





    const handleHeader1 = e => {
        
        setHeader1({
            ...header1,
            [e.target.name]: e.target.value
        })
    }

    const handleHeader2 = e => {
        
        setHeader2({
            ...header2,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = e => {
        e.preventDefault()
        setOpen(false)
        setModalData({ ...modalData, header1, header2})
        // history.push('/estimates')
        // dispatch(postAddHeader(headers))
        // console.log(headers)
        setFormState({
            enderDocForm: true,
            renderHeaderForm: false, // not in effect?
        })
        /* test */ console.log('When?')
        // stackoverflow.com/a/47649489
        // this.setState({ showModal: false })
    }

    //Styles

    const formStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        backgroundColor: 'white',
        opacity: '95%',
        width: '60%',
        height: 'auto',
        padding: '36px',
        borderRadius: '15px',
        transform: 'translate(-50%, -50%)'
    }

    const h1Style = {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#555555',
        marginBottom: '8px'
    }

    const xStyle = {
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: '24px',
        top: '4%',
        right: '4%',
        cursor: 'pointer'
    }

    const questionaireStyle = {
        display: 'inline'
    }

    const headerStyle = {
        color: '#00B3B5'
    }
    const divider = {
        display: 'block',
        backgroundColor: '#00B3B5',
        width: '100%',
        height: '2px'
    }
    const individualStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        height: '40px',
        padding: '12px 0px 0px 0px',
        alignItems: 'center'
    }

    const labelContainer = {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        padding: '5% 0px 5% 0px'
    }

    const spanStyle = {
        padding: '6px',
        color: '#555555'
    }

    const inputStyle = {
        float: 'right',
        margin: '0px 0px 0px 12px',
        border: '2px solid #00B3B5',
        boxSizing: 'border-box',
        padding: '6px',
        borderRadius: '4px'
    }

    const submitStyle = {
        backgroundColor: '#00B3B5',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        alignItems: 'center',
        padding: '12px 14px',
        float: 'right',
        marginTop: '20px',
        fontWeight: 'bolder',
        fontSize: '16px'
    }

    const flexBox = {
        display: 'flex'
    }

    const exampleDocumentStyle ={
        width: '20%',
        margin: 'auto',
        textAlign: 'center'
    }

    const exampleText = {
        fontSize: '15px'
    }

    const caseLabel = {
        fontStyle: 'italic',
        fontSize: '1.0em',
        float: 'left',
        padding: '5% 0px 5% 0px'
    }

        return (
            <form style={formStyle}>
                <div style = {flexBox}>
                <div>

                    <h1 style={h1Style}>Please Submit Additional Info</h1>
                    <a onClick={()=>setOpen(false)} style={xStyle}>X</a>

                    <div style = {questionaireStyle}>
                        
                        <br />
                        <div>
                            <h3 style = {headerStyle}>Document #1</h3>
                        </div>

                        <div style = {individualStyle}> 
                            <span style = {spanStyle}>Number of column headers:</span>
                            <input style={inputStyle} type="number" name="count" onChange={handleHeader1}></input>
                        </div>

                        <div style = {individualStyle}> 
                            <span style = {spanStyle}>Header name in first column:</span> 
                            <input placeholder="case sensitive" style={inputStyle} type="text" id="count1" name="name" onChange= {handleHeader1} />                     
                        </div>

                        <br />
                        <div style = {divider}></div>

                        {/* Document Two */}
                        <br />
                        <div>
                            <h3 style = {headerStyle}>Document #2</h3>
                        </div>

                        <div style = {individualStyle}> 
                            <span style = {spanStyle}>Number of column headers:</span>
                            <input style={inputStyle} type="number" name="count" onChange={handleHeader2}></input>
                        </div>

                        <div style = {individualStyle}> 
                            <span style = {spanStyle}>Header name in first column:</span>
                            <input placeholder="case sensitive" style={inputStyle} type="text" name="name" onChange={handleHeader2} />                            
                        </div>

                        


                    </div>

                    <div style = {labelContainer}>

                        <button style = {submitStyle} type="submit" onClick={handleSubmit}>Submit Headers</button>

                    </div>

                </div>

                <div style = {exampleDocumentStyle}>
                    <img src={exampleDocument} alt="" />
                    <p style= {exampleText}># of Columns:<span style = {headerStyle}>6</span></p>
                    <p style= {exampleText}>Header Name:<span style = {headerStyle}>Header</span></p>
                </div>
                </div>

            </form>
        )
    }


export default ComparisonHeader