// CheckoutForm.js
import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import "../../css/App.css";
import { useSelector } from 'react-redux';


const CheckoutForm = ({ onCustomerId, existingCustomerId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const fname = useSelector(state => state.user.name)
  const lname = useSelector(state => state.user.lastName)
  const name = (fname + " " + lname).trim()
  const email = useSelector(state => state.user.email);
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentMethod, setPaymentMethod] = useState(null);
  const userEmail = useSelector(state => state.user.email)
  const [responded, setResponded] = useState(false)
  const [paymentClicked, setPaymentClicked] = useState(false)

  useEffect(() => {
    console.log("paymentClicked: " + paymentClicked)
    if(!responded) {
      getCustomer(userEmail)    
    }
    
  }, [responded, paymentClicked])

  const getCustomer = async (email) => {
    try {
  
      const response = await axios.get(`${process.env.REACT_APP_CUSTOMER_EMAIL}`, {
        params: {
          email: email,
        },
      });
  
      const customer = response.data;
      existingCustomerId(customer.id)
      setResponded(true)
    } catch (error) {
      console.log('error customer', error);
    }
  }

  
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (paymentClicked) {
      return
    }
    
    setPaymentClicked(true)

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: email,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      setPaymentMethod(paymentMethod);
      try {
        const response = await axios.post(process.env.REACT_APP_SAVE_PAYMENT_METHOD, {
          name:  name,
          email: email,
          paymentMethodId: paymentMethod.id
        });

        const data = response.data;
        if (data.error) {
          setErrorMessage(data.error);
        } else {
          onCustomerId(data.customerId);
          window.location.reload();
        }
      } catch (err) {
        setErrorMessage(err.message);
      }
    }

  };

  
  return (
    <form className="card-form" onSubmit={handleSubmit}>
      <input
        type="hidden"
        value={email}
        
      />
      <div className="card-element">
        <CardElement />
      </div>
      <button type="submit" className="green-button" disabled={!stripe}>
        Add Payment Method
      </button>
      {paymentMethod && <div>Payment Method Saved</div>}
    </form>
  );
};

export default CheckoutForm;
