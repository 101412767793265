import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import video from '../../img/newToLevlr.mp4';
import Logout from '../logout/logout';
import Button from '@mui/material/Button';
import Modal from 'react-modal';

import levlr_logo from "../../img/LEVLR Logo_Reveersed.png";


const LeftNavigationPanel = props => {

    const [isActive, setActive] = useState(false);
    const isLoggedIn = useSelector(state => state.isLoggedIn)
    const userData = useSelector(state => state.user)
    // const isActive = useSelector(state => state.user.isActive)
    const isAdmin = userData.admin

    const [videoOpen, setVideoOpen] = useState(false);

    //console.log("email: " + userData.email)
    //console.log('admin?: ' + userData.admin) // returned "user"
    // returned "undefined" console.log('admin? ' + userData.role.admin)
    const fabIcon = {
        width: 'auto',
        backgroundColor: '#555555',
        borderRadius: '0px',
        height: 'auto',
        padding: '4px 24px',
        fontWeight: 'bold',
        boxSizing: 'border-box',
        fontSize: '28px',
        padding: '12px',
        margin: '0 16px',
        boxShadow: '-5px 5px 5px #222222'
    }
    const modalRestyleLarge = {
        overlay: {
            top: '25%',
            left: '25%',
            width: '50%',
            height: '75%',
            position: 'fixed',
        },
        content: {
            overflow: 'hidden',
            alignItems: 'center',
            backgroundColor: '#EEEEEE',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        }
    }
    const videoStyle = {
        display: 'block'
    }

    const xButton = {
        position: 'absolute',
        right: '3%',
        top: '3%',
        background: 'none',
        textAlign: 'right',
        width: 'fit-content',
        float: 'right',
        justifyContent: 'flex-end',
        alignSelf: 'end',
        fontSize: '24px',
        border: 'none',
        zIndex: '99'

    }

    function toggleClass() {
        setActive(!isActive);
    };

    {/* style={({isActive}) => ({})} */}

    function checkAdmin(){
        if(isAdmin == true){
            return(
                <li>{<NavLink to="/adminPanel" className="nav-item nav-link">Admin</NavLink>}</li>
            )
        }
    }

    function openVideo(){
        console.log("start open video!")
        console.log("videoOpen: " + videoOpen)
        setVideoOpen(true)
        console.log("videoOpen: " + videoOpen)
    }

    

    function checkInternal() {
        if(userData.email === "john.coday@pdgcreative.com" || userData.email === "caleb.williams@pdgcreative.com" || userData.email === "andrew@pdgcreative.com" || userData.email === "travis@pdgcreative.com" || userData.email === "travis+levlr.test@pdgcreative.com") {
            return(
                <li>{<NavLink to="/comparisonReports" className="nav-item nav-link">Comparison Report</NavLink>}</li>
            )
        }
    }

    function renderLeftNav() {
    
        if (isActive == false) {
            return (
                
                <div className={'left-container'}>

                    <div className={'elkmont-logo-container'}>
                        <img src={levlr_logo} className={'elkmont-logo-resize'}></img>
                    </div>
                        
                    <nav className={'nav-list'}>
                        
                        <ul>
                            {/* <li><NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'clicked-dashboard' : null)}>Dashboard</NavLink></li> */}
                            <li><NavLink to="/activeClaims" className={({ isActive }) => (isActive ? 'clicked-active-claims' : null)}>Active Comparisons</NavLink></li>
                                
                            {/*<li><NavLink to="/archivedClaims" className={({ isActive }) => (isActive ? 'clicked-archived-claims' : null)}>Archived Comparisons</NavLink></li>
                            {/* <li><NavLink to="/insuranceCompanies" className={({ isActive }) => (isActive ? 'clicked-insurance-companies' : null)}>Insurance Companies</NavLink></li>
                            <li><NavLink to="/statistics" className={({ isActive }) => (isActive ? 'clicked-statistics' : null)}>Statistics</NavLink></li>
                            <li><NavLink to="/reports" className={({ isActive }) => (isActive ? 'clicked-reports' : null)}>Reports</NavLink></li> */}
                            {checkAdmin()}
                            {checkInternal()}

                            
                            {/* <li><NavLink to="/adminDashboard" className="nav-item nav-link">Admin</NavLink></li> */}
                        </ul>
                        {/* <button onClick={Logout()} className="button-page">Logout</button> */}
                        <br/>
                        <br/>
                        <br/>
                        <Button
                            style={fabIcon}
                            variant="contained"
                            onClick={() => openVideo()}
                        >
                            NEW TO LEVLR? CLICK HERE
                        </Button>
                    </nav>
                </div>
            ) 
        }
      
    }

    return (
        <div>
            <div className={isActive ? 'hamburger': 'hamburger-change'} onClick={() => {toggleClass();}}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
            {renderLeftNav()}
            <Modal
                ariaHideApp={false}
                style={modalRestyleLarge}
                isOpen={videoOpen}
                onRequestClose={() => setVideoOpen(false)}
            > 
            <a style={xButton} onClick={() => setVideoOpen(false)}>X</a>
            <video style={videoStyle} width="100%" height="100%" controls>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            </Modal>
        </div>
    )

}

export default LeftNavigationPanel