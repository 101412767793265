// Frontend code using Stripe Elements
import React, { useEffect, useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import "../../css/App.css";
import { useSelector, useDispatch } from 'react-redux';

const SubscriptionForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('')
  const userData = useSelector(state => state.user)
  const [hasSubscription, setHasSubscription] = useState(false)
  const [customer, setCustomer] = useState();
  const [customerResponded, setCustomerResponded] = useState(false)
  const [subscriptionResponded, setSubscriptionResponded] = useState(false)
  const [subscription, setSubscription] = useState();
    //Revert to this once the trial/always 200 off timeframe is over. 
  //const [couponCode, setCouponCode] = useState(userData?.coupon)

  //Until then, set the default couponCode to an empty string. If an empty string, the backend will insert 200OFF
  const [couponCode, setCouponCode] = useState("")
  const [invoiceDetails, setInvoiceDetails] = useState()
  const [invoiceResponded, setInvoiceResponded] = useState(false)
  //console.log("invoiceDetails: " + JSON.stringify(invoiceDetails?.invoice,null,2));
  const [comps, setComps] = useState()
  const [comparisonsResponded, setComparisonsResponded] = useState(false)
  const [setToCancel, setSetToCancel] = useState(false)
  const [amount, setAmount] = useState(0)
  const [description, setDescription] = useState("")
  const [subscribeClicked, setSubscribeClicked] = useState(false)
  const [calculateResponded, setCalculateResponded] = useState(false)
  const [count, setCount] = useState(0)  
  //console.log("userData: " + JSON.stringify(userData, null, 2))
  const dispatch = useDispatch();
  //console.log("userData: " + JSON.stringify(userData,null,2))


  useEffect(() => {
    if(!customerResponded) {
      getCustomerDetails(userData.email)
    }
    if(!comparisonsResponded) {
      getResults(userData._id)
    }
    if(subscription?.data[0] && calculateResponded){
      updateUserSubscription()
    }
    if(!calculateResponded && comparisonsResponded) {
      calculateAdditionalBill()
    }
    if((userData?.baseSubscription?.customer != "" || userData?.baseSubscription?.customer != null || userData?.baseSubscription?.customer != undefined) && !invoiceResponded) {
      getInvoiceDetails(userData?.baseSubscription?.customer)
      setInvoiceResponded(true)
    }
    //console.log("Customer: " + JSON.stringify(customer,null,2))
    //console.log("userData: " + JSON.stringify(userData,null,2))
  },  [userData, dispatch, customerResponded, subscriptionResponded, calculateResponded, amount, description, count, comps, couponCode, invoiceDetails])

  const unsetColor = {
    color: "#555555"
  }


  const getCustomerDetails = async (email) => {
    console.log(email)
    try {
      const customerResponse = await axios.get(`${process.env.REACT_APP_CUSTOMER_EMAIL}`, {
        params: {
          email: email,
        },
      });
  
      const customer = customerResponse.data;
      // Ensure userData is defined and has an _id field
      if (userData && userData._id) {
        if (customer.id) {
          setCustomer(customer);
          setCustomerResponded(true);
          const subscriptionResponse = await axios.get(`${process.env.REACT_APP_GET_CUSTOMER_SUBSCRIPTIONS}${userData?.stripeCustomer?.id}`);
          const subscriptionData = subscriptionResponse.data;
          setSubscription(subscriptionData);

  
          setSubscriptionResponded(true);
        }
      } else {
        console.error('User data or user ID is not defined');
      } 
    } catch (error) {
      console.error("Error in getCustomerDetails: ", error);
    }
  };
  
const updateUserSubscription = async () => {
  const update = axios.post(process.env.REACT_APP_UPDATE_USER_SUBSCRIPTION, {
    user: userData._id,
    created: subscription?.data[0]?.created,
    start: subscription?.data[0]?.current_period_start,
    end: subscription?.data[0]?.current_period_end,
    used: count,
  })
  console.log("update: " + JSON.stringify(update,null,2))
}

function readableDate(date) {
  if (date !== undefined && date !== null) {
    // Convert the Unix timestamp to a readable date without time
    const readable = new Date(date * 1000).toLocaleDateString();
    return readable;
  } else {
    return "N/A";
  }
}


const handleSubmit = async (event) => {
    event.preventDefault();

    if(subscribeClicked) {
      return
    }
    setSubscribeClicked(true)
    if (!stripe || !elements) {
      return;
    }

    if (couponCode === "") {
      const confirmApplyWithoutCoupon = window.confirm("No coupon code provided. Do you want to continue?");
      if (!confirmApplyWithoutCoupon) {
          return; // Stop the submission if user cancels
      }
    }
    //console.log("baseSubscription: " + JSON.stringify(userData?.baseSubscription))
    if (!userData?.baseSubscription) {
      try {
          const response = await axios.post(process.env.REACT_APP_CREATE_BASE_SUBSCRIPTION, {
              email: userData?.email,
              customerId: userData?.stripeCustomer?.id,
              paymentMethodId: userData?.stripeCustomer?.invoice_settings?.default_payment_method,
              priceId: process.env.REACT_APP_SUBSCRIPTION,
              coupon: couponCode
          });
  
          // Log the subscription response for debugging
          console.log("subscription: " + JSON.stringify(response.data, null, 2));
          
          // Handle success: Reload the page or redirect
          window.location.reload();
          
          // You might also want to display a success message to the user here
      } catch (err) {
          // Extract error message from the response
          const errorMessage = err.response?.data?.error?.message || 'An unexpected error occurred.';
          
          // Set the error message for display
          setErrorMessage(errorMessage);
          
          // Prompt the user to confirm before reloading the page
          if (window.confirm('That coupon does not exist. Do you want to try again?')) {
              window.location.reload();
          }
          
          // Optionally, you can log the error for debugging
          console.error("Error creating subscription: ", err);
      }
  }
  
}


const handleCancel = async () => {
  const cancelSubscription = await axios.post(`${process.env.REACT_APP_CANCEL_SUBSCRIPTION}/${userData.baseSubscription.id}`)
  window.location.reload();
  //console.log("post: " + JSON.stringify(cancelSubscription,null,2))
  if (cancelSubscription.data) {
      getCustomerDetails(userData.email)
  } 
}

function calculateAdditionalBill() {
  if(userData?.baseSubscription?.status != "trialing") {
    const periodStart = userData?.baseSubscription?.current_period_start;
  const periodEnd = userData?.baseSubscription?.current_period_end;

  let updatedCount = count; // Initialize with current count value

  if (comps && comps.length > 0) {
    for (let i = 0; i < comps.length; i++) {
      let date = new Date(comps[i].dateUploaded);
      let utcDate = Math.floor((date.getTime() + (68800 * 1000)) / 1000);
      //console.log("utcDate: " + utcDate);
      if (utcDate > periodStart && utcDate < periodEnd && comps[i].status === "Finished") {
        console.log("if: ");
        updatedCount++; // Increment count using a temporary variable
      }
    }
  }

  console.log("updatedCount: " + updatedCount); // Log updatedCount instead of count

  // Calculate amount and description based on updatedCount
  if ((updatedCount - userData?.monthly_allotment) > 0) {
    const calculatedAmount = (updatedCount - userData?.monthly_allotment) * userData?.additional_cost;
    setAmount(calculatedAmount);

    const writeDescription = `You used ${updatedCount - userData?.monthly_allotment} additional comparisons this billing cycle @ $${userData?.additional_cost} per comparison. Total: ${calculatedAmount}`;
    setDescription(writeDescription);
  }

  setCount(updatedCount); // Update count state with the final value
  setCalculateResponded(true);
    
  }
  
}

const getResults = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_RESULTS_DATA}user/${id}`);
    const data = await response.json();

    setComps(data);
    setComparisonsResponded(true)
  } catch (error) {
    console.error("Error fetching results:", error);
  }
}
function checkUserState() {
  if (userData?.coupon != "") {
    return true
  }
  else {
    return false
  }
}

const getInvoiceDetails = async (id) => {
  console.log("id: " + id);
  try {
    const response = await fetch(`${process.env.REACT_APP_INVOICE_DETAILS}/${id}`);
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    const data = await response.json();
    console.log("Invoice Details:", data);
    
    // Update state or handle data as needed
    setInvoiceDetails(data);
  } catch (error) {
    console.error("Error fetching invoice details:", error);
  }
};


function returnForms(){
  //console.log("userData: " + JSON.stringify(userData?.baseSubscription, null, 2))
  if((userData?.baseSubscription === undefined || userData?.baseSubscription === null) && (userData?.stripeCustomer?.invoice_settings?.default_payment_method !== undefined)){    
    return(
          <form  className="subscribe-form" onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Coupon Code"
                value={couponCode}
                disabled={checkUserState()}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              
              <button className="green-button" type="submit" disabled={!stripe}>
                  Subscribe
              </button>
              <i>Subscriptions start at $299.00. New Customers will receive $200.00 off every month.</i>
              {errorMessage && <div>{errorMessage}</div>}
              
          </form>
      )
  }
  else if(userData?.baseSubscription?.status === "active" && userData?.baseSubscription?.cancel_at_period_end) {
    console.log("trialing")
    return(
      <div className="subscribe-form">
          <p>Subscription set to cancel on <strong>{new Date(userData?.baseSubscription?.cancel_at * 1000).toLocaleString()}</strong>.</p>
      </div>
    )
  }
  else if(userData?.baseSubscription?.status === "trialing" && userData?.baseSubscription?.trial_end) {
    console.log("trialing")
    return(
      <div className="cancel-container">
        <p style={{justifyContent: 'flex-start'}}>Free Trial set to end on <strong>{new Date(userData?.baseSubscription?.trial_end * 1000).toLocaleString()}</strong>.</p>

        <button className="gray-button" onClick={() => handleCancel()} type="submit">
          Cancel Subscription
        </button>
      </div>
    )
  }
  else if (userData?.baseSubscription?.status === "active" || userData?.baseSubscription?.status === "trialing") {
    return (
      <div className="cancel-container">
        <button className="gray-button" onClick={() => handleCancel()} type="submit">
          Cancel Subscription
        </button>
      </div>
    );
  }
    
  else {
      return
  }
}

  return (
    <div>
      {returnForms()}
      <div className="price-model">
      <p>
        Comparisons used this cycle:{" "}
        <strong>
          {userData?.baseSubscription?.current_period_start ? count : "No Subscription"}
        </strong>
      </p>        
        <p>Allotment: <strong style={unsetColor}>{userData?.monthly_allotment}</strong></p>
        
        <p>Price/Additional: <strong style={unsetColor}>${userData?.additional_cost}</strong></p>

        <br />
        <br />

        <p>Subscription Created</p>
        <strong>{readableDate(userData?.baseSubscription?.created)}</strong>

        <p>Next Bill</p>
        <strong>
          {readableDate(invoiceDetails?.invoice?.period_end)}: $
          {(typeof invoiceDetails?.invoice?.amount_due === 'number' ? 
            (invoiceDetails.invoice.amount_due / 100).toFixed(2) : '0.00')}
        </strong>

      </div>
    </div>
  );
};

export default SubscriptionForm;