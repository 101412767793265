import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../css/Accounts.css';
import MainWrapper from '../wrappers/mainWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import "../../css/App.css";
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import axios from 'axios';


const EditAccount = props => {
    const navigate = useNavigate()
    let getUserId = useSelector(state => state.user)
    const [active, setActive] = useState({isActive: false})

    const [openPricing, setOpenPricing] = useState(false)
    
    const [pricingInformation, setPricingInformation] = useState({
        monthly_allotment: 0,
        additional_cost: 9.99,
        coupon: ""
    })

    console.log("pricingInformation: " + JSON.stringify(pricingInformation, null, 2))

    const [userData, setUserData] = useState()
    const [responded, setResponded] = useState(false)
    const params = useParams();
    const editButton = {
        color: 'blue',
        fontSize: '10px'
    }

    const modalStyle = {
        textAlign: 'center',
        background: 'none',
        marginBottom: '12px'
    }

    const modalRestyleSmall = {
        overlay: {
            top: '25%',
            left: '25%',
            width: '50%',
            height: '30%',
            position: 'fixed',
        },
        content: {
            inset: '5%',
            border: '1px solid black',
            backgroundColor: '#DDDDDD'
        }
    }

    const modalRestyleLarge = {
        overlay: {
            top: '25%',
            left: '25%',
            width: '50%',
            height: '70%',
            position: 'fixed',
        },
        content: {
            inset: '5%',
            border: '1px solid black',
            backgroundColor: '#DDDDDD'
        }
    }

    const buttonContainer = {
        display: 'flex',
        flexDirection: 'column',
        padding: '4px 4px',
        width: '94%',
        justifyContent: 'space-between',
        position: 'absolute',
        bottom: '5%',
        marginTop: '12px'
    }

    const textFieldStyle = {
        display: 'grid',
        padding: '2px',
        backgroundColor: 'white',
        margin: '12px',
        borderRadius: '4px'
    }

    const centerFields = {
        alignItems: 'middle'
    }

    useEffect(() => {
        if(!responded){
            getUser(params.id)
        }
      
      }, [responded])
    
    const inlineStatus = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'space-between'
    }

    const getUser = async (id) => {
        const response = await fetch(
            `${process.env.REACT_APP_GET_USER_DATA}${id}`
        ).then((response) => response.json({}));
        setUserData(response)
        setResponded(true)
    }

    const deactivateUser = () => {

        if(userData.email != undefined && userData.email != null) {
            let formData = {email: userData.email};
            axios({
                method: 'PUT',
                url: `${process.env.REACT_APP_DEACTIVATE}`,
                data: formData
            }).then(response => {
                getUser(params.id)
            });
        }
    }
      
 
    const activateUser = () => {
        
        if(userData.email != undefined && userData.email != null) {
            let formData = {email: userData.email};
            axios({
                method: 'PUT',
                url: `${process.env.REACT_APP_ACTIVATE}`,
                data: formData
            }).then(response => {
                getUser(params.id)
            });
        }
    }

    function handleSavePricing(id) {
        setOpenPricing(false)
        axios.put(`${process.env.REACT_APP_SAVE_PRICING}${id}`, pricingInformation)
          .then(response => {   
            getUser(params.id)
          })
          .catch(error => {
            alert("Saving was unsuccessful. Please try again.");
            console.error('There was an error!', error);
          });
    }
   
    function returnVal(value){
        if(value === undefined) {
            return (
                "N/A"
            )
        }
        else {
            return(
                value
            )
        }
    }   
    function returnActive(val) {
        if (val) {
            return(
                <div style={inlineStatus}>
                    <strong>Subsctiption Status:</strong><p>Active</p><br /><button className={'deactivate-button'} onClick={()=> deactivateUser()}>Deactivate</button>
                </div>
            )
        }
        else {
            return(
                <div style={inlineStatus}>
                    <strong>Subsctiption Status:</strong><p>Inactive</p><br /><button className={'activate-button'} onClick={()=> activateUser()}>Activate</button>
                </div>
            )
        }
    }
    const numbersOnly = (e) => {
        // Remove any non-numeric characters from the input value
        e.target.value = e.target.value.replace(/\D/g, '');
    };
    const numbersAndDec = (e) => {
        let value = e.target.value;
    
        // Allow only digits and a single decimal point with up to two decimal places
        if (!/^\d*\.?\d{0,2}$/.test(value)) {
            // Remove invalid characters
            value = value.slice(0, -1);
        }

        e.target.value = value;
    };


    function returnProfileContainer() {
        if(userData === undefined) {
            return(
                <div className={'profile-container'}>
                    <h1>One Moment Please.</h1>
                </div>
            )
        }
        else {
            return(
                <div className={'profile-container'}>
                    

                   

                    <div className={'account-details'}>

                        <div style={{width: '40%'}}>
                            <div>
                                <h2 className={'green-large-bold'}>ACCOUNT DETAILS</h2>
                                <p className={'black-small-bold'}>{returnVal(userData?.company?.name)}</p>
                                <p className={'black-small'}>{returnVal(userData?.email)}</p>
                            </div>
                            
                            <div style={{marginTop: '12px'}}>
                                <h2 className={'green-small-bold'}>COMPANY</h2>
                                
                                <p className={'black-small-bold'}>{returnVal(userData?.company?.name)}</p>
                                <p className={'black-small'}>
                                    {returnVal(userData?.company?.address_1)} 
                                </p>
                                <p className={'black-small'}>
                                    {returnVal(userData?.company?.address_2)} 
                                </p>
                                <p className={'black-small'}>
                                    {returnVal(userData?.company?.city)}, {returnVal(userData?.company?.state)} &nbsp; {returnVal(userData?.company?.zipCode)}
                                </p>
                                
                            </div>
                        
                            <div style={{marginTop: '12px'}}>
                                <h2 className={'green-small-bold'}>ACCOUNT TYPE</h2>
                                <p className={'black-small-bold'}>
                                    {userData?.acc_type} 
                                </p>
                            </div>

                            <div style={{marginTop: '12px'}}>
                                <h2 className={'green-small-bold'}>PHONE NUMBER</h2>
                                    
                                <p className={'black-small-bold'}>
                                    {returnVal(userData?.phone)}
                                </p>
                            </div>
                        </div>     
                        <div style={{width: '40%'}}>
                            <p>{returnActive(userData.isActive)}</p>
                            <br />   
                            <p><strong>Monthly Allotment: </strong>{userData.monthly_allotment || 0}</p>
                            <br />   
                            <p><strong>Additional Cost: </strong>${userData.additional_cost || 9.99}</p>
                            <br />   
                            <p><strong>Coupon: </strong>{userData.coupon || ""}</p>                            
                            <br />   
                            <button onClick={()=> setOpenPricing(true)} className={'pricing-button'}>Edit Pricing Model</button>
                            <Modal
                                ariaHideApp={false}
                                style={modalRestyleLarge}
                                isOpen={openPricing}
                                onRequestClose={() => setOpenPricing(false)}
                            > 

                                <div style={modalStyle}>
                                    <h1>Edit Pricing Information</h1>
                                </div>

                                <div style={centerFields}>
                                    <TextField
                                        style={textFieldStyle}
                                        label={"Monthly Allotment"}
                                        onInput={numbersOnly}
                                        defaultValue={userData?.monthly_allotment}
                                        onBlur={(e) => setPricingInformation({...pricingInformation, monthly_allotment: e.target.value.trim()})}
                                    />
                                    <TextField
                                        style={textFieldStyle}
                                        label={"Additional Cost"}
                                        onInput={numbersAndDec}
                                        defaultValue={userData?.additional_cost}
                                        onBlur={(e) => setPricingInformation({...pricingInformation, additional_cost: e.target.value.trim()})}
                                    />
                                    <TextField
                                        style={textFieldStyle}
                                        label={"Coupon"}
                                        defaultValue={userData?.coupon}
                                        onBlur={(e) => setPricingInformation({...pricingInformation, coupon: e.target.value.trim()})}
                                    />
                                </div>

                                <div style={buttonContainer}>
                                    <Button
                                        size="small"
                                        sx={{
                                        ':hover': {
                                            backgroundColor: '#999999'
                                        },
                                        fontWeight: 'bold', 
                                        color: 'white',
                                        margin: '8px 8px',
                                        backgroundColor: '#723E49'}}
                                        onClick={() => setOpenPricing(false)}
                                    >
                                        Exit
                                    </Button>
                                    <Button
                                        size="small"
                                        sx={{
                                        ':hover': {
                                            backgroundColor: '#999999'
                                        },
                                        fontWeight: 'bold',
                                        margin: '8px 8px',
                                        color: 'white', 
                                        backgroundColor: '#00B3B5'}}
                                        onClick={() => handleSavePricing(params.id)}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Modal>
                        </div>                   
                    </div>
                    
                </div>
            )
        }
    }
   

    return (
        <div className={'outer-container'}>
            <MainWrapper/>
            <div className={'right-container'}>
                {returnProfileContainer()}
            </div>
        </div>
    )
}

export default EditAccount
