import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { putUpdatePassword } from '../../actions/auth';


import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import queryString from 'query-string';


import backgroundImage from "../../img/LEVLR-Login-Background.jpg";
import levlr_logo from "../../img/LEVLR Logo_CMYK.png";

  const ResetPassword = props => {
    
    const [match, setMatch] = useState(false)
    const [response, setResponse] = useState({})
    const [credentials, setCredentials] = useState({})
       
    const navigate = useNavigate()
    const dispatch = useDispatch();

    useEffect(() => {
        //getReset(params); 
        sessionTimeout()
        compareToken(token)   
    }, [sessionTimeout()])

    function sessionTimeout() {
        let now = new Date()
        let expire = new Date(response.resetPasswordExpires)
        if (expire.getTime() < now.getTime()) {
            alert("Your Reset Session Timed Out!")
            navigate(`/login`)
        }
        else {
            console.log("NOT EXPIRED")
        }
    }


    {/*
    const getReset = async (params) => {
        axios.get('localhost:3000/reset', {
            params: {
                resetPasswordToken: props.match.params.token,
            },
        }).then(response => {
            console.log(response);
        })
    }
    */}

    let { token } = useParams();

    const compareToken = async (token) => {
        const response = await fetch(
            `${process.env.REACT_APP_TOKEN}/${token}`
        ).then((response) => response.json({}));
        setResponse(response)
    }

    function validatePassword() {
        if (document.getElementById('password') === null) {
            return false
        }
        else {
            var p = document.getElementById('password').value,
            errors = [];
            if (p.length < 10) {
                errors.push("10 characters. \n");
            }
            if (p.search(/[a-z]/) < 0) {
                errors.push("1 lowercase letter. \n"); 
            }
            if (p.search(/[A-Z]/) < 0) {
                errors.push("1 uppercase letter. \n"); 
            }
            if (p.search(/[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]/i) < 0) {
                errors.push("1 special character. \n"); 
            }
            {/*
            if (p.search(/[0-9]/) < 0) {
                errors.push("1 digit. \n");
            }
            */}
            if (errors.length > 0) {
                return (errors)
            }
        return true
        }
    }

    function validateConfirm() {
        if((document.getElementById('password') === null) && (document.getElementById('confirm') === null)) {
            return false
        }
        if((document.getElementById('password') === null) || (document.getElementById('confirm') === null)) {
            return false
        }
        if((document.getElementById('password') === undefined) && (document.getElementById('confirm') === undefined)) {
            return false
        }
        if((document.getElementById('password') === undefined) || (document.getElementById('confirm') === undefined)) {
            return false
        }
        else {
            if (document.getElementById('password').value === document.getElementById('confirm').value) {
                return true
            }
            else {
                return false
            }
        }
    }

    const handleConfirm = e => {
        if (e.target.value === document.getElementById('password').value) {
            setMatch(true)
        }
        else {
            setMatch(false)
        }
    }

    function allowReset() {
        if ((validateConfirm() === true) && (validatePassword() === true)) {
            return loginButtonStyle
        }
        else {
            return loginButtonStyleDisabled
        }
    }

    function renderPasswordRequirements() {
        if(validatePassword() === undefined) {
            return
        }
        if(validatePassword().length != undefined) {
            return (
                <div style={requirementStyles}>
                    {validatePassword().map((req, i) =>
                        <span style={noPadding} key={i}>{req}</span>
                    )}
                </div>
            )
        }
    }
 


    const handleChange = e => {
        setCredentials({ ...credentials, 
            email: response.email,
            [e.target.name]: e.target.value})
    }

    const routeChange = () => { 
        let path = `/login`; 
        navigate(path);
    }

    function returnToLogin() {
        let path = `/login`; 
        navigate(path);
    }

    const updatePassword = e => {
        e.preventDefault()
        returnToLogin()
        dispatch(putUpdatePassword({ props, credentials })) 
    };

    const backGroundStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '100%',
      paddingBottom: '10%',
      paddingTop: '10%'
  }

  const noPadding = {
    padding: '0 0',
    margin: '0 0'
  }

  const requirementStyles = {
    padding: '0 0',
    margin: '0 0',
    display: 'inline',
    width: '65%',
    height: 'auto',
    textAlign: 'left',
    fontSize: '1.75vh',
    fontWeight: 'bold',
    color: 'rgb(1, 101, 106)'
}

  const loginPaperStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      width: '70%',
      height: 'auto',
      paddingTop: '4vh',
      paddingBottom: '4vh',
      marginLeft: 'auto',
      marginRight: 'auto'
  }
  
  const loginTextFieldStyle = {
      width: '65%',
      height: 'auto'
  }
  
  const loginFormStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      marginTop: 1
  }
  
  const loginButtonStyle = {
      width: '50%',
      color: "white",
      backgroundColor: "#2fb7b7",
      '&:hover': {
          backgroundColor: '#01656a'
      },
      margin: 16
  }

  const loginButtonStyleDisabled = {
    pointerEvents: 'none',
    width: '50%',
    color: "white",
    backgroundColor: "#01656a",
    margin: 16,
}
  
  const signupButtonStyle = {
      width: '50%',
      color: "white",
      backgroundColor: "#01656a",
      '&:hover': {
          backgroundColor: '#2fb7b7'
      },
      margin: 16
  }
  
  const levlrLogoStyle = {
      width: '40%',
      height: 'auto'
  } 
  
      return (
        <div className="Background-Container" style={backGroundStyle}>
        <Container component="main" maxwidth="xs">
                <CssBaseline />
                <Paper style={loginPaperStyle}>
                    <img src={levlr_logo} style={levlrLogoStyle} alt="Levlr Logo"/>
                    <form style={loginFormStyle} noValidate onSubmit={(e) => updatePassword(e)}>
                      <div>Please Note: Passwords are Case Sensitive</div>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        id="email"
                        name="email"
                        value={response.email}
                        disabled={true}
                        autoFocus
                        onChange={handleChange}
                        style={loginTextFieldStyle}
                    />
                    <div> Enter A New Password </div>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required={true}
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                        helperText={renderPasswordRequirements()}
                        onChange={handleChange}
                        style={loginTextFieldStyle}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required={true}
                        id="confirm"
                        label="Confirm Password"
                        name="password"
                        type="password"
                        style={loginTextFieldStyle}
                        onChange={handleConfirm}
                        error={!match}
                        helperText={match ? ' ' : 'Passwords Do Not Match.'}
                    />
            
                    <Button
                        type="submit"
                        variant="contained"
                        style={allowReset()}
                    >
                        Reset Your Password
                    </Button>
                  </form>

                    <Button
                        type="submit"
                        variant="contained"
                        style={loginButtonStyle}
                        onClick={routeChange}
                    >
                        Return to Login
                    </Button>
                </Paper>
            </Container>
           
           </div>
        // <div className="Background-Container" style={backGroundStyle}>
        //   <div> title </div>
        //   <form className="password-form">
        //     <TextField
        //       id="password"
        //       name="password"
        //       label="password"
        //       onChange={handleChange}
        //       type="password"
        //     />
        //     <button>
        //      update password
        //       </button>
        //   </form>
  
        //   {/* {updated && ( */}
        //     {/* <div>
        //       <p>
        //         Your password has been successfully reset, please try logging in
        //         again.
        //       </p>
        //       <button>
        
        //        login
        //         </button>
        //     </div> */}
        //   {/* )} */}
        //   <button>home </button>
        // </div>
      );
          };
        
  
 export default ResetPassword;
//   import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import axios from 'axios';
// import TextField from '@material-ui/core/TextField';

// import {
//   LinkButtons,
//   updateButton,
//   homeButton,
//   loginButton,
//   HeaderBar,
//   forgotButton,
//   inputStyle,
//   SubmitButtons,
// } from '../components';

// const loading = {
//   margin: '1em',
//   fontSize: '24px',
// };

// const title = {
//   pageTitle: 'Password Reset Screen',
// };

// export default class ResetPassword extends Component {
//   constructor() {
//     super();

//     this.state = {
//       username: '',
//       password: '',
//       updated: false,
//       isLoading: true,
//       error: false,
//     };
//   }

//   async componentDidMount() {
//     const {
//       match: {
//         params: { token },
//       },
//     } = this.props;
//     try {
//       const response = await axios.get('http://localhost:3003/reset', {
//         params: {
//           resetPasswordToken: token,
//         },
//       });
//       // console.log(response);
//       if (response.data.message === 'password reset link a-ok') {
//         this.setState({
//           username: response.data.username,
//           updated: false,
//           isLoading: false,
//           error: false,
//         });
//       }
//     } catch (error) {
//       console.log(error.response.data);
//       this.setState({
//         updated: false,
//         isLoading: false,
//         error: true,
//       });
//     }
//   }

//   handleChange = name => (event) => {
//     this.setState({
//       [name]: event.target.value,
//     });
//   };

//   updatePassword = async (e) => {
//     e.preventDefault();
//     const { username, password } = this.state;
//     const {
//       match: {
//         params: { token },
//       },
//     } = this.props;
//     try {
//       const response = await axios.put(
//         'http://localhost:3003/updatePasswordViaEmail',
//         {
//           username,
//           password,
//           resetPasswordToken: token,
//         },
//       );
//       console.log(response.data);
//       if (response.data.message === 'password updated') {
//         this.setState({
//           updated: true,
//           error: false,
//         });
//       } else {
//         this.setState({
//           updated: false,
//           error: true,
//         });
//       }
//     } catch (error) {
//       console.log(error.response.data);
//     }
//   };

//   render() {
//     const {
//  password, error, isLoading, updated 
// } = this.state;

//     if (error) {
//       return (
//         <div>
//           <HeaderBar title={title} />
//           <div style={loading}>
//             <h4>Problem resetting password. Please send another reset link.</h4>
//             <LinkButtons
//               buttonText="Go Home"
//               buttonStyle={homeButton}
//               link="/"
//             />
//             <LinkButtons
//               buttonStyle={forgotButton}
//               buttonText="Forgot Password?"
//               link="/forgotPassword"
//             />
//           </div>
//         </div>
//       );
//     }
//     if (isLoading) {
//       return (
//         <div>
//           <HeaderBar title={title} />
//           <div style={loading}>Loading User Data...</div>
//         </div>
//       );
//     }
//     return (
//       <div>
//         <HeaderBar title={title} />
//         <form className="password-form" onSubmit={this.updatePassword}>
//           <TextField
//             style={inputStyle}
//             id="password"
//             label="password"
//             onChange={this.handleChange('password')}
//             value={password}
//             type="password"
//           />
//           <SubmitButtons
//             buttonStyle={updateButton}
//             buttonText="Update Password"
//           />
//         </form>

//         {updated && (
//           <div>
//             <p>
//               Your password has been successfully reset, please try logging in
//               again.
//             </p>
//             <LinkButtons
//               buttonStyle={loginButton}
//               buttonText="Login"
//               link="/login"
//             />
//           </div>
//         )}
//         <LinkButtons buttonText="Go Home" buttonStyle={homeButton} link="/" />
//       </div>
//     );
//   }
// }

// ResetPassword.propTypes = {
//   // eslint-disable-next-line react/require-default-props
//   match: PropTypes.shape({
//     params: PropTypes.shape({
//       token: PropTypes.string.isRequired,
//     }),
//   }),
// };
