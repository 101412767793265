import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { logger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import Reducer from './reducers';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import levlrLogo from './img/LEVLR Logo_CMYK.png'

// Redux store setup
const store = createStore(Reducer, applyMiddleware(thunk, logger));

// Component to display a welcome message for small screens
const WelcomeMessage = () => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '48px', textAlign: 'center' }}>
    <a href="/login"><img style={{ width: '300px', height: 'auto' }} src={levlrLogo} alt="LEVLR Logo" /></a>
    <br/>
    <br/>
    <br/>
    <br/>

    <h1 style={{ fontSize: '32px' }}>Welcome to LEVLR!</h1>
    <br/>
    <br/>

    <p style={{ fontSize: '20px' }}>Thank you for signing up! We’re excited to have you on board and can’t wait for you to experience the power of our AI-driven solution.</p>
    <br/>

    <p style={{ fontSize: '20px' }}>For the best experience, we recommend using our platform on a desktop. We’ve also sent a welcome email – be sure to check it out!</p>
    <br/>

    <p style={{ fontSize: '20px' }}>Let’s get to work saving you time and boosting your revenue!</p>
  </div>
);

// Main rendering component
const MainComponent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  // Update states on window resize and URL change
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1200);
    const handlePathChange = () => setCurrentPath(window.location.pathname);

    window.addEventListener('resize', handleResize);
    window.addEventListener('popstate', handlePathChange);
    window.addEventListener('hashchange', handlePathChange); // For client-side routing

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('popstate', handlePathChange);
      window.removeEventListener('hashchange', handlePathChange);
    };
  }, []);

  // Check if the current path is one of the allowed paths
  const allowedPaths = ['/login', '/register', '/forgot', '/'];
  const isAllowedPath = allowedPaths.includes(currentPath);

  // Check screen size and allowed paths to decide component rendering
  return isMobile && !isAllowedPath ? <WelcomeMessage /> : <App />;
};

// Initial rendering
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MainComponent />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
