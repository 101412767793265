import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import axios from 'axios'


import uploadIcon from '../../img/upload-file.png';
import fileIcon from '../../img/file-icon.png';
import helperImg from '../../img/header-helper.svg';
import leftArrow from '../../img/left-arrow.svg';
import rightArrow from '../../img/right-arrow.svg';


import NewModal from './modal';
import Modal from 'react-modal';
import ModalMUI from '@mui/material/Modal';
import '../../css/ActiveClaims.css';
import '../../css/AdjustmentsActiveClaims.css';


import MainWrapper from '../wrappers/mainWrapper';


const MAX_COUNT = 2;
export const comparisonState = false;
const CreateNewClaim = props => {

    const [claimData, setClaimData] = useState({
        adj_email : "",
        adj_phone: "",
        adjuster: "",
        claim_number: "",
        claim_type: "",
        date_open: "",
        insurance_comp: "",
        status: "",
        insured: "",
        insured_address: "",
        insured_phone: "",
        prop_type: "",
        user_id: "",
        doc1_name: "",
        doc2_name: ""
    })


    //ref to header columns container. used for scroll const below.
    const ref = useRef(null);   

    let path = `/activeClaims`;

    //use navigate later for redirect to activeClaims upon submitting comparison. 
    const navigate = useNavigate();
    const [responded, setResponded] = useState(false);

    //Set initial states for initial headers and missing value columns. Duplicate init into soft. 
    const [doc1InitHeaders, setDoc1InitHeaders] = useState([]);
    const [doc2InitHeaders, setDoc2InitHeaders] = useState([]);
    const [doc1SoftHeaders, setDoc1SoftHeaders] = useState([]);
    const [doc2SoftHeaders, setDoc2SoftHeaders] = useState([]);

    const [doc1InitMissing, setDoc1InitMissing] = useState([])
    const [doc2InitMissing, setDoc2InitMissing] = useState([])
    const [doc1SoftMissing, setDoc1SoftMissing] = useState([])
    const [doc2SoftMissing, setDoc2SoftMissing] = useState([])

    //console.log("doc1InitHeaders: " + doc1InitHeaders)
    //console.log("doc2InitHeaders: " + doc2InitHeaders)
    //console.log("doc1SoftHeaders: " + doc1SoftHeaders)
    //console.log("doc2SoftHeaders: " + doc2SoftHeaders)
    //console.log("doc1InitMissing: " + doc1InitMissing)
    //console.log("doc2InitMissing: " + doc2InitMissing)
    //console.log("doc1SoftMissing: " + doc1SoftMissing)
    //console.log("doc2SoftMissing: " + doc2SoftMissing)
    //console.log("doc1SoftHeaders.length: " + doc1SoftHeaders.length)


    //empty state gets populated with comparisonID created upon clicking submit comparison button. 
    const [comparisonID, setComparisonID] = useState("");

    //used for submit comparison button to only allow one click. 
    const buttonRef = useRef(null);

    //request userID. 
    const userData = useSelector(state => state.user._id)

    const [file1Name, setFile1Name] = useState("")
    const [file2Name, setFile2Name] = useState("")

    // set header1 and header2 to default empty objects so the current component doesn't render "undefined" object.
    const [modalData, setModalData] = useState({
        header1: {
            name:'',
            count:''
        },
        header2: {
            name:'',
            count:''
        }
    })

    // state for loading spinner
    const [loadingStatus, setLoadingStatus] = useState('')

    //open state for modals. 
    const [open, setOpen] = useState(false);
    const [modifyHeadersOpen1, setModifyHeadersOpen1] = useState(false)
    const [modifyHeadersOpen2, setModifyHeadersOpen2] = useState(false)
    const [confirmHeadersOpen, setConfirmHeadersOpen] = useState(false)

    //count number of clicks on the submit comparison button. 
    const [numberOfSubmits, setNumberOfSubmits] = useState(0)

    //used later to determine if submit comparison button should be clickable. 
    const [fileUploaded, setFileUploaded] = useState(false)

    // File State
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileLimit, setFileLimit] = useState(false);

    //variable that will eventually be sent to the DB and FastAPI. 
    let fullAdjustedHeaders = {
        doc1headers: doc1SoftHeaders,
        doc2headers: doc2SoftHeaders, 
        missing1: doc1SoftMissing,
        missing2: doc2SoftMissing,
        isModified: checkModified(),
        compID: comparisonID
    }

    //boolean var. not sure why this isn't a useState. 
    let limitExceeded = false;


{/* Methods and Functions below pertain to the initial form rendered in this component. */}    
    const handleClickOpen = e => {
        e.preventDefault() // do not submit <form>
        newClaim() // setClaimData, setModalData
        setOpen(true);
    }
    
    const handleClose = () => {
        setOpen(false);
    }
    
    useEffect(() => {
      setClaimData({
        ...claimData,
        user_id: userData
      })  
      doc1SoftHeaders
      doc2SoftHeaders
      doc1SoftMissing
      doc2SoftMissing
      checkModified()
      renderForm1()
      returnFile()
      if(doc1SoftHeaders.length === 0 && responded){
        doc1SoftHeaders.length = parseInt(modalData.header1.count)
        doc1SoftHeaders.fill("Undetected")
        doc1SoftHeaders.fill(modalData.header1.name, 0, 1)
        setDoc1InitMissing(new Array(parseInt(modalData.header1.count)).fill(false))
        setDoc1SoftMissing(new Array(parseInt(modalData.header1.count)).fill(false))
      }
      if(doc2SoftHeaders.length === 0 && responded){
        doc2SoftHeaders.length = parseInt(modalData.header2.count)
        doc2SoftHeaders.fill("Undetected")
        doc2SoftHeaders.fill(modalData.header2.name, 0, 1)
        setDoc2InitMissing(new Array(parseInt(modalData.header2.count)).fill(false))
        setDoc2SoftMissing(new Array(parseInt(modalData.header2.count)).fill(false))
      }
    }, [uploadedFiles, responded])
   
    const handleChange = e => {
        newClaim()
        setClaimData({ ...claimData, [e.target.name]: e.target.value})
    }

    const newClaim = () => {
        localStorage.setItem('user', '');
        setClaimData({...claimData, user_id : userData})
        setClaimData({...claimData, file1Name: file1Name})
        setClaimData({...claimData, file2Name: file2Name})
        setModalData({ ...modalData, claimData: {...claimData, file1Name: file1Name[0], file2Name: file2Name[0]}})
    }

    //Return true if all input values are greater than 0. Still does not validate proper input types.
    function checkHeaderEmptyStrings() {
        if(modalData.header1.name.length > 0 && modalData.header2.name.length > 0 && modalData.header1.count.length > 0 && modalData.header2.count.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    //Return a string or page break for the 3rd Modal in the missing values column.
    function returnMissingString (x) {
        if(x) {
            return "Blank(s)"
        }
        else {
            return <br />
        }
    }

    //determine whether or not the headers have been changed in the 1st or 2nd modal. 
    function checkModified() {
        if ((JSON.stringify(doc1InitHeaders) === JSON.stringify(doc1SoftHeaders)) && (JSON.stringify(doc2InitHeaders) === JSON.stringify(doc2SoftHeaders))) {
            return false
        }
        else {
            return true
        }
    }

    //verify that response does not return an undefined array.
    function verifyResponse(x) {
        console.log("x: " + x)
        if (x) {
            return x
        }
        else {
            console.log("else")
            return ""
        }
    }

    function returnFile() {
        if(uploadedFiles.length === 2) {
            setFile1Name(uploadedFiles.slice(0,1).map((key,val) => {
                return key.name
            }))
            setFile2Name(uploadedFiles.slice(1,2).map((key,val) => {
                return key.name
            }))
        
        }
    }

    //COMPLETE FORM SUBMISSION FROM MODALDATA STATE
    const newComparison = e => {
        e.preventDefault()
        newClaim()
        setLoadingStatus('loading-spinner');
        //const fullModalData = modalData
        //let path = `/activeClaims`;
        navigate(path);
        //console.log("before post")
        //console.log("newComparisonClaimData: " + JSON.stringify(claimData, null,2))
        //console.log("modalData:" + JSON.stringify(modalData,null,2))
        axios.post(`${process.env.REACT_APP_QUEUE}`, modalData)
        .then(response => {    
            setLoadingStatus('');
            //alert("Your comparison is nearly done. Please continue to verify header information.");
            //console.log("comparison done."); 
            if (response.data["code"] != 200){
                console.error("=====================================")
                console.error("ERROR CODE: " + response.data["code"])
                console.error("=====================================")
            }
            
            //add if logic for 001 error. set new modal for manual input. Piggy back off the existing Modal. Set new variables/useStates for this?
            setComparisonID(verifyResponse(response.data.data[0].comparisonID))
            setDoc1InitHeaders(verifyResponse(response.data.data[0].headers1))
            setDoc2InitHeaders(verifyResponse(response.data.data[0].headers2))
            setDoc1SoftHeaders(verifyResponse(response.data.data[0].headers1))
            setDoc2SoftHeaders(verifyResponse(response.data.data[0].headers2))
            setDoc1InitMissing(new Array((verifyResponse(response.data.data[0].headers1)).length).fill(false))
            setDoc2InitMissing(new Array((verifyResponse(response.data.data[0].headers2)).length).fill(false))
            setDoc1SoftMissing(new Array((verifyResponse(response.data.data[0].headers1)).length).fill(false))
            setDoc2SoftMissing(new Array((verifyResponse(response.data.data[0].headers2)).length).fill(false))

            setModifyHeadersOpen1(true)
            setResponded(true)
        })
        .catch(error => {
            //let path = `/activeClaims`;
            //alert("Error in parsing document. Please Try Again")
            navigate(path);
        });
    }

    const continueComparison = e => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_CONTINUE_COMPARISON}`, fullAdjustedHeaders)
        .then(response => {   
            setConfirmHeadersOpen(false)
            navigate(path);
         })  
         .catch(error => {
            //alert("There was an error submitting your changes. Please Try Again.")
            console.error('There was an error!', error);
            navigate(path);
        }); 
        axios.post(`${process.env.REACT_APP_SAVE_ADJUST_HEADERS}`, fullAdjustedHeaders)
        .then(response => {   
          alert("Thank you for confirming your headers!");
        })
        .catch(error => {
          alert("Saving was unsuccessful. Please try again.");
          navigate(path);
          console.error('There was an error!', error);
        });
    }

    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    alert(`You can only add a maximum of ${MAX_COUNT} files`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) setUploadedFiles(uploaded)

    }

    const handleFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);
    }

    //method called when "upload files" button is clicked. 
    const handleFileSubmit = (e) => {
        setLoadingStatus('loading-spinner')
        e.preventDefault()
        const formData = new FormData();
        uploadedFiles.map((file, index) => {
            formData.append(`file`, file);
        });
        fetch(
            `${process.env.REACT_APP_POST_FILES}`,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                console.log('Success:', result[1].filename);       
                setModalData({ ...modalData, files:{ "file_1" : result[0].filename,"file_2" : result[1].filename}, timeout: 10000000})
                setFileUploaded(true)
                setLoadingStatus('');
                alert("We've got your files, OK!");
            })     
            .catch((error) => {
                alert("Failed to upload your files.")
                console.error('Error:', error);
            });
    };

    function file1DefaultText () {
        const uploaded = [...uploadedFiles];
        if (uploaded.length === 0) {
            return (
                <p style={defaultText}>Select File #1</p>    
            ) 
        }
        else {
            return (
                <label htmlFor='fileUpload' className={'upload-new-file-button'} onClick={resetArray}>+ Upload New File</label>
            )
        }
    }

    function file2DefaultText () {
        const uploaded = [...uploadedFiles];
        if (uploaded.length === 1 || uploaded.length === 0) {
            return (
                <p style={defaultText}>Select File #2</p>    
            )
        }
        else {
            return (
                <label htmlFor='fileUpload' className={'upload-new-file-button'} onClick={resetArray}>+ Upload New File</label>
            )
        }
    }

    function allowUploadButton() {
        if (fileUploaded === true) {
            return 'button-submit-clear'
        }
        if (fileLimit === true) {
            return 'button-submit';
        }
        else {
            return 'button-submit-clear';
        }
    }
    
    function allowSubmitComparisonButton() {
        if (numberOfSubmits > 0) {
            return 'button-submit-comparison-disabled';
        }
        if ((fileUploaded == true && checkHeaderEmptyStrings() == true)) {
            return 'button-submit-comparison';
        }
        else {
            return 'button-submit-comparison-disabled';
        }
    }

    function toggleImg1() {
        const uploaded = [...uploadedFiles];
        if (uploaded.length === 1 || uploaded.length === 2) {
            return fileIcon;
        }
        else {
            return uploadIcon;
        }
    }

    function toggleImg2() {
        const uploaded = [...uploadedFiles];
        if (uploaded.length === 2) {
            return fileIcon;
        }
        else {
            return uploadIcon;
        }
    }

    //remove both uploaded files and reset states
    function resetArray () {
        var confirmAlert = confirm('Do you wish to remove both files from the session?')
        if(confirmAlert) {
            setUploadedFiles((uploadedFiles) => uploadedFiles.filter((_, index) => index !== 0));
            setUploadedFiles((uploadedFiles) => uploadedFiles.filter((_, index) => index !== 0));
            setFileLimit(false);
            limitExceeded = false;
        }
        else {
            return
        }
    }

{/* Methods and Functions below pertain to the modals that pop up after header detection. */}
    {/* logic to scroll element overflow in the 1st and 2nd modals. */}
    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
    };

    {/* handle input from text fields in Modal1. */}
    const handleHeaderChange1 = (index, e) => {
        const updateVal = doc1SoftHeaders.map((c, i) => {
            if (i === index) {
              // Return the current header
              if(e.target.value === "") {
                return doc1InitHeaders[index]
              }
              else {
                return (e.target.value).trim()
              }
            } else {
              // The rest haven't changed
              return c;
            }
          });
        setDoc1SoftHeaders(updateVal)
    }


    {/* handle input from text fields in Modal2. */}
    const handleHeaderChange2 = (index, e) => {
        const updateVal = doc2SoftHeaders.map((c, i) => {
            if (i === index) {
              // Return the current header
              if(e.target.value === "") {
                return doc2InitHeaders[index]
              }
              else {
                return (e.target.value).trim()
              }
            } else {
              // The rest haven't changed
              return c;
            }
          });
        setDoc2SoftHeaders(updateVal)
    }


    {/* handle yes input field in Modal1. */}
    const handleMissingVal1 = (index, e) => {
        const updateVal = doc1SoftMissing.map((c, i) => {
            if (i === index) {
              // Return the current val
              return e.target.checked;
            } else {
              // The rest haven't changed
              return c;
            }
          })
        setDoc1SoftMissing(updateVal)
    }

    {/* handle yes input field in Modal2. */}
    const handleMissingVal2 = (index, e) => {
        const updateVal = doc2SoftMissing.map((c, i) => {
            if (i === index) {
              // Return the current val
              return e.target.checked;
            } else {
              // The rest haven't changed
              return c;
            }
          });
        setDoc2SoftMissing(updateVal)
    }

    {/* Reset to initial values for document 1. */}
    function handleReset1() {
        setDoc1SoftHeaders(doc1InitHeaders)
        setDoc1SoftMissing(doc1InitMissing)
    }

    {/* Reset to initial values for document 2 */}
    function handleReset2() {
        setDoc2SoftHeaders(doc2InitHeaders)
        setDoc2SoftMissing(doc2InitMissing)
    }

    //UI flow. Open and close appropriate modals
    function handleSubmitDoc1Button() {
        setModifyHeadersOpen1(false)
        setModifyHeadersOpen2(true)
    }

    //UI flow. Open and close appropriate modals
    function handleSubmitDoc2Button() {
        setModifyHeadersOpen2(false)
        setConfirmHeadersOpen(true)
    }

    //UI flow. Open and close appropriate modals
    function handleReviewDocsButton () {
        setModifyHeadersOpen1(true)
        setConfirmHeadersOpen(false)
    }

    //Entire view for this component. 
    function renderForm1() {
        return (
            <div className={"create-new-claim-container"}>
                            
                <h1 className={'h1-create-new-claim'}>Create New Comparison</h1>
                
                {/* User Adjust Headers */}
                <div>
                    <Modal
                    style={modalRestyle}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                    isOpen={modifyHeadersOpen1}
                    onRequestClose={() => setModifyHeadersOpen1(false)}
                    >
                        {/* Container for entire Modal */}
                        <form style={modBackground}>
                            
                            {/* Container for header and close button */}
                            <div style={topDiv}>
                                <div>
                                    <h1 style={checkWork}>CHECK MY WORK</h1>
                                    <h1 style={docNumber}>Document #1</h1>
                                </div>
                                {/*<img src={xButton} style={xButtonResize} alt="x button" onClick={() => (setModifyHeadersOpen1(false) && alert("You will need to restart the comparison if you continue"))}/>*/}
                            </div>

                            <div style={flexContainer}>

                                <div style={modGuts}>
                            
                                    {/* Container for first column (question and Headers label). */}
                                    <div style={leftDiv}>
                                        <br />
                                        <div></div>
                                        <span style={headersLabel}>Detected Headers:</span>
                                        <span style={yourChangeLabel}>Your Change:</span>

                                        <div style={questionGrid}>
                                            <span style={numeric}>1</span>
                                            <span style={modQuestion}>Update columns, if necessary</span>
                                        </div>

                                        <div style={questionGrid}>
                                            <span style={numeric}>2</span>
                                            <span style={modQuestion}>Does column have missing data?</span>
                                        </div>
                                        <br />
                                    </div>
                                </div>

                                <div style={arrowDiv} onClick={() => scroll(-100)}>
                                    <img style={arrowStyle}  src={leftArrow} alt="left arrow" />
                                </div>

                                <div className="columns" style={modGuts} ref={ref}>
                                    
                                    {doc1SoftHeaders.map((header, index) =>
                                        <div className="unique-column" id={index}>

                                            {/* Container for middle columns (yes checkbox, default header found, and update/delete buttons). */}
                                            {/* Loop through state to make multiple columns. */}
                                            <br />
                                            <span style={columnNumber}>{index + 1}</span>
                                            <span style={detectedHeaders}>{doc1InitHeaders[index]}</span>
                                            <span style={modifyHeaders}>{header}</span>


                                            <input id={index} onChange={(e) => handleHeaderChange1(index, e)} style={headerFill} type="text"/>
                                            <div style={yesDiv}>
                                                <input key={Math.random()} checked={doc1SoftMissing[index]} id={header} onChange={(e) => handleMissingVal1(index, e)} name="yesBox" style={checkBox} type="checkbox" /> 
                                                <label style={helperLabel} htmlFor="yesBox">Yes</label>  
                                            </div>

                                        </div>
                                    )} 
                                </div>  

                                <div style={arrowDiv} onClick={() => scroll(100)}>
                                    <img style={arrowStyle} src={rightArrow} alt="right arrow" />
                                </div>                              

                                <div style={modGuts}>

                                    {/* Container for last column (example/helper image). */}
                                    <div style={rightDiv}>
                                        <span style={helperLabelHead}>Example: </span>
                                        <img style={helperImgResize} src={helperImg} alt="helper img"/>                                
                                        <span style={helperLabel}>Columns <strong style={{color: '#00B3B5'}}>3 & 5 </strong> have missing data</span>
                                    </div>

                                </div>
                            
                            </div>
                            <button type="button" onClick={() => handleSubmitDoc1Button()} style={modSubmitButton}>Confirm Document #1</button>
                            <input type="reset" value="Reset Doc 1" onClick={() => handleReset1()} style={resetButton}/>

                        </form>
                    </Modal>
                </div>

                <div>
                    <Modal                            
                    style={modalRestyle}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                    isOpen={modifyHeadersOpen2}
                    onRequestClose={() => setModifyHeadersOpen2(false)}
                    >
                        {/* Container for entire Modal */}
                        <form style={modBackground}>
                            
                            {/* Container for header and close button */}
                            <div style={topDiv}>
                                <div>
                                    <h1 style={checkWork}>CHECK MY WORK</h1>
                                    <h1 style={docNumber}>Document #2</h1>
                                </div>
                                {/*<img src={xButton} style={xButtonResize} alt="x button" onClick={() => (setModifyHeadersOpen2(false) && alert("You will need to restart the comparison if you continue"))}/>*/}
                            </div>

                            <div style={flexContainer}>

                                <div style={modGuts}>
                            
                                    {/* Container for first column (question and Headers label). */}
                                    <div style={leftDiv}>
                                        <br />
                                        <div></div>
                                        <span style={headersLabel}>Detected Headers:</span>
                                        <span style={yourChangeLabel}>Your Change:</span>

                                        <div style={questionGrid}>
                                            <span style={numeric}>1</span>
                                            <span style={modQuestion}>Update columns, if necessary</span>
                                        </div>

                                        <div style={questionGrid}>
                                            <span style={numeric}>2</span>
                                            <span style={modQuestion}>Does column have missing data?</span>
                                        </div>

                                        <br />
                                    </div>
                                </div>

                                <div style={arrowDiv} onClick={() => scroll(-100)}>
                                    <img style={arrowStyle}  src={leftArrow} alt="left arrow" />
                                </div>

                                <div className="columns" style={modGuts} ref={ref}>

                                    {doc2SoftHeaders.map((header, index) =>

                                    <div className="unique-column" id={index}>
                                        {/* Container for middle columns (yes checkbox, default header found, and update/delete buttons). */}
                                        {/* Loop through state to make multiple columns. */}
                                        
                                            <br />
                                            <span style={columnNumber}>{index + 1}</span>
                                            <span style={detectedHeaders}>{doc2InitHeaders[index]}</span>
                                            <span style={modifyHeaders}>{header}</span>

                                            <input id={index} onChange={(e) => handleHeaderChange2(index, e)} style={headerFill} type="text"/>
                                            <div style={yesDiv}>
                                                <input key={Math.random()} checked={doc2SoftMissing[index]} id={header} onChange={(e) => handleMissingVal2(index, e)} name="yesBox" style={checkBox} type="checkbox" /> 
                                                <label style={helperLabel} htmlFor="yesBox">Yes</label>  
                                            </div>

                                        </div>
                                
                                    )} 
                                </div>  

                                <div style={arrowDiv} onClick={() => scroll(100)}>
                                    <img style={arrowStyle} src={rightArrow} alt="right arrow" />
                                </div>                              

                                <div style={modGuts}>

                                    {/* Container for last column (example/helper image). */}
                                    <div style={rightDiv}>
                                        <span style={helperLabelHead}>Example: </span>
                                        <img style={helperImgResize} src={helperImg} alt="helper img"/>                                
                                        <span style={helperLabel}>Columns <strong style={{color: '#00B3B5'}}>3 & 5 </strong> have missing data</span>
                                    </div>

                                </div>
                            
                            </div>
                            <button type="button" onClick={() => handleSubmitDoc2Button()} style={modSubmitButton}>Confirm Document #2</button>
                            <input type="reset" value="Reset Doc 2" onClick={() => handleReset2()} style={resetButton}/>

                        </form>
                    </Modal>
                </div>

                <div>
                    <Modal
                    style={modalRestyle}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                    isOpen={confirmHeadersOpen}
                    onRequestClose={() => setConfirmHeadersOpen(false)}
                    >
                        <div style={modBackground}>
                            
                            {/* Container for header and close button */}
                            <div style={topDiv}>
                                <div>
                                    <h1 style={checkWork}>CONFIRM MY WORK</h1>
                                </div> 
                            </div>

                            <div style={flexConfirm}>
                                <div style={stackDocs}>

                                <h2 style={confirmDocNumber}>Document #1</h2>

                                    <div className="columns-div-confirm" style={modGuts}>

                                        {/* Loop through headers array. */}
                                        
                                        {doc1SoftHeaders.map((header, index) =>

                                        <div className="confirm-column" id={index}>
                                            <span style={columnNumber}>{index + 1}</span>
                                            <span style={modifyHeaders}>{header}</span>
                                            <span style={modifyHeaders}>{returnMissingString(doc1SoftMissing[index])}</span>
                                        </div>
                                        )} 
                                        
                                    </div>      

                                <h2 style={confirmDocNumber}>Document #2</h2> 
                                    
                                    <div className="columns-div-confirm" style={modGuts}>

                                        {/* Loop through headers array. */}
                                        {doc2SoftHeaders.map((header, index) =>

                                        <div className="confirm-column" id={index}>

                                            <span style={columnNumber}>{index + 1}</span>
                                            <span style={modifyHeaders}>{header}</span>
                                            <span style={modifyHeaders}>{returnMissingString(doc2SoftMissing[index])}</span>

                                        </div>
                                        )} 

                                    </div>                       

                                </div>
                            </div>

                            <button onClick={continueComparison} style={modSubmitButton}> Confirm Both Docs </button>
                            <button onClick={() => handleReviewDocsButton()} style={resetButton}> Review Both Docs </button>           
                        </div>
                        
                    </Modal>
                </div>

                <form className={'form-layout'} onSubmit={(e) => newClaim(e)}>

                    <div className={'form-element-style'}>
                        <label className={'create-new-claim-label'} htmlFor="insured">Insured</label>
                        <input className={'create-new-claim-input'} type="text" id="insured" name="insured" placeholder="Insured Name" onChange={handleChange}/>
                    </div>

                    {/*
                    <div className={'form-element-style'}>
                        <label className={'create-new-claim-label'} htmlFor="insured-phone">Insured Phone</label>
                        <input className={'create-new-claim-input'} type="text" id="insured_phone" name="insured_phone" placeholder="Insured Phone" onChange={handleChange}/>
                    </div>
                    */}
                    
                    <div className={'form-element-style'}>       
                        <label className={'create-new-claim-label'} htmlFor="insured-address">Insured Address <span style={optionalField}>(optional)</span></label>
                        <input className={'create-new-claim-input'} type="text" id="insured_address" name="insured_address" placeholder="Insured Address" onChange={handleChange}/>
                    </div>
                    
                    <div className={'form-element-style'}>
                        <label className={'create-new-claim-label'} htmlFor="claim-number">Claim Number <span style={optionalField}>(optional)</span></label>
                        <input className={'create-new-claim-input'} type="text" id="claim_number" name="claim_number" placeholder="Claim Number" onChange={handleChange}/>
                    </div>


                    {/*
                    <div className={'form-element-style'}>
                        <label className={'create-new-claim-label'} htmlFor="property-type">Property Type</label>
                        <input className={'create-new-claim-input'} type="text" id="prop_type" name="prop_type" placeholder="Property Type" onChange={handleChange}/>
                    </div>
                    */}


                    {/*
                    <div className={'form-element-style'}>
                        <label className={'create-new-claim-label'} htmlFor="nature-of-the-claim">Nature of the Claim</label>
                        <input className={'create-new-claim-input'} type="text" id="claim_type" name="claim_type" placeholder="Nature of the Claim" onChange={handleChange}/>
                    </div>
                    */}


                    {/*
                    <div className={'form-element-style-unique'}>
                        <div className={'form-element-style'}>
                            <label className={'create-new-claim-label'} htmlFor="date-opened">Date Opened</label>
                            <input className={'create-new-claim-input-small'}type="date" id="date_open" name="date_open" onChange={handleChange}/>
                        </div>
                        
                        <div className={'form-element-style'}>
                            <label className={'create-new-claim-label'} htmlFor="status">Status</label>
                            <select className={'create-new-claim-input-small-select'} name="status" id="status" onSelect={handleChange}>
                                <option value="0">Active</option>
                                
                            </select>
                        </div>
                    </div>
                    */}


                    {/*
                    <div className={'form-element-style'}>
                        <label className={'create-new-claim-label'} htmlFor="insurance-company">Insurance Company</label>
                        <input className={'create-new-claim-input'} type="text" id="insurance_comp" name="insurance_comp" placeholder="Insurance Company" onChange={handleChange}/>
                    </div>
                    */}
                    
                    <div className={'form-element-style'}>
                        <label className={'create-new-claim-label'} htmlFor="adjuster">Adjuster <span style={optionalField}>(optional)</span></label>
                        <input className={'create-new-claim-input'} type="text" id="adjuster" name="adjuster" placeholder="Adjuster Name" onChange={handleChange}/>
                    </div>


                    <div style = {lineBreak}> <div></div> </div>
                    <div style = {lineBreak}> <div></div> </div>


                    {/*
                    <div className={'form-element-style'}>
                        <label className={'create-new-claim-label'} htmlFor="adjuster-email">Adjuster Email</label>
                        <input className={'create-new-claim-input'} type="text" id="adj_email" name="adj_email" placeholder="Adjuster Email" onChange={handleChange}/>
                    </div>


                    <div className={'form-element-style'}>
                        <label className={'create-new-claim-label'} htmlFor="adjuster-phone">Adjuster Phone</label>
                        <input className={'create-new-claim-input'} type="text" id="adjust_phone" name="adj_phone" placeholder="Adjuster Phone"  onChange={handleChange}/>
                    </div>
                    */}

                    <div className="hiddenFieldName" style={{display: 'none'}} type="text" name="user_id" id="user_id" value={userData}/>             

                </form>

                <h2 className={'h2-upload-comparison'}>Upload Comparison</h2>
                <form className={'file-upload'} onSubmit={(e) => handleFileSubmit(e)}>

                    {/* FILES INPUT */}
                    <input style={{display: 'none'}} id='fileUpload' type='file' multiple
                    accept='application/pdf, image/png'
                    onChange={handleFileEvent}
                    disabled={fileLimit}
                    />

                    <label style={file1} className={!fileLimit ? 'button-upload' : 'button-upload-disabled'} htmlFor='fileUpload'>
                        <img style={imgResize} src={toggleImg1()}></img>
                        
                        <div style={fileInfo}>

                            {uploadedFiles.slice(0, 1).map((file, index) => (
                                
                                    <p id={index} className="uploaded-files-name">
                                        {file.name}
                                    </p>
                            
                            ))}
                            
                            {file1DefaultText()}

                        </div>
                    </label>

                    <label style={file2} className={!fileLimit ? 'button-upload' : 'button-upload-disabled'} htmlFor='fileUpload'>
                        <img style={imgResize} src={toggleImg2()}></img>

                        <div style={fileInfo}>

                            {uploadedFiles.slice(1, 2).map((file, index) => (
                                    
                                    <p id={index} className="uploaded-files-name">
                                        {file.name}
                                    </p>
                            
                            ))}
                            
                            {file2DefaultText()}

                        </div>
                    </label>
                </form>

                <form className={'buttons-container'} onSubmit={(e) => newComparison(e)}>
                        
                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                    
                        <button type="submit" className={allowUploadButton()} onClick={handleFileSubmit}>Upload Files</button>
                        
                    </div>

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <button className={'button-add-headers'} onClick={handleClickOpen}>Add File Headers</button>
                        <ModalMUI
                        open={open}
                        onRequestClose={handleClose}>
                            <NewModal
                            setModalData={setModalData}
                            modalData={modalData}
                            setOpen={setOpen}/>
                        </ModalMUI>
                    </div>

                    <div className="submit-comparison-container">
                        <button type="submit" ref={buttonRef} onClick={() => (setNumberOfSubmits(numberOfSubmits + 1))}className={allowSubmitComparisonButton()}>Submit Comparison</button>    
                    </div>

                </form>

            </div>
        )
    }

    //STYLES
    const optionalField = {
        color: '#777777',
        fontWeight: '400'
    }
    const modalRestyle = {
        content: {
            background: 'none',
            border: 'none',
            inset: '0px',
            backgroundColor: 'rgb(25, 25, 25, 0.5)',
            padding: '0px',
          },
    }
    const modBackground = {
        backgroundColor: '#DDDEDD',
        position: 'fixed',
        top: '10%',
        left: '10%',
        height: '80%',
        width: '80%',
        borderRadius: '18px',
        border: '1px solid black'
    }
    const checkWork = {
        display: 'inline',
        color: '#555F55',
        fontWeigth: '900',
        fontSize: '24px',
        margin: '0px 16px 10px 16px'
    }
    const docNumber = {
        display: 'inline',
        color: '#00B3B5',
        fontSize: '18px',      
    }
    const confirmDocNumber = {
        margin: '24px 0px',
        display: 'block',
        textAlign: 'center',
        color: '#00B3B5',
        fontSize: '18px',
    }
    const questionGrid = {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '10% 90%',
        padding: '10px 4px 0px 4px',
        justifySelf: 'center'
    }
    const numeric = {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#999999'
    }
    const arrowStyle = {
        height: '60%',
        width: '100%',
    }
    const arrowDiv = {
        padding: '0px 0px 0px 8px',
        display: 'flex',
        width: '7%',
        height: 'auto',
        alignItems: 'center',
        margin: '0px 0px',   
    }
    const topDiv = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'middle'
    }
    const flexContainer = {
        position: 'relative',
        top: '8%',
        width: '100%',
        height: '65%',
        display: 'flex',
        justifyContent: 'space-between'
    }
    const flexConfirm = {
        position: 'relative',
        width: '100%',
        height: '80%',
        display: 'flex',
        justifyContent: 'space-between'
    }
    const stackDocs = {
        position: 'relative',
        top: '8%',
        width: '100%',
        height: '65%',
        display: 'block',
        justifyContent: 'space-between'
    }
    const leftDiv = {
        display: 'grid',
        gridTemplateRows: '10% 10% 10% 10% 20% 20%',
        fontSize: '16px',
        height: '90%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: '12px',
        margin: '0px 0px 24px 12px'
    }
    const modGuts = {
        display: 'flex'
    }
    const rightDiv = {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        margin: '0px 0px 0px 0px',
    }
    const columnNumber = {
        color: '#00B3B5',
        justifySelf: 'center'
    }
    const modifyHeaders = {
        padding: '4px 4px',
        color: '#555F55',
        fontWeight: '500',
        justifySelf: 'center',
        whiteSpace: 'nowrap'
    }
    const detectedHeaders = {
        padding: '4px 4px',
        color: '#00B3B5',
        fontWeight: '500',
        justifySelf: 'center',
        borderBottom: 'dotted 1px #555F55'
    }
    const modQuestion = {
        padding: '0px 0px 0px 12px',
        color: '#444444',
        fontSize: '12px',
        textAlign: 'left'
    }
    const helperLabel = {
        display: 'block',
        color: '#444444',
        fontSize: '18px',
        padding: '0px 24px 0px 24px',
        textAlign: 'center'
    }
    const helperLabelHead = {
        display: 'block',
        color: '#00B3B5',
        fontWeight: 'bold',
        marginBottom: '4px',
        fontSize: '18px',
        padding: '0px 24px 0px 24px',
        textAlign: 'center',
        width: '100%'
    }
    const yesDiv = {
        display: 'flex',
        padding: '24px 16px 0px 16px',
        width: '100%',
        justifyContent: 'center',
        justifySelf: 'center'
    }
    const helperImgResize = {
        display: 'block',
        padding: '0px 24px 0px 24px',
        margin: '0px 24px 0px 24px',
        width: '100%',
        height: 'auto'
    }
    const checkBox = {
        borderRadius: '8px',
        height: '22px',
        width: '22px'
    }
    const headersLabel = {
        color: '#00B3B5',
        padding: '4px 4px',
        fontWeight: '500',
        fontSize: '16px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        borderBottom: '1px dotted #555F55'
    }
    const yourChangeLabel = {
        color: '#555F55',
        padding: '4px 4px',
        fontWeight: '600',
        fontSize: '16px',
        textAlign: 'center',
        whiteSpace: 'nowrap'
    }
    const headerFill = {
        border: '1px solid #00B3B5',
        borderRadius: '4px',
        maxWidth: '150px',
        height: '30px',
        fontSize: '1.0vw',
        textAlign: 'center',
        justifySelf: 'center',
        margin: '18px 0px' 
    }
    const modSubmitButton = {
        position: 'absolute',
        bottom: '4%',
        left: '2%',
        backgroundColor: '#00B3B5',
        color: 'white',
        fontSize: '16px',
        border: 'none',
        width: 'auto',
        height: '40px',
        padding: '4px 16px',
        margin: '4px 0px 4px 0px'
    }
    const resetButton = {
        position: 'absolute',
        bottom: '4%',
        right: '2%',
        backgroundColor: '#723e49',
        color: 'white',
        fontSize: '16px',
        border: 'none',
        width: 'auto',
        height: '40px',
        padding: '4px 16px',
        margin: '4px 0px 4px 0px',
        ':hover': {
            opacity: '50%'
        },
    }
    const file1 = {
        display: 'block',
        boxSizing: 'border-box',
        borderRadius: '8px',
        width: '45%',
        height: '96%',
        justifyContent: 'flex-start',
        marginRight: '32px'
    }
    const file2 = {
        display: 'block',
        boxSizing: 'border-box',
        width: '45%',
        height: '96%',
        borderRadius: '8px',
        justifyContent: 'flex-end',
        marginLeft: '32px'
    }
    const fileInfo = {  
        fontSize: '2.0vh',
        position: 'absolute',
        top: '75%',
        height: '25%',
        alignItems: 'center',
        verticalAlign: 'center',
        left: '10%',
        right: '10%'
    }
    const defaultText = {
        color: '#333333',
        fontWeight: 'bold',
        fontSize: '1.5vh'
    }
    const imgResize = {
        height: 'auto',
        width: '13%',
        margin: '46px 12px 24px 12px'
    }
    const lineBreak = {
        width: '100%',
        height: '40px'
    }
        return (
            <div className={'outer-container'}>
                <MainWrapper/>
                <div className={loadingStatus}></div>

                <div className={'right-container'}>
                    {renderForm1()}        
                </div>                
            </div>
        )
}
export default CreateNewClaim