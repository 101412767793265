import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userConstants } from '../../actions/userTypes'; // Ensure the path is correct
import Button from '@mui/material/Button';

const LogoutButton = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        // Remove the token from local storage
        localStorage.removeItem('token');
        
        // Dispatch a logout action to clear user state in Redux
        dispatch({ type: userConstants.LOGOUT_USER });

        // Redirect to the login page
        navigate('/login');
    };
    const fabIcon = {
        width: '120px',
        backgroundColor: '#555555',
        borderRadius: '0px',
        height: '36px',
        padding: '4px 24px',
        fontWeight: 'bold'
    }

    return (
        <Button
            style={fabIcon}
            variant="contained"
            onClick={handleLogout}
        >
            Logout
        </Button>
    );
};

export default LogoutButton;
