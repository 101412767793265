import { findByLabelText } from '@testing-library/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Logout from '../logout/logout';
import '../../css/Dashboard.css';

import MainWrapper from '../wrappers/mainWrapper';

const Dashboard = props => {
    const setState = useState()
    const isLoggedIn = useSelector(state => state.isLoggedIn)
    const userData = useSelector(state => state.user)


    {/* Not to actually be used. Just created something to test classNames. */}
    let buttonText = '';
    const statusText = ['Open', 'Pending', 'Closed']; 
    let randStatus = statusText[Math.floor(Math.random() * statusText.length)];
    let statusStyle = '';

    function handleStatusButtons () {
        if (randStatus == 'Open'){
            statusStyle = 'open-status';
            buttonText = 'Edit';
            return 'alter-button';
        }

        else if (randStatus === 'Pending') {
            statusStyle = 'pending-status';
            buttonText = 'Close';
            return 'close-button';
        }

        else if (randStatus === 'Closed') {
            statusStyle = 'closed-status';
            buttonText = 'Archive'
            return 'archive-button';
        }
    }

    const checkToken = e => {
           
        //console.log(localStorage.getItem('token'))
        //console.log(isLoggedIn)
        //console.log(userData)
    }

 
    return (  

        <div className={'outer-container'}>
            <MainWrapper/>
            <div className={'right-container'}>

                <div className={'status-container'}>

                    <div className="variants">
                        <h1>VARIANTS</h1>
                        <p>Found This Week</p>
                        <strong>89</strong>
                    </div>

                    <div className="claims-pending">
                        <h1>CLAIMS</h1>
                        <p>Pending</p>
                        <strong>16</strong>
                    </div>

                    <div className="weekly">
                        <h1>WEEKLY</h1>
                        <p>Variant Value</p>
                        <strong>$25,652</strong>
                    </div>

                    <div className="claims-closed">
                        <h1>CLAIMS</h1>
                        <p>Closed This Week</p>
                        <strong>4</strong>
                    </div>

                </div>

                <div className={'adjustment-and-search-container'}>

                    <button className="adjustment-button">CREATE NEW ADJUSTMENT</button>

                    <input type="text" id="search-field" />

                </div>

                <div className={'active-claims-container'}>

                    <h1 className={'h1-active-claims'}>Active Comparisons</h1>

                    <table className={'table-active-claims'}>

                        <th className={'table-header'}>Insured</th>
                        <th className={'table-header'}>Insurance Company</th>
                        <th className={'table-header'}>Adjuster</th>
                        <th className={'table-header'}>Date Opened</th>
                        <th className={'table-header'}>Status</th>
                        <th className={'table-header'}>Actions</th>

                        <tr>
                            <td className={'table-data'}>Jim and Pamela Halpert</td>
                            <td className={'table-data'}>Farmers Insurance</td>
                            <td className={'table-data'}>Dwight Schrute</td>
                            <td className={'table-data'}>08/30/21</td>
                            <td className={statusStyle}>{randStatus}</td>
                            <button className={handleStatusButtons()}>{buttonText}</button>
                        </tr>

                        <tr>
                            <td className={'table-data'}>Phyllis Lapin</td>
                            <td className={'table-data'}>AAA</td>
                            <td className={'table-data'}>Angela Martin</td>
                            <td className={'table-data'}>08/10/21</td>
                            <td className={statusStyle}>{randStatus}</td>
                            <button className={handleStatusButtons()}>{buttonText}</button>
                        </tr>

                        <tr>
                            <td className={'table-data'}>Vance Refrigeration</td>
                            <td className={'table-data'}>State Farm</td>
                            <td className={'table-data'}>Jan Levinson</td>
                            <td className={'table-data'}>08/05/21</td>
                            <td className={statusStyle}>{randStatus}</td>
                            <button className={handleStatusButtons()}>{buttonText}</button>
                        </tr>

                    </table>

                </div>

                <div className={'change-page'}>

                    <button onClick={checkToken}className="button-page">1</button>
                    <button onClick={Logout} className="button-page">2</button>
                    <button className="button-page">3</button>
                    <button className="button-page">4</button>
                    <button className="button-page">5</button>

                </div>
                
            </div>
            
        </div>

    )
}

export default Dashboard

