import { findByLabelText } from '@testing-library/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import Logout from '../logout/logout';
import '../../css/AdminDashboard.css';
import caretDown from '../../img/caret-down.svg'
import caretUp from '../../img/caret-up.svg'

import { activateUser, deactivateUser } from '../../actions/auth';

import MainWrapper from '../wrappers/mainWrapper';

const AdminDashboard = props => {
   
    const isLoggedIn = useSelector(state => state.isLoggedIn)
    const userData = useSelector(state => state.user)
    const [filter, setFilter] = useState(true)
    const [activeUsers, setActiveUsers] = useState()
    const [inactiveUsers, setInactiveUsers] = useState()
    const [currEmail, setCurrEmail ] = useState({
        email: ''
    })
    const [searchByEmail, setSearchByEmail] = useState("")
    const [nameSort, setNameSort] = useState(false)
    const [emailSort, setEmailSort] = useState(false)
    const [filteredUsers, setFilteredUsers] = useState()
    const [matches, setMatches] = useState("")
    const [dateSort, setDateSort] = useState(false)


    const greenBold = {
        fontWeight: 'bold',
        color: '#00B3B5'
    }

    useEffect(() =>{
        getApiData()
        getInactive()
    }, [filteredUsers])
   
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // Function to collect data
    const getApiData = async () => {
        const response = await fetch(
        `${process.env.REACT_APP_ACTIVE_USR}`
        ).then((response) => response.json({}));
    
        // update the state
        setActiveUsers(response.user);
        
    };

  const getInactive = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_INACTIVE_USR}`
    ).then((response) => response.json({}));
  
    // update the state
    setInactiveUsers(response.inactiveuser);

    
  };

  const handleFilterChange = (event) => {
    setFilteredUsers()
    setFilter(event.target.value === 'true');
    if(!filter) {
        const filtered = activeUsers.filter(user => user.email.toLowerCase().includes(searchByEmail.toLowerCase()));
        setFilteredUsers(filtered)
        if(filtered?.length === 0) {
            setMatches('0 Matches');
        }
        else {
            setMatches("")
        }
    }
    else {
        const filtered = inactiveUsers.filter(user => user.email.toLowerCase().includes(searchByEmail.toLowerCase()));
        setFilteredUsers(filtered)
        if(filtered?.length === 0) {
            setMatches('0 Matches');
        }
        else {
            setMatches("")
        }
    }
};
function goToAccount(id) {
    navigate(`/editAccount/${id}`)
}

function formatPhoneNumber(phone) {
    // Remove any non-digit characters (including periods)
    const cleanPhone = phone.replace(/\D/g, '');
  
    // Check if the cleaned phone number has exactly 10 digits
    if (cleanPhone.length === 10) {
      // Format the phone number as (XXX) XXX-XXXX
      return `(${cleanPhone.slice(0, 3)}) ${cleanPhone.slice(3, 6)}-${cleanPhone.slice(6)}`;
    } else {
      return phone; // Return the original input if it's not a valid 10-digit phone number
    }
  }


function sortName() {
    console.log("nameSort: " + nameSort)
    if(nameSort){
        if(filter) {
            const sorted = [...activeUsers].sort((a, b) => b.name.localeCompare(a.name));
            setActiveUsers(sorted);
        }
        else {
            const sorted = [...inactiveUsers].sort((a, b) => b.name.localeCompare(a.name));
            setInactiveUsers(sorted);
        }
        setNameSort(false)
    }
    else {
        if(filter) {
            const sorted = [...activeUsers].sort((a, b) => a.name.localeCompare(b.name));
            setActiveUsers(sorted);
        }
        else {
            const sorted = [...inactiveUsers].sort((a, b) => a.name.localeCompare(b.name));
            setInactiveUsers(sorted);
        }
        setNameSort(true)
    }
}

function sortEmail(){
    console.log("emailSort: " + emailSort)
    if(emailSort){
        setEmailSort(false)

        if(filter) {
            const sorted = [...activeUsers].sort((a, b) => b.email.localeCompare(a.email));
            setActiveUsers(sorted);
        }
        else {
            const sorted = [...inactiveUsers].sort((a, b) => b.email.localeCompare(a.email));
            setInactiveUsers(sorted);
        }
    }
    else {
        setEmailSort(true)

        if(filter) {
            const sorted = [...activeUsers].sort((a, b) => a.email.localeCompare(b.email));
            setActiveUsers(sorted);
        }
        else {
            const sorted = [...inactiveUsers].sort((a, b) => a.email.localeCompare(b.email));
            setInactiveUsers(sorted);
        }
    }
}
function sortDate(){
    console.log("dateSort: " + dateSort)
    if(dateSort){
        setDateSort(false)

        if(filter) {
            const sorted = [...activeUsers].sort((a, b) => b.date_created.localeCompare(a.date_created));
            setActiveUsers(sorted);
        }
        else {
            const sorted = [...inactiveUsers].sort((a, b) => b.date_created.localeCompare(a.date_created));
            setInactiveUsers(sorted);
        }
    }
    else {
        setDateSort(true)

        if(filter) {
            const sorted = [...activeUsers].sort((a, b) => a.date_created.localeCompare(b.date_created));
            setActiveUsers(sorted);
        }
        else {
            const sorted = [...inactiveUsers].sort((a, b) => a.date_created.localeCompare(b.date_created));
            setInactiveUsers(sorted);
        }
    }
}
const search = () => {
    if(filter) {
        const filtered = activeUsers.filter(user => user.email.toLowerCase().includes(searchByEmail.toLowerCase()));
        setFilteredUsers(filtered)
        if(filtered?.length === 0) {
            setMatches('0 Matches');
        }
        else {
            setMatches("")
        }
    }
    else {
        const filtered = inactiveUsers.filter(user => user.email.toLowerCase().includes(searchByEmail.toLowerCase()));
        setFilteredUsers(filtered)
        if(filtered?.length === 0) {
            setMatches('0 Matches');
        }
        else {
            setMatches("")
        }
    }

}

const searchInputStyle = {
    float: 'right',
    margin: '16px',
    height: '28px',
    padding: '0px 12px'
}
const errorMessage = {
    position: 'absolute',
    top: '4%',
    fontWeight: 'bold',
    right: '5%',
    margin: '16px',
    height: '28px',
    padding: '0px 12px',
    color: 'red'
}
const dropdownStyle = {
    float: 'right',
    margin: '16px',
    height: '32px',
    padding: '0px 12px'
}
const searchButton = {
    float: 'right',
    margin: '16px 0px',
    height: '32px',
    padding: '0px 12px',
    backgroundColor: '#00B3B5',
    color: 'white',
    width: 'auto',
    border: 'none'
}
const caretStyle = {
    height: '16px',
    width: 'auto'
}

function returnUsers() {
    const activeUsersToDisplay = (filteredUsers !== undefined && filteredUsers?.length > 0) ? filteredUsers : activeUsers;
    const inactiveUsersToDisplay = (filteredUsers !== undefined && filteredUsers?.length > 0) ? filteredUsers : inactiveUsers;
    console.log("users[0]: " + JSON.stringify(activeUsersToDisplay,null,2))

    if(filter) {
        return(
            <div>

                <h1>Active Users</h1>
                
                <table className={'activeUsers'}>
                    <thead>
                        <th onClick={() => sortName()}>Name <img style={caretStyle} src={nameSort ?  caretDown : caretUp}></img></th>
                        <th onClick={() => sortEmail()}>Email <img style={caretStyle} src={emailSort ? caretDown : caretUp}></img></th>
                        <th onClick={() => sortDate()}>Date Created <img style={caretStyle} src={emailSort ? caretDown : caretUp}></img></th>
                        <th>Phone #</th>
                        <th>Monthly Allotment</th>
                        <th>Additional Cost</th>
                        <th>Coupon</th>
                        <th>Actions</th>
                    </thead>
                
                    <tbody>
                        {activeUsersToDisplay && 
                            activeUsersToDisplay
                                .slice() // Create a shallow copy of the array
                                .reverse() // Reverse the order of the elements
                                .map((user) => (
                                    <tr key={user._id}> {/* Added key prop for React's reconciliation */}
                                        <td id={user.name}>{user.name}</td>       
                                        <td id={user.email}>{user.email}</td>  
                                        <td>
                                            {user.date_created 
                                                ? new Date(user.date_created).toLocaleString('en-US', {
                                                    year: 'numeric', 
                                                    month: 'long', 
                                                    day: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true // Use 12-hour format with AM/PM
                                                }) 
                                                : "N/A"}

                                        </td>      
                                        <td id={user?.phone}>{formatPhoneNumber(user?.phone)}</td>
                                        <td style={greenBold}>{user.monthly_allotment || 0}</td>
                                        <td style={greenBold}>${user.additional_cost || 9.99}</td>
                                        <td>{user.coupon || "-"}</td>
                                        <td>
                                            <button name="email" onClick={() => goToAccount(user._id)} className="adjustment-button">EDIT</button>
                                        </td>                 
                                    </tr>
                                ))}
                    </tbody>


                </table>

            </div> 

        )
    }
    else {
        return (
           
            <div className={'usercontainer2'}>
                <h1>Inactive Users</h1>

                <div className="inactive">

                    <table className={'activeUsers'}>
                        <thead>
                            <th onClick={() => sortName()}>Name <img style={caretStyle} src={nameSort? caretDown : caretUp}></img></th>
                            <th onClick={() => sortEmail()}>Email <img style={caretStyle} src={emailSort? caretDown : caretUp}></img></th>
                            <th onClick={() => sortDate()}>Date Created <img style={caretStyle} src={dateSort? caretDown : caretUp}></img></th>
                            <th>Phone #</th>
                            <th>Monthly Allotment</th>
                            <th>Additional Cost</th>
                            <th>Coupon</th>
                            <th>Actions</th>
                        </thead>
            
                        <tbody>

                            {inactiveUsersToDisplay && inactiveUsersToDisplay.map((user) => (
                            
                                <tr>
                                    <td id={user.name}>{user.name}</td>       
                                    <td id={user.email}>{user.email}</td>  
                                    <td>
                                    {user.date_created 
                                        ? new Date(user.date_created).toLocaleString('en-US', {
                                            year: 'numeric', 
                                            month: 'long', 
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true // Use 12-hour format with AM/PM
                                        }) 
                                        : "N/A"}
                                    </td>
                                    <td id={user?.phone}>{formatPhoneNumber(user?.phone)}</td>
                                    <td>{user.monthly_allotment || 0}</td>
                                    <td>${user.additional_cost || 9.99}</td>
                                    <td>{user.coupon || "-"}</td>
                                    <td><button name="email" onClick={() => goToAccount(user._id)} className="adjustment-button">EDIT</button></td>                 
                                </tr>
                            
                            ))}
                        </tbody>

                    </table>

                </div>

            </div>
        )
    }
}
  
  
    return (  

        <div className={'outer-container'}>
            <MainWrapper/>
            <div className={'right-container'}>

                <div className={'admin-container'}>
                
                    <button style={searchButton} onClick={() => search()}>Search</button>
                    <input placeholder="email" className="users-select" style={searchInputStyle} type="text" onChange={(event) => setSearchByEmail((event.target.value).trim())}/>
                    {matches && <p style={errorMessage}>{matches}</p>}


                    <select className="users-select" style={dropdownStyle} name="filter" id="filter" onChange={handleFilterChange}>
                        <option value="true">Active Users</option>
                        <option value="false">Inactive Users</option>
                    </select>
                

                    {returnUsers()}

                </div>

            </div>
            
        </div>

    )
}

export default AdminDashboard
