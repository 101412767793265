import React, { useState, useRef } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import EstimateUpload from '../comparison/estimateUpload';
import ComparisonHeader from './comparisonHeader';


const NewModal = (props) =>{
    console.log(props.modalData)
    const [id, setId] = useState()
    const [formState, setFormState] = useState( {
        // renderDocForm: true,
        renderHeaderForm: true,
       
    })

    const checkProps = e => {
        // console.log(props)
    }
    if (!props.modalData) {
        return(<div>thing</div>)
    }
    return (
        // <div>
        //     <button
        //     onClick={checkProps}></button>
        // </div>
        <div>
            {/* {formState.renderDocForm && <EstimateUpload setFormState={setFormState} setId={setId} setModalData={props.setModalData} modalData={props.modalData} />} */}
             {formState.renderHeaderForm && <ComparisonHeader setFormState={setFormState} id={id} setId={setId}
              history={props.history} setModalData={props.setModalData} modalData={props.modalData} setOpen={props.setOpen} />} 
        </div>
    )
}

export default NewModal