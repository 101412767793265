import React, {useState, useRef} from 'react';

import '../../css/EstimatedUpload.css';
import '../../css/AdjustmentsActiveClaims.css';
const MAX_COUNT = 3;
function EstimateUpload({setFormState, setId, actions, setModalData, modalData}){
	const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileLimit, setFileLimit] = useState(false);
	

	const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    alert(`You can only add a maximum of ${MAX_COUNT} files`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) setUploadedFiles(uploaded)

    }



    const handleFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);
    }


    const handleSubmission = () => {
        // const formData = new FormData();

        // uploadedFiles.map((file, index) => {
        //     formData.append(`file`, file);
        // });
        console.log(modalData)
        setModalData({ ...modalData, uploadedFiles})
       
        // fetch(
        //   
        //     {
        //         method: 'POST',
        //         body: formData,
        //     }
        // )
        //     .then((response) => response.json())
        //     .then((result) => {
        //         console.log('Success:', result);
        //     })
        //     .catch((error) => {
        //         console.error('Error:', error);
        //     });
			 
			setFormState({
	      renderDocForm: false,
	     renderHeaderForm: true,
	 })

    };


	return(
		<div className={'adjustments-container'}>

		<h2 className={'h2-adjustments'}>Adjustments</h2>

		<div className={'grid-container-1'}>

			<div style={{ paddingLeft: '8px' }}>
				<div className="">

					<input id='fileUpload' type='file' multiple
						accept='application/pdf, image/png'
						onChange={handleFileEvent}
						disabled={fileLimit}
					/>

					<label htmlFor='fileUpload'>
						<a className={`btn btn-primary ${!fileLimit ? '' : 'disabled'} `}>Upload Files</a>
					</label>

					<div className="uploaded-files-list">
						{uploadedFiles.map(file => (
							<div >
								{file.name}
							</div>
						))}
					</div>

					<div>
						<button className="button" onClick={handleSubmission}>Submit</button>
					</div>

				</div>
			</div>
			</div>
			</div>
			
	)

}

export default EstimateUpload; 
//      setFormState({
	//      renderDocForm: false,
	//     renderHeaderForm: true,
	//  })