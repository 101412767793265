import React from 'react';

import '../../css/Reports.css';
import MainWrapper from '../wrappers/mainWrapper';

const Reports = props => {

    return (
        
        <div className={'outer-container'}>
            <MainWrapper/>
            <div className={'right-container'}>
                

                <div className={'reports-container'}>

                    <div className={'header-content-container'}>
                        <h1 className={'h1-reports'}>Comparison Report</h1>
                        <button className={'button-archive'}>Archive</button>
                    </div>

                    <table className={'table-claim-report-1'}>

                        <th>Insured</th>
                        <th>Insured Phone</th>
                        <th>Insured Address</th>


                        <tr>
                            <td>Dunder Mifflin Paper - David Wallis</td>
                            <td>123-456-7890</td>
                            <td>4128 S. Hampton Pl. Scranton, PA 74067</td>
                        </tr>

                        <th>Nature of the Claim</th>
                        <th>Property Type</th>
                        <th>Date Opened</th>
                        <th>Date Modified</th>
                        <th>Status</th>

                        <tr>
                            <td>Fire and smoke damage</td>
                            <td>Commercial</td>
                            <td>08/01/21</td>
                            <td>—</td>
                            <td>Active</td>
                        </tr>

                        <th>Insurance Company</th>
                        <th>Adjuster</th>
                        <th>Adjuster Email</th>
                        <th></th>
                        <th>Adjuster Phone</th>

                        <tr>
                            <td>Farmers Insurance</td>
                            <td>Andrew Bernard</td>
                            <td>abernard@farmersins.com</td>
                            <td></td>
                            <td>888-555-4444</td>
                        </tr>

                    </table>

                    <table className={'table-claim-report-2'}>

                        <colgroup>
                            <col style={{width: '30%'}}/>
                            <col style={{width: '15%'}}/>
                            <col style={{width: '15%'}}/>
                            <col style={{width: '15%'}}/>
                            <col style={{width: '25%'}}/>
                        </colgroup>

                        <thead>
                            <th>Item For Repair</th>
                            <th>Repair Type</th>
                            <th>Estimated Cost</th>
                            <th>Comparison</th>
                            <th>Variant Value</th>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Structural Repair</td>
                                <td>Structural</td>
                                <td>$24,000.00</td>
                                <td>$18,000.00</td>
                                <td>$6,000.00</td>
                            </tr>
                        </tbody>

                    </table>

                    <div className={'summary'}>
                        <button className={'button-export-report'}>Export Report</button>
                        <strong className={'strong-variant-value'}>$12,000.00</strong>
                        <strong className={'strong-variant'}>Total Variant Value: </strong> 
                    </div>

                </div>
             
            </div>
        </div>
    )

}

export default Reports